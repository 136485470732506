import React from "react";
import Alert from "@mui/material/Alert";

const GenericAlert = ({ severity, text, variant = "standard", style = {} }) => {
    return (
        <Alert variant={variant} severity={severity} style={style}>
            {text}
        </Alert>
    );
};

export default GenericAlert;
