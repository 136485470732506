import { Button, Grid, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { ApiService } from "common/services/ApiService";

const QuestionAnswerForum = ({ studentActivity, updateData, editableForum = true }) => {
    const classes = useStyles();
    const [newQuestion, setNewQuestion] = useState("");
    const [questions, setQuestions] = useState([]);
    const [showNewResponseMap, setShowNewResponseMap] = useState({});
    const [answerNewResponseMap, setAnswerNewResponseMap] = useState({});

    useEffect(() => {
        if (studentActivity?.questions && studentActivity?.questions.length > 0) {
            setQuestions(studentActivity.questions);
            setShowNewResponseMap(studentActivity.questions.map((obj) => ({ [obj.id]: false })));
            const answers = { ...answerNewResponseMap };
            studentActivity.questions.forEach((obj) => {
                answers[obj.id] = "";
            });
            setAnswerNewResponseMap(answers);
        } else {
            setQuestions([]);
            setShowNewResponseMap({});
            setAnswerNewResponseMap({});
        }
    }, [studentActivity]);

    const saveQuestion = async () => {
        const data = {
            text: newQuestion,
        };
        const result = await ApiService.addQuestion(studentActivity.id, data);
        updateData();
    };

    const saveAnswer = async (parent) => {
        const data = {
            text: answerNewResponseMap[parent],
            parent,
        };
        const result = await ApiService.addAnswer(data);
        updateData();
    };

    return (
        <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} md={8}>
                <h3>Nueva pregunta</h3>
                <TextareaAutosize
                    minRows={5}
                    style={{ width: "100%", padding: "15px" }}
                    onChange={(evt) => setNewQuestion(evt.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={8} display={"flex"} justifyContent={"flex-end"}>
                <Button variant="contained" onClick={saveQuestion}>
                    Guardar
                </Button>
            </Grid>
            <Grid item xs={12} md={8}>
                <h3>Preguntas anteriores:</h3>
            </Grid>
            {questions &&
                questions.map((question) => (
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "65%",
                        }}
                        key={question.id}
                    >
                        <Grid item xs={12} className={classes.questionAnswerBox}>
                            {question.text}
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setShowNewResponseMap({
                                            ...showNewResponseMap,
                                            [question.id]: true,
                                        });
                                    }}
                                >
                                    Responder
                                </Button>
                            </Grid>
                        </Grid>
                        {question.childrens &&
                            question.childrens.map((obj, i) => (
                                <Grid
                                    item
                                    xs={11}
                                    // md={8}
                                    key={i}
                                    className={classes.questionAnswerBox}
                                >
                                    {obj.text}
                                </Grid>
                            ))}

                        <Grid
                            item
                            xs={11}
                            style={{
                                display: showNewResponseMap[question.id] ? "block" : "none",
                            }}
                            id={`new-response-parent-${question.id}`}
                        >
                            <Grid item>
                                <h3>Respuesta</h3>
                                <TextareaAutosize
                                    minRows={5}
                                    style={{ width: "100%", padding: "15px" }}
                                    value={answerNewResponseMap[question.id] || ""}
                                    onChange={(evt) =>
                                        setAnswerNewResponseMap({
                                            ...answerNewResponseMap,
                                            [question.id]: evt.target.value,
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item display={"flex"} justifyContent={"flex-end"}>
                                <Button variant="contained" onClick={() => saveAnswer(question.id)}>
                                    Guardar
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setShowNewResponseMap({
                                            ...showNewResponseMap,
                                            [question.id]: false,
                                        });
                                    }}
                                >
                                    Descartar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
        </Grid>
    );
};

export default QuestionAnswerForum;
