import { Button, Grid } from "@mui/material";
import React, { useState } from "react";

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} padding={3}>
                <img
                    src={`/img/${images[currentIndex]}`}
                    alt={`Slide ${currentIndex}`}
                    style={{
                        width: "100%",
                        height: "340px",
                        objectFit: "contain",
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"space-between"}
                px={10}
                pb={3}
            >
                <Button
                    variant="contained"
                    onClick={handlePrev}
                    disabled={currentIndex === 0}
                >
                    Anterior
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={currentIndex === images.length - 1}
                >
                    Siguiente
                </Button>
            </Grid>
        </Grid>
    );
};

export default ImageSlider;
