import Chart from "common/components/Chart/Chart";
import { useWordCloudChart } from "hooks/chart/UseWordCloudChart";
import React, { useEffect } from "react";

const WordCloudChart = ({ data, xAxis }) => {
    const { options: optionsWordCloud, setOptions: setWordCloudChart } = useWordCloudChart();

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setWordCloudChart({
                ...optionsWordCloud,
                series: [
                    {
                        ...optionsWordCloud.series[0],
                        data: Object.entries(data).map(([key, value]) => ({
                            name: key,
                            weight: value
                        }))
                    },
                ],
            });
        }
    }, [data]);

    return <Chart options={optionsWordCloud} />;
};

export default WordCloudChart;
