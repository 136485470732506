import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import wordcloud from "highcharts/modules/wordcloud.js";
import HighchartsNetworkgraph from "highcharts/modules/networkgraph";
import HighchartsMore from "highcharts/highcharts-more";
import { NETWORKGRAPH, MOREGRAPH } from "utils/constants";

const typeChart = {
    [NETWORKGRAPH]: HighchartsNetworkgraph,
    [MOREGRAPH]: HighchartsMore,
};

const Chart = ({ options, type = null }) => {
    HC_more(Highcharts);
    NoDataToDisplay(Highcharts);
    wordcloud(Highcharts);

    Highcharts.seriesTypes.wordcloud.prototype.deriveFontSize = function (
        relativeWeight
    ) {
        var maxFontSize = 45;
        // Will return a fontSize between 0px and 25px.
        return Math.floor(maxFontSize * (relativeWeight + 1));
    };

    type && typeChart[type](Highcharts);

    return (
        <div id="chart-component">
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default Chart;
