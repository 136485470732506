import {
    Autocomplete,
    CircularProgress,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import AsyncSelect from "common/components/AsyncSelect/AsyncSelect";
import { ApiService } from "common/services/ApiService";
import React, { useEffect, useState } from "react";

const SelectProvinceAndLocation = ({
    handleChangeProvince = () => {},
    handleChangeLocation = () => {},
    handleChangeEstablishment = () => {},
    showEstablishments = false,
    xs = 6,
    lg = 4,
    sm = 4,
}) => {
    const [provinces, setProvinces] = useState([]);
    const [locations, setLocations] = useState([]);
    const [establishments, setEstablishments] = useState([]);
    const [establishment, setEstablishment] = useState([]);
    const [locationId, setLocationId] = useState(null);

    useEffect(() => {
        async function getProvinces() {
            const res = await ApiService.getProvinces();
            setProvinces(res.data);
        }

        getProvinces();
    }, []);

    const getLocations = async (provinceId) => {
        const res = await ApiService.getLocations(provinceId);
        setLocations(res.data);
    };

    const getEstablishments = async (locationId = "", search = "") => {
        const res = await ApiService.getEstablishments(null, null, {
            location: locationId,
            name: search,
        });
        setEstablishments(res.data.results);
        return res.data.results;
    };

    const searchAsyncSelect = (search) => {
        getEstablishments(locationId, search);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={xs} lg={lg} sm={sm}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Seleccione una provincia"
                    // margin="normal"
                    fullWidth
                    defaultValue={""}
                    // sx={{ marginTop: "20px" }}
                    onChange={(evt) => {
                        handleChangeProvince(evt);
                        getLocations(evt.target.value);
                    }}
                >
                    {provinces.map((province) => (
                        <MenuItem key={province.id} value={province.id}>
                            {province.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={xs} lg={lg} sm={sm}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Seleccione una localidad"
                    // helperText="Por favor seleccione una localidad"
                    defaultValue={""}
                    fullWidth
                    onChange={(evt) => {
                        handleChangeLocation(evt);
                        setLocationId(evt.target.value);
                        getEstablishments(evt.target.value);
                    }}
                >
                    {locations.length > 0 ? (
                        locations.map((location) => (
                            <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled value={0}>
                            Seleccione una localidad
                        </MenuItem>
                    )}
                </TextField>
            </Grid>
            {showEstablishments && (
                <Grid item xs={xs} lg={lg} sm={sm}>
                    <AsyncSelect
                        disabled={locations.length > 0 ? false : true}
                        options={establishments}
                        onChangeSearch={(search) => searchAsyncSelect(search)}
                        onChangeSelect={(value) => {
                            handleChangeEstablishment(value);
                            setEstablishment(value);
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default SelectProvinceAndLocation;
