import { useState } from "react";

export const useNetworkGraphChart = (title, configTooltip = {}) => {
    const [options, setOptions] = useState({
        chart: {
            type: "networkgraph",
            height: 700,
        },
        title: {
            text: title,
            align: "center",
        },
        tooltip: configTooltip,
        plotOptions: {
            networkgraph: {
                keys: ["from", "to"],
                layoutAlgorithm: {
                    integration: 'verlet'
                },
                draggable: false
            },
        },
        series: [
            {
                accessibility: {
                    enabled: false,
                },
                dataLabels: {
                    enabled: true,
                    linkFormat: "",
                },
                id: "reddit-graph",
            },
        ],
        credit: false,
    });

    return {
        options,
        setOptions,
    };
};
