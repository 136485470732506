import { Button } from "@mui/material";

export const adminTableColumns = [];
export const superAdminTableInstitutionsColumns = [
    {
        field: "name",
        headerName: "Institución",
        width: 500,
        disableColumnMenu: true,
    },
    {
        field: "location",
        headerName: "Localidad",
        width: 350,
        disableColumnMenu: true,
    },
    {
        field: "actions",
        headerName: "Acciones",
        width: 300,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <div>
                    <Button
                        variant="contained"
                        onClick={() => console.log("ver ", params.id)}
                    >
                        Ver
                    </Button>
                </div>
            );
        },
    },
];

export const getUsersRadioGroupItems = (roleLogged = "student", ignoreRoleLogged = false) => {
    return [
        {
            value: "admin",
            label: "Administrador",
            show:
                ["superadmin", "admin"].includes(roleLogged) &&
                !(ignoreRoleLogged && roleLogged === "admin"),
        },
        {
            value: "teacher",
            label: "Profesores",
            show:
                ["teacher", "superadmin", "admin"].includes(roleLogged) &&
                !(ignoreRoleLogged && roleLogged === "teacher"),
        },
        {
            value: "student",
            label: "Estudiantes",
            show: true,
        },
        {
            value: "pending",
            label: "Pendientes de aprobación",
            show: ["superadmin", "admin", "teacher"].includes(roleLogged),
        },
    ];
};
export const rolesArray = [
    { name: "admin", label: "Admin" },
    { name: "teacher", label: "Profesor" },
    { name: "student", label: "Estudiante" },
];

// export const superAdminTableUsersColumns = [
//     { field: "first_name", headerName: "Nombre", width: 350 },
//     { field: "email", headerName: "Email", width: 350 },
//     {
//         field: "role",
//         headerName: "Roles",
//         width: 350,
//         renderCell: (params) => {
//             const isAdmin =
//                 params.row.roles.filter((role) => role.name === "admin")
//                     .length > 0;

//             const isTeacher =
//                 params.row.roles.filter((role) => role.name === "teacher")
//                     .length > 0;
//             const isStudent =
//                 params.row.roles.filter((role) => role.name === "student")
//                     .length > 0;

//             return (
//                 <div>
//                     <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
//                         <FormControlLabel
//                             control={<Checkbox />}
//                             onChange={() =>
//                                 handleChangeCheckBox(
//                                     params.row.roles,
//                                     isAdmin,
//                                     "admin"
//                                 )
//                             }
//                             checked={isAdmin}
//                             label="Admin"
//                         />
//                         <FormControlLabel
//                             control={<Checkbox />}
//                             checked={isTeacher}
//                             onChange={() =>
//                                 handleChangeCheckBox(
//                                     params.row.roles,
//                                     isTeacher,
//                                     "teacher"
//                                 )
//                             }
//                             label="Profesor"
//                         />
//                         <FormControlLabel
//                             control={<Checkbox />}
//                             checked={isStudent}
//                             onChange={() =>
//                                 handleChangeCheckBox(
//                                     params.row.roles,
//                                     isStudent,
//                                     "student"
//                                 )
//                             }
//                             label="Estudiante"
//                         />
//                     </FormGroup>
//                 </div>
//             );
//         },
//     },
// ];
