import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    dndContainer: {
        overflow: "auto",
        height: 500,
        // Estilos para ocultar las scrollbars
        "scrollbar-width": "none" /* Firefox */,
        "-ms-overflow-style": "none" /* IE 10+ */,
        "&::-webkit-scrollbar": {
            background: "transparent" /* Chrome/Safari/Webkit */,
            width: "0px",
            height: "0px",
        },
    },
    questionAnswerBox: {
        padding: "20px",
        border: "1px solid grey",
        borderRadius: "5px",
        marginTop: "10px !important",
    },
}));

export default useStyles;
