import React, { useEffect } from "react";
import MapChart from "common/components/MapChart/MapChart";
import { useMapChart } from "hooks/chart/UseMapChart";

const MapChartGrapher = ({ data }) => {
    const { options: optionsMapChart, setOptions: setOptionsMapChart } = useMapChart();

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const optionsMapChartAux = { ...optionsMapChart };
            const dataUpdated = {
                ...optionsMapChartAux.series[0],
                data: data.data,
            };

            optionsMapChartAux.series[0] = dataUpdated;
            setOptionsMapChart(optionsMapChartAux);
        }
    }, [data]);

    return <MapChart options={optionsMapChart} />;
};

export default MapChartGrapher;
