import { useState } from "react";
import Highcharts from "highcharts";
import topology from "utils/maps/worldTopology.json";

export const useMapChart = (title) => {
    const [options, setOptions] = useState({
        chart: {
            map: topology,
        },

        accessibility: {
            description: "Map where city locations have been defined using latitude/longitude.",
        },

        mapNavigation: {
            enabled: true,
        },

        tooltip: {
            headerFormat: "",
            pointFormat: "<b>{point.name}</b><br>Lat: {point.lat}, Lon: {point.lon}",
        },

        series: [
            {
                // Specify points using lat/lon
                type: "mappoint",
                name: "Cities",
                accessibility: {
                    point: {
                        valueDescriptionFormat:
                            "{xDescription}. Lat: {point.lat:.2f}, lon: {point.lon:.2f}.",
                    },
                },
                data: [],
                maxSize: "12%",
                color: Highcharts.getOptions().colors[0],
            },
            {
                // Use the gb-all map with no data as a basemap
                name: "Great Britain",
                borderColor: "#A0A0A0",
                nullColor: "rgba(200, 200, 200, 0.3)",
                showInLegend: false,
            },
            {
                name: "Separators",
                type: "mapline",
                nullColor: "#707070",
                showInLegend: false,
                enableMouseTracking: false,
                accessibility: {
                    enabled: false,
                },
            },
        ],
    });

    return {
        options,
        setOptions,
    };
};
