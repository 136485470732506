import {
    Autocomplete,
    DialogContent,
    DialogContentText,
    TextField,
} from "@mui/material";
import React from "react";

const DialogEstablishmentContent = ({
    selectedEstablishment,
    users,
    handleSetResponsibleToEstablishment,
    handleSetManagersToEstablishment,
    selectedResponsible = null,
    selectedManagers = [],
}) => {
    console.log(users);
    return (
        <DialogContent>
            <DialogContentText>
                <strong>Dirección:</strong> {selectedEstablishment.address}
            </DialogContentText>
            <DialogContentText>
                <strong>Email:</strong> {selectedEstablishment.email}
            </DialogContentText>
            <DialogContentText>
                <strong>Teléfono:</strong> {selectedEstablishment.phone}
            </DialogContentText>
            <DialogContentText>
                <strong>Cue:</strong> {selectedEstablishment.cue}
            </DialogContentText>
            {/* <Autocomplete
                sx={{ marginTop: "16px" }}
                id="autocomplete"
                freeSolo
                // options={products.map((option) => option.name)}
                options={users}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.first_name} {option.last_name}
                        </li>
                    );
                }}
                getOptionLabel={(option) => {
                    return `${option.first_name} ${option.last_name}`;
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Seleccione un encargado" />
                )}
                value={selectedResponsible}
                onChange={(evt, newValue) => {
                    if (newValue !== null) {
                        console.log(newValue);
                        handleSetResponsibleToEstablishment(newValue);
                    }
                }}
            />
            <Autocomplete
                sx={{ marginTop: "16px" }}
                id="autocomplete"
                // freeSolo
                multiple
                // options={products.map((option) => option.name)}
                options={users}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.first_name} {option.last_name}
                        </li>
                    );
                }}
                getOptionLabel={(option) => {
                    return `${option.first_name} ${option.last_name}`;
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Seleccione administradores" />
                )}
                value={selectedManagers}
                onChange={(evt, newValue) => {
                    if (newValue !== null) {
                        handleSetManagersToEstablishment(newValue);
                    }
                }}
            /> */}
        </DialogContent>
    );
};

export default DialogEstablishmentContent;
