import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { GoogleLogin, googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useEffect, useState } from "react";

const SignInForm = ({
    logInFormData,
    setLogInFormData,
    setOpenLoader,
    handleLogIn,
    handleChangeForms,
    handleClose,
    setShowError,
    showError,
}) => {
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const checkLoginFormData = (evt) => {
        evt.preventDefault();
        setShowError(false);
        if (!logInFormData.email.trim()) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false);
        }
        if (!logInFormData.password.trim()) {
            setPasswordError(true);
            return;
        } else {
            setPasswordError(false);
        }
        setOpenLoader(true);

        handleLogIn();
    };

    const [user, setUser] = useState();
    const [profile, setProfile] = useState([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log("Login Failed:", error),
    });

    const onGoogleLoginSuccess = () => {
        const GOOGLE_AUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth";
        const REDIRECT_URI = "auth/google/";
        const api = process.env.REACT_APP_API;

        const scope = [
            "https://www.googleapis.com/auth/userinfo.email",
            "https://www.googleapis.com/auth/userinfo.profile",
        ].join(" ");

        const params = {
            response_type: "code",
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            redirect_uri: `${api}/${REDIRECT_URI}`,
            prompt: "select_account",
            scope,
        };

        const urlParams = new URLSearchParams(params).toString();
        window.location = `${GOOGLE_AUTH_URL}?${urlParams}`;
    };

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    return (
        <>
            <form>
                <DialogTitle sx={{ width: { md: "500px" } }}>Iniciar Sesión</DialogTitle>
                <DialogContent>
                    <TextField
                        error={emailError}
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Nombre de usuario"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(evt) => {
                            setLogInFormData({
                                email: evt.target.value,
                                password: logInFormData.password,
                            });
                            setEmailError(false);
                        }}
                    />
                    <TextField
                        error={passwordError}
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Contraseña"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={(evt) => {
                            setLogInFormData({
                                email: logInFormData.email,
                                password: evt.target.value,
                            });
                            setPasswordError(false);
                        }}
                    />
                    <div style={{ marginTop: "10px" }}>
                        <Typography variant="p">
                            <small>
                                Todavía no tienes cuenta?{" "}
                                <Typography
                                    variant="p"
                                    sx={{
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                    }}
                                    color={"primary.dark"}
                                    onClick={() => {
                                        handleChangeForms();
                                    }}
                                >
                                    Registrate
                                </Typography>
                            </small>
                        </Typography>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <GoogleLogin
                            onSuccess={(credentialResponse) => {
                                onGoogleLoginSuccess();
                            }}
                            onError={() => {
                                console.log("Login Failed");
                            }}
                        >
                            Cerrar google
                        </GoogleLogin>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ backgroundColor: "primary.light" }}
                    >
                        Cancelar
                    </Button>
                    <Button type="submit" variant="contained" onClick={checkLoginFormData}>
                        Iniciar Sesión
                    </Button>
                </DialogActions>
            </form>
            {showError === true ? (
                <Grid
                    item
                    xs={12}
                    md={7}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "12px",
                    }}
                >
                    <Alert severity="error">
                        El email o la contraseñas son incorrectos, intente nuevamente!
                    </Alert>
                </Grid>
            ) : null}
        </>
    );
};

export default SignInForm;
