import React from "react";
import {
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Switch,
} from "@mui/material";

const ExcludedWords = ({ itemsState, handleChange }) => {
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Excluir:</FormLabel>
            <FormGroup>
                <Grid container sx={{ width: "100%" }}>
                    {itemsState.map((item) => (
                        <Grid key={item.name} item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={item.checked}
                                        onChange={(evt) =>
                                            handleChange(evt, item)
                                        }
                                        name={item.name}
                                    />
                                }
                                label={item.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </FormGroup>
        </FormControl>
    );
};

export default ExcludedWords;
