import axios from "axios";

const headersJson = {
    headers: {
        "Content-Type": "application/json",
    },
};

const headersMultipart = () => {
    const token = `Bearer ${localStorage.getItem("access")}`;
    return {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
        },
    };
};

const headersJsonAuth = () => {
    const token = `Bearer ${localStorage.getItem("access")}`;
    return {
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    };
};

const createHeadersJsonPaginated = (headers, page, pageSize) => {
    return {
        ...headers,
        params: {
            page,
            page_size: pageSize,
        },
    };
};

const headersJsonPaginated = (page, pageSize, auth) => {
    const headers = auth ? headersJsonAuth() : headersJson;
    return createHeadersJsonPaginated(headers, page, pageSize);
};

const getHeadersJsonPaginated = (page, pageSize, params, auth = true) => {
    const paginatedHeaders = headersJsonPaginated(page, pageSize, auth);
    return {
        ...paginatedHeaders,
        params: {
            ...paginatedHeaders.params,
            ...params,
        },
    };
};

const getHeadersJsonFilter = (params, auth = true) => {
    const headers = auth ? headersJsonAuth() : headersJson;
    return {
        ...headers,
        params: {
            ...params,
        },
    };
};

const api = process.env.REACT_APP_API;

export const ApiService = {
    login: async (data) => {
        const result = await axios.post(`${api}/api/token/`, data, headersJson);
        return result;
    },
    me: async (token) => {
        const result = await axios.get(`${api}/auth/me/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        return result;
    },
    register: async (data) => {
        const result = await axios.post(`${api}/auth/register/`, data, headersJson);
        return result;
    },
    completeRegisterGoogle: async (data) => {
        const result = await axios.post(
            `${api}/auth/complete-register-google/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getFilterUsers: async (page = 1, pageSize = 10, params) => {
        const filterParams = getHeadersJsonPaginated(page, pageSize, params);
        const result = await axios.get(`${api}/auth/users/`, filterParams);
        return result;
    },
    getProvinces: async () => {
        const result = await axios.get(`${api}/institutional/provinces/`, headersJson);
        return result;
    },
    getLocations: async (id) => {
        const result = await axios.get(
            `${api}/institutional/locations/?province=${id}`,
            headersJson
        );
        return result;
    },
    getRoles: async () => {
        const result = await axios.get(`${api}/auth/roles/`, headersJsonAuth());
        return result;
    },
    getEstablishments: async (page = 1, pageSize = 10, params, auth = true) => {
        const filterParams = getHeadersJsonPaginated(page, pageSize, params, auth);
        const result = await axios.get(`${api}/institutional/establishment/`, filterParams);
        return result;
    },
    getUserEstablishments: async (userId) => {
        const result = await axios.get(
            `${api}/institutional/establishment/?user=${userId}`,
            headersJsonAuth()
        );
        return result;
    },
    getUserEsetablishmentRoles: async (userId, establishmentId) => {
        const result = await axios.get(
            `${api}/auth/roles/?user=${userId}&establishment=${establishmentId}`,
            headersJsonAuth()
        );
        return result;
    },
    updateUserRoles: async (userId, data) => {
        const result = await axios.put(
            `${api}/auth/users/${userId}/update-role/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    requestUserRoleEstablishment: async (data) => {
        const result = await axios.put(
            `${api}/auth/user-role-establishments/request-user-role-establishment/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    setEstablishmentManagers: async (establishmentId, data) => {
        const result = await axios.put(
            `${api}/institutional/establishment/${establishmentId}/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    uploadFile: async (data) => {
        const result = await axios.post(`${api}/activity/upload-file`, data, headersMultipart());
        return result;
    },
    getRedditGraph: async (params) => {
        const result = await axios.get(
            `${api}/flow-activity/reddit/word-frequency/`,
            getHeadersJsonFilter(params)
        );
        return result;
    },
    getRedditGraphUserComments: async (params) => {
        const result = await axios.get(
            `${api}/flow-activity/reddit/user-comments/`,
            getHeadersJsonFilter(params)
        );
        return result;
    },
    getCourses: async (page = 1, pageSize = 10, params, auth = true) => {
        const filterParams = getHeadersJsonPaginated(page, pageSize, params, auth);
        const result = await axios.get(`${api}/institutional/courses/`, filterParams);
        return result;
    },
    getCourse: async (id) => {
        const result = await axios.get(`${api}/institutional/courses/${id}/`, headersJsonAuth());
        return result;
    },
    getCourseFromId: async (id) => {
        const result = await axios.get(`${api}/institutional/courses/${id}/`, headersJsonAuth());
        return result;
    },
    createCourse: async (data) => {
        const result = await axios.post(`${api}/institutional/courses/`, data, headersJsonAuth());
        return result;
    },

    getSubjects: async (page = 1, pageSize = 10, params, auth = true) => {
        const filterParams = getHeadersJsonPaginated(page, pageSize, params, auth);
        const result = await axios.get(`${api}/institutional/subjects/`, filterParams);
        return result;
    },
    joinCourse: async (data) => {
        const result = await axios.post(
            `${api}/institutional/user-course-requests/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getUserCourseRequests: async (page = 1, pageSize = 10, params, auth = true) => {
        const filterParams = getHeadersJsonPaginated(page, pageSize, params, auth);
        const result = await axios.get(`${api}/institutional/user-course-requests/`, filterParams);
        return result;
    },
    pendingNotification: async (userId) => {
        const result = await axios.get(`${api}/common/pending-notification`, headersJsonAuth());
        return result;
    },
    createActivity: async (data) => {
        const result = await axios.post(`${api}/activity/activities/`, data, headersJsonAuth());
        return result;
    },
    cloneActivity: async (data, idActivity) => {
        const result = await axios.post(
            `${api}/activity/activities/${idActivity}/clone/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    createSubjectCourseActivity: async (data) => {
        const result = await axios.post(
            `${api}/activity/subject-course-activity/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getSubjectCourseActivities: async () => {
        const result = await axios.get(
            `${api}/activity/subject-course-activity/`,
            headersJsonAuth()
        );
        return result;
    },
    getSubjectCourseActivity: async (id) => {
        const result = await axios.get(
            `${api}/activity/subject-course-activity/${id}/`,
            headersJsonAuth()
        );
        return result;
    },
    getDataGraphBasic: async (data = {}, id) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity/${id}/get-data-graph-basic/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getValuesForHeader: async (id, params = {}) => {
        const result = await axios.get(
            `${api}/flow-activity/student-activity/${id}/values-for-header/`,
            getHeadersJsonFilter(params)
        );
        return result;
    },
    getDataGraphColumn: async (data = {}, id) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity/${id}/get-data-graph-group-by/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getDataGraphMap: async (data = {}, id) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity/${id}/get-data-graph-map/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getDataGraph: async (data = {}, id) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity/${id}/get-data-graph/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getDataGraphBetweenness: async (data = {}, id) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity/${id}/get-data-graph-betweenness/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getDataWordCloud: async (data = {}, id) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity/${id}/get-data-word-cloud/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    createStudentActivityResolution: async (data = {}) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity-resolution/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getStudentActivityResolutions: async (params) => {
        const result = await axios.get(
            `${api}/flow-activity/student-activity-resolution/`,
            getHeadersJsonFilter(params)
        );
        return result;
    },
    addQuestion: async (id, data = {}) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity/${id}/add-question/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    qualify: async (data = {}, id) => {
        const result = await axios.post(
            `${api}/flow-activity/student-activity/${id}/qualify/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    addAnswer: async (data = {}) => {
        const result = await axios.post(
            `${api}/activity/activity-question/add-answer/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    getActivities: async (page = 1, pageSize = 10, params, auth = true) => {
        const filterParams = getHeadersJsonPaginated(page, pageSize, params, auth);
        const result = await axios.get(`${api}/activity/activities/`, filterParams);
        return result;
    },
    getStudentActivities: async (params) => {
        const result = await axios.get(
            `${api}/flow-activity/student-activity/`,
            getHeadersJsonFilter(params)
        );
        return result;
    },
    getStudentActivity: async (id) => {
        const result = await axios.get(
            `${api}/flow-activity/student-activity/${id}/`,
            headersJsonAuth()
        );
        return result;
    },
    getGraphs: async () => {
        const result = await axios.get(`${api}/flow-activity/graph/`, headersJsonAuth());
        return result;
    },
    getStaticDataSources: async (page = 1, pageSize = 10, params, auth = true) => {
        const filterParams = getHeadersJsonPaginated(page, pageSize, params, auth);
        const result = await axios.get(`${api}/activity/static-data-source/`, filterParams);
        return result;
    },
    getDatasetsCloneCreate: async (params) => {
        const result = await axios.get(
            `${api}/activity/static-data-source/get-data-clone-create/`,
            getHeadersJsonFilter(params)
        );
        return result;
    },
    getUserRoleEstablishments: async (params) => {
        const result = await axios.get(
            `${api}/auth/user-role-establishments/`,
            getHeadersJsonFilter(params)
        );
        return result;
    },
    acceptOrRejectRole: async (ureId, data) => {
        const result = await axios.put(
            `${api}/auth/user-role-establishments/${ureId}/accept-or-reject/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    acceptOrRejectCourse: async (ucreId, data) => {
        const result = await axios.put(
            `${api}/institutional/user-course-requests/${ucreId}/accept-or-reject/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
    createSubject: async (data) => {
        const result = await axios.post(`${api}/institutional/subjects/`, data, headersJsonAuth());
        return result;
    },
    updateSubject: async (data, subjectId) => {
        const result = await axios.put(
            `${api}/institutional/subjects/${subjectId}/`,
            data,
            headersJsonAuth()
        );
        return result;
    },
};
