import { React, useState, Fragment, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container, List, ListItem, ListItemText, MenuItem, TextField } from "@mui/material";

import useStyles, { stylesSx } from "./styles";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import DialogUserEstablishmentContent from "modules/authentication/pages/SuperAdmin/components/DialogUserEstablishmentContent";
import { useAuth } from "context/AuthContext";
import { ApiService } from "common/services/ApiService";
import { ROLE_MAP } from "utils/constants";

const Profile = () => {
    const { containerStyle, cardStyle } = stylesSx;
    const { auth } = useAuth();

    const [user, setUser] = useState({});
    const [dataSubmit, setDataSubmit] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [pendingUserRoleEstablishment, setPendingUserRoleEstablishment] = useState(false);

    const handleSave = async () => {
        const data = {
            ...dataSubmit,
            roles: dataSubmit.roles.map((r) => r.value),
            user: user.id,
        };

        await ApiService.requestUserRoleEstablishment(data);
    };

    const getDataUser = async () => {
        const result = await ApiService.me(auth?.access);
        const dataUser = result.data;
        setPendingUserRoleEstablishment(
            dataUser && dataUser.role_establishment_pending_list.length > 0
        );
        setUser(dataUser);
    };

    useEffect(() => {
        getDataUser();
    }, []);

    return (
        <Container sx={containerStyle}>
            <Card sx={cardStyle}>
                <CardContent sx={{ textAlign: "center" }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {`${user.first_name} ${user.last_name}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {user.selectedRole?.name}
                    </Typography>
                    <Typography gutterBottom variant="p" component="div">
                        Lolcalidad
                    </Typography>
                    <Typography gutterBottom variant="p">
                        <strong>INSTITUTOS RELACIONADOS</strong>
                        <ul>
                            {user.role_establishment_list?.map(
                                (establishment) => (
                                    <li key={establishment.establishment.id}>
                                        {establishment.establishment.name}
                                    </li>
                                )
                            )}
                        </ul>
                    </Typography>
                </CardContent>
                <CardActions sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    {pendingUserRoleEstablishment && (
                        <div>
                            <Typography variant="body2" color="text.secondary">
                                Usted tiene pendiente de aprobacion los siguientes roles
                            </Typography>
                            <List>
                                {user?.role_establishment_pending_list.map((re, idx) => (
                                    <Fragment key={idx}>
                                        <ListItem disablePadding>
                                            <ListItemText primary={`- ${re.establishment.name}`} />
                                        </ListItem>
                                        <ListItem disablePadding sx={{ pl: 5 }}>
                                            <ListItemText
                                                primary={`Roles: ${re.roles
                                                    .map((role) => ROLE_MAP[role])
                                                    .join(", ")}`}
                                            />
                                        </ListItem>
                                    </Fragment>
                                ))}
                            </List>
                        </div>
                    )}
                    <Button
                        variant="contained"
                        disabled={pendingUserRoleEstablishment}
                        size="small"
                        onClick={() => {
                            setOpenDialog(true);
                        }}
                    >
                        Añadir instituto
                    </Button>
                </CardActions>
                {openDialog && (
                    <GenericDialog
                        handleSave={handleSave}
                        open={openDialog}
                        setOpen={setOpenDialog}
                        title={"Solicitud nuevo instituto"}
                        onClose={() => {}}
                    >
                        <DialogUserEstablishmentContent
                            isSuperAdmin
                            showPreviousRoleList={false}
                            selectedUser={user}
                            handleChangeExternalData={(data) => {
                                setDataSubmit({
                                    ...dataSubmit,
                                    ...data,
                                });
                            }}
                        ></DialogUserEstablishmentContent>
                    </GenericDialog>
                )}
            </Card>
        </Container>
    );
};

export default Profile;
