import Chart from "common/components/Chart/Chart";
import { useNetworkGraphBetweennessChart } from "hooks/chart/UseNetworkGraphBetweennessChart";
import { useNetworkGraphChart } from "hooks/chart/UseNetworkGraphChart";
import React, { useEffect, useMemo } from "react";
import { NETWORKGRAPH } from "utils/constants";


const StaticNetworkBetweennessChart = ({ data }) => {
    const {
        options: optionsStaticNetworkChart,
        setOptions: setOptionsStaticNetworkChart,
    } = useNetworkGraphBetweennessChart("Ocurrencias");

    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            setOptionsStaticNetworkChart({
                ...optionsStaticNetworkChart,
                series: [
                    {
                        ...optionsStaticNetworkChart.series[0],
                        data: data.links,
                        nodes: data.nodes.map((n) => ({
                            id: n.id,
                            marker: {
                                radius: n.frequency === 0 ? 1 : n.frequency * 250,  // Adjust the multiplier for visibility
                            }
                        })),
                    },
                ],
            });
        }
    }, [data]);

    return <Chart options={optionsStaticNetworkChart} type={NETWORKGRAPH} />;
};

export default StaticNetworkBetweennessChart;
