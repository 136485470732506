import { MenuItem, TextField } from "@mui/material";
import DateFieldComponent from "common/components/DateFieldComponent/DateFieldComponent";
import { ApiService } from "common/services/ApiService";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

const DialogContentInputs = ({ courseFormData, setCourseFormData }) => {
    const [subjects, setSubjects] = useState([]);
    useEffect(() => {
        const getSubjects = async () => {
            const res = await ApiService.getSubjects(null, null, {
                no_page: 1,
            });
            setSubjects(res.data);
        };

        getSubjects();
    }, []);

    return (
        <>
            <TextField
                // error={emailError}
                autoFocus
                margin="normal"
                id="name"
                fullWidth
                label="Nombre del curso"
                type="text"
                // variant="standard"
                onChange={(evt) => {
                    setCourseFormData({
                        ...courseFormData,
                        name: evt.target.value,
                    });
                    // setEmailError(false);
                }}
            />
            <TextField
                fullWidth
                id="subject"
                select
                label="Materia"
                value={courseFormData.subject}
                onChange={(evt) => {
                    setCourseFormData({
                        ...courseFormData,
                        subject: evt.target.value,
                    });
                }}
                margin="normal"
            >
                {subjects.map((subject) => (
                    <MenuItem key={subject.id} value={subject.id}>
                        {subject.name}
                    </MenuItem>
                ))}
            </TextField>
            <DateFieldComponent
                variant={"outlined"}
                label={"Fecha de inicio"}
                handleChange={(evt) => {
                    setCourseFormData({
                        ...courseFormData,
                        start_date: dayjs(evt).format("YYYY-MM-DD"),
                    });
                }}
            ></DateFieldComponent>
            <DateFieldComponent
                variant={"outlined"}
                label={"Fecha de fin"}
                handleChange={(evt) => {
                    setCourseFormData({
                        ...courseFormData,
                        end_date: dayjs(evt).format("YYYY-MM-DD"),
                    });
                }}
            ></DateFieldComponent>
            <TextField
                // error={emailError}
                margin="normal"
                id="cicle"
                fullWidth
                label="Ciclo lectivo"
                type="text"
                // variant="standard"
                onChange={(evt) => {
                    setCourseFormData({
                        ...courseFormData,
                        cicle: evt.target.value,
                    });
                    // setEmailError(false);
                }}
            />
            <TextField
                // error={emailError}
                margin="normal"
                id="name"
                fullWidth
                label="Año de cursada"
                type="text"
                // variant="standard"
                onChange={(evt) => {
                    setCourseFormData({
                        ...courseFormData,
                        year: evt.target.value,
                    });
                    // setEmailError(false);
                }}
            />
        </>
    );
};

export default DialogContentInputs;
