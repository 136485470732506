import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import GenericTable from "common/components/GenericTable/GenericTable";

import getColumns from "modules/activity/utils/getColumns";
import { ApiService } from "common/services/ApiService";
import { useAuth } from "context/AuthContext";
import { checkGridRowIdIsValid } from "@mui/x-data-grid";
import BackButton from "common/components/BackButton/BackButton";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const TAB_MAP = {
    0: "PEND",
    1: "CORR",
    2: "DELI",
    3: "REDE",
};

const Activities = () => {
    const [value, setValue] = useState(0);
    const { id, courseId } = useParams();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const [rows, setRows] = useState();
    const [course, setCourse] = useState({});

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const getStudentActivities = async () => {
            const result = await ApiService.getStudentActivities({
                student: auth?.selectedRole.name === "student" ? auth.userId : null,
                delivery_status: TAB_MAP[value],
                course: auth?.selectedRole.name === "student" ? id : courseId,
                activity: ["teacher", "admin", "superadmin"].includes(auth?.selectedRole.name)
                    ? id
                    : null,
            });
            if (result.data.length === 0) {
                setRows([]);
                return;
            }
            setRows(
                result.data.map((d) => ({
                    ...d.feedback,
                    ...d.course_activity.activity,
                    id: d.id,
                    userName: d.student.email,
                    firstName: d.student.first_name,
                    lastName: d.student.last_name,
                }))
            );
        };
        getStudentActivities();
    }, [value]);

    useEffect(() => {
        if (courseId) {
            const getCourse = async () => {
                const result = await ApiService.getCourse(courseId);
                setCourse(result.data);
            };
            getCourse();
        }
    }, [courseId]);

    return (
        <Container>
            <Box style={{ padding: "10px 0px 0px 0px" }}>
                <BackButton />
            </Box>
            <h1>
                {auth.selectedRole.name === "student" ? "Mis actividades" : "Actividades del curso"}{" "}
                - {course?.name}
            </h1>
            <Box sx={{ width: "100%", marginTop: "3%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Pendientes" {...a11yProps(0)} />
                        <Tab label="Corregidas" {...a11yProps(1)} />
                        <Tab label="Entregadas" {...a11yProps(2)} />
                        <Tab label="Pendientes (Reentrega)" {...a11yProps(3)} />
                        {/* <Tab label="Vencidas" {...a11yProps(4)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {rows && (
                        <GenericTable
                            rows={rows}
                            columns={getColumns.getPendingColumns(
                                navigate,
                                auth?.selectedRole.name
                            )}
                            columnVisibilityModel={{
                                title: auth?.selectedRole.name === "student",
                                firstName: auth?.selectedRole.name !== "student",
                                lastName: auth?.selectedRole.name !== "student",
                                userName: auth?.selectedRole.name !== "student",
                            }}
                        ></GenericTable>
                    )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {rows && (
                        <GenericTable
                            rows={rows}
                            columns={getColumns.getPendingColumns(
                                navigate,
                                auth?.selectedRole.name
                            )}
                            columnVisibilityModel={{
                                title: auth?.selectedRole.name === "student",
                                firstName: auth?.selectedRole.name !== "student",
                                lastName: auth?.selectedRole.name !== "student",
                                userName: auth?.selectedRole.name !== "student",
                            }}
                        ></GenericTable>
                    )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {rows && (
                        <GenericTable
                            rows={rows}
                            columns={getColumns.getCorrectedColumns(
                                navigate,
                                auth?.selectedRole.name
                            )}
                            columnVisibilityModel={{
                                title: auth?.selectedRole.name === "student",
                                firstName: auth?.selectedRole.name !== "student",
                                lastName: auth?.selectedRole.name !== "student",
                                userName: auth?.selectedRole.name !== "student",
                            }}
                        ></GenericTable>
                    )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {rows && (
                        <GenericTable
                            rows={rows}
                            columns={getColumns.getDeliveredColumns(
                                navigate,
                                auth?.selectedRole.name
                            )}
                            columnVisibilityModel={{
                                title: auth?.selectedRole.name === "student",
                                firstName: auth?.selectedRole.name !== "student",
                                lastName: auth?.selectedRole.name !== "student",
                                userName: auth?.selectedRole.name !== "student",
                            }}
                        ></GenericTable>
                    )}
                </TabPanel>
                <TabPanel value={value} index={4}>
                    {rows && (
                        <GenericTable
                            rows={rows}
                            columns={getColumns.getExpiredColumns()}
                        ></GenericTable>
                    )}
                </TabPanel>
                <TabPanel value={value} index={5}>
                    {rows && (
                        <GenericTable
                            rows={rows}
                            columns={getColumns.getDelayedColumns()}
                        ></GenericTable>
                    )}
                </TabPanel>
            </Box>
        </Container>
    );
};

export default Activities;
