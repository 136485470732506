import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    linkNavBar: { textDecoration: "none", color: "white" },
    logo: { width: "70px" },
    superAdminButton: {
        color: "grey",
    },
}));

export const stylesSx = {
    boxFlexStyle: {
        flexGrow: 1,
    },
    boxDisplayStyle: { display: { md: "flex" } },
    appBarStyle: { backgroundColor: "primary.dark" },
    typographyDivStyle: { display: "flex" },
    menuButtons: {
        my: 2,
        color: "inherit",
        display: "flex",
        alignItems: "center",
    },
    menuButtonsMobile: {
        color: "inherit",
        padding: "5px 0px !important",
        width: "100%",
        justifyContent: "start",
    },
    menuText: {
        display: { md: "flex" },
        alignItems: "center",
        fontWeight: "bold",
        color: "white",
    },
};

export default useStyles;
