import React, { useEffect } from "react";

import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import ProtectedRoute from "common/pages/ProtectedRoute/ProtectedRoute";

import Home from "common/pages/Home/Home";
import HomeAd from "common/pages/Home/HomeAd";
import Admin from "modules/authentication/pages/Admin/Admin";
import SuperAdmin from "modules/authentication/pages/SuperAdmin/SuperAdmin";
import Datasets from "modules/activity/pages/Datasets/Datasets";
import Activities from "modules/activity/pages/Activities/Activities";
import Profile from "modules/institutional/pages/Profile/Profile";
import theme from "theme/theme";
import NavBar from "common/components/NavBar/NavBar";

import { useAuth } from "context/AuthContext";
import SocialGraphs from "modules/activity/pages/SocialGraphs/SocialGraphs";
import Teacher from "modules/authentication/pages/Teacher/Teacher";
import Graphs from "modules/activity/pages/Graphs/Graphs";
import CourseDetail from "modules/authentication/components/CourseDetail/CourseDetail";
import Grapher from "modules/activity/pages/Activities/components/Grapher";
import CoursesList from "modules/authentication/components/CoursesList/CorusesList";
import queryString from "query-string";

function App() {
    const { auth } = useAuth();

    const isAdmin =
        !!auth &&
        auth?.rolesEstablishment &&
        auth?.rolesEstablishment.some((re) => re.roles.includes("admin")) &&
        auth?.selectedRole?.name === "admin";
    const isSuperAdmin =
        !!auth &&
        auth?.rolesEstablishment &&
        auth?.rolesEstablishment.some((re) => re.roles.includes("superadmin")) &&
        auth?.selectedRole?.name === "superadmin";
    const isTeacher =
        !!auth &&
        auth?.rolesEstablishment &&
        auth?.rolesEstablishment.some((re) => re.roles.includes("teacher")) &&
        auth?.selectedRole?.name === "teacher";

    const isStudent =
        !!auth &&
        auth?.rolesEstablishment &&
        auth?.rolesEstablishment.some((re) => re.roles.includes("student")) &&
        auth?.selectedRole?.name === "student";

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <CssBaseline />
                <NavBar />
                <div style={{ paddingTop: 80 }}>
                    <Routes>
                        {/* <Route path="/" element={<Home />} /> */}
                        <Route path="/" element={<HomeAd />} />
                        {/* ENVOLVIENDO LAS RUTAS DENTRO DE ESTE ROUTE PUEDO PROTEGERLAS POR LA CONDICION QUE YO QUIERA */}
                        <Route element={<ProtectedRoute isAllowed={!!auth} />}>
                            {/* DATASETS ROUTES */}
                            <Route
                                path="/datasets/public"
                                element={<Datasets title={"públicos"} />}
                            />
                            <Route
                                path="/datasets/protected"
                                element={<Datasets title={"protegidos"} />}
                            />

                            {/* GRAPHS ROUTES */}
                            <Route path="/graphs/:id" element={<SocialGraphs />} />
                            <Route path="/graphs" element={<Graphs />} />
                            <Route path="/grapher/:id" element={<Grapher />} />

                            {/* USER ROUTES */}
                            <Route path="/activities" element={<Activities />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route
                                path="/courses"
                                element={
                                    <Container maxWidth={"xl"}>
                                        <CoursesList
                                            columnVisibilityModel={{
                                                adminActions: false,
                                            }}
                                        />
                                    </Container>
                                }
                            />
                        </Route>

                        {/* ADMIN */}
                        <Route element={<ProtectedRoute isAllowed={isAdmin} />}>
                            <Route path="/admin" element={<Admin />} />
                            <Route path="/admin/course/:id" element={<CourseDetail />} />
                            <Route path="/admin/:courseId/activity/:id" element={<Activities />} />
                        </Route>

                        {/* SUPERADMIN ROUTES */}
                        <Route element={<ProtectedRoute isAllowed={isSuperAdmin} />}>
                            <Route path="/superadmin" element={<SuperAdmin />} />
                            <Route path="/superadmin/course/:id" element={<CourseDetail />} />
                            <Route
                                path="/superadmin/:courseId/activity/:id"
                                element={<Activities />}
                            />
                        </Route>

                        {/* TEACHER ROUTES */}
                        <Route element={<ProtectedRoute isAllowed={isTeacher} />}>
                            <Route path="/teacher" element={<Teacher />} />
                            <Route path="/teacher/course/:id" element={<CourseDetail />} />
                            <Route
                                path="/teacher/:courseId/activity/:id"
                                element={<Activities />}
                            />
                        </Route>

                        {/* STUDENT ROUTES */}
                        <Route element={<ProtectedRoute isAllowed={isStudent} />}>
                            <Route path="/student/course/:id" element={<CourseDetail />} />
                        </Route>

                        <Route path="*" element={<Navigate to="/"></Navigate>} />
                    </Routes>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
