import { useState } from "react";

export const useNetworkGraphBetweennessChart = (title, configTooltip = {}) => {
    const [options, setOptions] = useState({
        chart: {
            type: "networkgraph",
            height: 700,
        },
        title: {
            text: 'Network Graph with Betweenness Centrality'
        },
        plotOptions: {
            networkgraph: {
                keys: ["from", "to"],
                draggable: true,
                layoutAlgorithm: {
                    integration: 'verlet'
                },
            }
        },
        series: [{
            dataLabels: {
                enabled: true,
                linkFormat: ''
            },

        }]
    });

    return {
        options,
        setOptions,
    };
};
