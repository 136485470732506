const palette = {
    primary: {
        main: "#12416F",
        light: "#597A9A",
        dark: "#0D2E4E",
    },
    secondary: {
        main: "#1DBDCD",
        light: "#4ACAD7",
        dark: "#14848F",
        white: "#FFF",
    },
    warning: {
        main: "#FFB74D",
        light: "rgba(255, 183, 77, 0.5)",
        dark: "#FF9800",
    },
    error: {
        main: "#F44336",
        light: "#E57373",
        dark: "#D32F2F",
    },
    info: {
        main: "#2196F3",
        light: "#64B5F6",
        dark: "#1976D2",
    },
    success: {
        main: "#4CAF50",
        light: "#81C784",
        dark: "#388E3C",
    },
    grey: {
        500: "#E1E1E1",
        700: "#787E8F",
        900: "#5C5C5C",
        light: "#EFEFEF",
        main: "#787E8F",
        dark: "#3D3E40",
    },
    text: {
        primary: "#3D3E40",
        secondary: "#787E8F",
        disabled: "#9E9E9E",
    },
    background: {
        default: "#FAFAFA",
        paper: "#FAFAFA",
        disabled: "#E6E6E6",
        row: "#E7EBEF",
        rowHeader: "#E9E9E9",
    },
    icon: {
        main: "#D6DDFF",
        disabled: "#C5C5C5",
        light: "#A8B7FB",
        dark: "rgba(41, 98, 255, 0.4)",
    },
    action: {
        active: "#12416F",
        hover: "rgba(74, 202, 215, 0.15)",
        selected: "rgba(74, 202, 215, 0.30)",
        press: "rgba(74, 202, 215, 0.45)",
        disabled: "#9E9E9E",
        disabledBackground: "#E6E6E6",
        focus: "rgba(74, 202, 215, 0.12)",
    },
    divider: "#E1E1E1",
    white: "#FFF",
    black: "#000",
    primaryOutlinedHover: "#E7ECF0",
    primaryOutlinedPress: "#91A7BC",
    secondaryOutlinedHover: "#E7ECF0",
    secondaryOutlinedPress: "#A4E4EA",
};

export default palette;
