import Chart from "common/components/Chart/Chart";
import { useNetworkGraphChart } from "hooks/chart/UseNetworkGraphChart";
import React, { useEffect } from "react";
import { NETWORKGRAPH } from "utils/constants";

const SocialNetworkChart = ({ data }) => {
    const {
        options: optionsNetworkGraphChartReddit,
        setOptions: setOptionsNetworkGraphChartReddit,
    } = useNetworkGraphChart("Ocurrencias");

    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            setOptionsNetworkGraphChartReddit({
                ...optionsNetworkGraphChartReddit,
                series: [
                    {
                        ...optionsNetworkGraphChartReddit.series[0],
                        data: data.links,
                        nodes: data.nodes.map((n) => ({
                            id: n.id,
                            marker: {
                                radius: n.frequency,
                            },
                        })),
                    },
                ],
            });
        }
    }, [data]);

    return <Chart options={optionsNetworkGraphChartReddit} type={NETWORKGRAPH} />;
};

export default SocialNetworkChart;
