import { Button, Container, DialogContent } from "@mui/material";
import FilterBar from "common/components/FilterBar/FilterBar";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import { ApiService } from "common/services/ApiService";
import { useAuth } from "context/AuthContext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CourseActivitiesList = ({ columnVisibilityModel, courseId }) => {
    const navigate = useNavigate();
    const { auth } = useAuth();

    const [openLoader, setOpenLoader] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filter, setFilter] = useState("");
    const [extraFilter, setExtraFilter] = useState({});
    const [page, setPage] = useState(1);

    const [activities, setActivities] = useState([]);

    const getActivities = async (filter = "", extraFilter = {}, page = 1, pageSize = 10) => {
        setOpenLoader(true);
        const params = {
            ...extraFilter,
            search: filter,
            course: courseId,
        };

        const res = await ApiService.getActivities(page, pageSize, params);

        setRowCount(res.data.count);
        setPageSize(pageSize);
        setFilter(filter);
        setExtraFilter(extraFilter);
        setPage(page);

        setActivities(
            res.data.results.map((activity) => ({
                id: activity.id,
                title: activity.title,
                description: activity.description,
                establishment: activity.establishment,
                visibility: activity.visibility,
            }))
        );
        setOpenLoader(false);
    };

    const activitiesColumns = [
        { field: "title", headerName: "Titúlo", width: 150 },
        { field: "description", headerName: "Descripción", width: 150 },
        { field: "establishment", headerName: "Establecimiento", width: 450 },
        {
            field: "actions",
            headerName: "Acciones",
            width: 300,
            hideable: true,
            renderCell: (params) => {
                console.log(params);
                return (
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate(
                                `/${auth.selectedRole?.name}/${courseId}/activity/${params.row.id}`
                            );
                        }}
                    >
                        Ver actividad
                    </Button>
                );
            },
        },
    ];

    return (
        <div style={{ marginTop: "20px" }}>
            <FilterBar
                columnVisibilityModel={columnVisibilityModel}
                onPageChange={getActivities}
                openLoader={openLoader}
                rowCount={rowCount}
                columns={activitiesColumns}
                rows={activities}
                pageSize={pageSize}
            />

            <GenericDialog title={`Titulo actividad`} open={openDialog} setOpen={setOpenDialog}>
                <DialogContent>
                    <p>Titulo enunciado</p>
                    <p>Enunciado</p>
                    <p>Objetivo</p>
                    <p>Descripción actividad</p>
                    <Button variant="contained" onClick={() => navigate("/grapher/4")}>
                        Resolver
                    </Button>
                </DialogContent>
            </GenericDialog>
        </div>
    );
};

export default CourseActivitiesList;
