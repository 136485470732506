import React, { useEffect, useState } from "react";

import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    MenuItem,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import DateFieldComponent from "common/components/DateFieldComponent/DateFieldComponent";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";
import { ApiService } from "common/services/ApiService";
import dayjs from "dayjs";

const SignUpForm = ({
    handleSignUp,
    signUpFormData,
    setSignUpFormData,
    handleChangeForms,
    handleClose,
    setShowError,
    showError,
    loggedByGoogle = false,
}) => {
    // const [provinces, setProvinces] = useState([]);
    // const [locations, setLocations] = useState([]);
    const [establishments, setEstablishments] = useState([]);

    // useEffect(() => {
    //     async function getProvinces() {
    //         const res = await ApiService.getProvinces();
    //         setProvinces(res.data);
    //     }

    //     getProvinces();
    // }, []);

    // const getLocations = async (provinceId) => {
    //     const res = await ApiService.getLocations(provinceId);
    //     setLocations(res.data);
    // };

    const getEstablishments = async (locationId) => {
        const res = await ApiService.getEstablishments(
            null,
            null,
            { location: locationId },
            false
        );
        setEstablishments(res.data.results);
    };

    const handleChangeProvince = (evt) => {
        setSignUpFormData({
            ...signUpFormData,
            province: evt.target.value,
        });
        // getLocations(evt.target.value);
    };

    const handleChangeLocation = (evt) => {
        setSignUpFormData({
            ...signUpFormData,
            city: evt.target.value,
        });
        getEstablishments(evt.target.value);
    };

    return (
        <>
            <form onSubmit={(evt) => handleSignUp(evt)}>
                <DialogTitle sx={{ width: { md: "500px" } }}>
                    {!loggedByGoogle ? "Registrarse" : "Completar registro"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Registrate para poder acceder a todas las fuentes de datos y para comenzar a
                        resolver tus actividades
                    </DialogContentText>
                    {!loggedByGoogle && (
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Nombre"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(evt) => {
                                    setSignUpFormData({
                                        ...signUpFormData,
                                        firstName: evt.target.value,
                                    });
                                }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="lastName"
                                label="Apellido"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(evt) => {
                                    setSignUpFormData({
                                        ...signUpFormData,
                                        lastName: evt.target.value,
                                    });
                                }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                                variant="standard"
                                onChange={(evt) => {
                                    setSignUpFormData({
                                        ...signUpFormData,
                                        email: evt.target.value,
                                    });
                                }}
                            />
                        </>
                    )}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="dni"
                        label="DNI"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(evt) => {
                            setSignUpFormData({
                                ...signUpFormData,
                                dni: evt.target.value,
                            });
                        }}
                    />

                    <DateFieldComponent
                        label="Fecha de nacimiento"
                        handleChange={(evt) => {
                            setSignUpFormData({
                                ...signUpFormData,
                                birthday: dayjs(evt).format("YYYY-MM-DD"),
                            });
                        }}
                    ></DateFieldComponent>

                    <div style={{ marginTop: "20px" }}>
                        <SelectProvinceAndLocation
                            // value={signUpForm.province}
                            handleChangeProvince={handleChangeProvince}
                            handleChangeLocation={handleChangeLocation}
                            lg={6}
                            xs={6}
                            sm={6}
                        ></SelectProvinceAndLocation>
                    </div>

                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Seleccione un establecimiento"
                        margin="dense"
                        // helperText="Por favor seleccione una localidad"
                        defaultValue={""}
                        fullWidth
                        sx={{ marginTop: "20px" }}
                        onChange={(evt) => {
                            setSignUpFormData({
                                ...signUpFormData,
                                establishments: [evt.target.value],
                            });
                        }}
                    >
                        {establishments.length > 0 ? (
                            establishments.map((establishment) => (
                                <MenuItem key={establishment.id} value={establishment.id}>
                                    {establishment.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled value={0}>
                                Seleccione un establecimiento
                            </MenuItem>
                        )}
                    </TextField>
                    {!loggedByGoogle && (
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="password"
                                label="Contraseña"
                                type="password"
                                fullWidth
                                variant="standard"
                                onChange={(evt) => {
                                    setSignUpFormData({
                                        ...signUpFormData,
                                        password: evt.target.value,
                                    });
                                }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="password2"
                                label="Repetir contraseña"
                                type="password"
                                fullWidth
                                variant="standard"
                                onChange={(evt) => {
                                    setSignUpFormData({
                                        ...signUpFormData,
                                        repeatedPassword: evt.target.value,
                                    });
                                }}
                            />
                        </>
                    )}
                    <p>
                        <small>No sos estudiante?</small>
                    </p>
                    <FormControlLabel
                        checked={signUpFormData.isTeacher}
                        onChange={(evt) =>
                            setSignUpFormData({
                                ...signUpFormData,
                                isTeacher: evt.target.checked,
                            })
                        }
                        control={<Switch color="primary" />}
                        label={
                            <p style={{ margin: "0px" }}>
                                Registrarse como <strong>Profesor</strong>
                            </p>
                        }
                        labelPlacement="start"
                        sx={{ margin: "5px 0px" }}
                    />
                    <FormControlLabel
                        checked={signUpFormData.isAdmin}
                        onChange={(evt) =>
                            setSignUpFormData({
                                ...signUpFormData,
                                isAdmin: evt.target.checked,
                            })
                        }
                        control={<Switch color="primary" />}
                        label={
                            <p style={{ margin: "0px" }}>
                                Registrarse como <strong>Administrador</strong>
                            </p>
                        }
                        labelPlacement="start"
                        sx={{ margin: "0px" }}
                    />
                    {!loggedByGoogle && (
                        <div style={{ marginTop: "10px" }}>
                            <Typography variant="p">
                                <small>
                                    Ya tienes cuenta?{" "}
                                    <Typography
                                        variant="p"
                                        sx={{
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                        }}
                                        color={"primary.dark"}
                                        onClick={() => {
                                            handleChangeForms();
                                        }}
                                    >
                                        Inicia sesión
                                    </Typography>
                                </small>
                            </Typography>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ backgroundColor: "primary.light" }}
                    >
                        Cancelar
                    </Button>
                    <Button type="submit" variant="contained">
                        Registrarse
                    </Button>
                </DialogActions>
            </form>
            {showError === true ? (
                <Grid
                    item
                    xs={12}
                    md={7}
                    sx={{
                        position: "fixed",
                        top: "45%",
                        left: "33%",
                        zIndex: 9999,
                    }}
                >
                    <Alert severity="error" variant="filled">
                        Ocurrió un error, intente nuevamente!
                    </Alert>
                </Grid>
            ) : null}
        </>
    );
};

export default SignUpForm;
