import React, { useState, useEffect } from "react";
import { Autocomplete, MenuItem, TextField } from "@mui/material";
import { ApiService } from "common/services/ApiService";
import { useAuth } from "context/AuthContext";

const DialogContentAssign = ({
    selectedCourse,
    setSelectedCourse,
    assignCourseFormData,
    setAssignCourseFormData,
}) => {
    const { auth } = useAuth();
    const [courses, setCourses] = useState([]);
    const [subject, setSubject] = useState("");
    const [selectedActivityFor, setSelectedActivityFor] = useState("");
    const [selectedEstudents, setSelectedStudents] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (selectedCourse && selectedCourse !== "") {
            setSelectedStudents([]);
            const getUserCourseRequests = async () => {
                const res = await ApiService.getUserCourseRequests(null, null, {
                    no_page: 1,
                    course: selectedCourse?.id,
                });
                const userRequests = res.data;
                const uniqueObjects = Object.values(
                    userRequests.reduce((acc, ur) => {
                        if (!acc[ur.user.id]) {
                            acc[ur.user.id] = ur;
                        }
                        return acc;
                    }, {})
                )
                    .filter((ur) =>
                        ur.user.role_establishment_list.some(
                            (re) =>
                                re.establishment.id === selectedCourse.establishment.id &&
                                re.roles.includes("student")
                        )
                    )
                    .map((ur) => ({
                        id: ur.user.id,
                        firstName: ur.user.first_name,
                        lastName: ur.user.last_name,
                    }));
                setUsers(uniqueObjects);
            };
            getUserCourseRequests();
        }
    }, [selectedCourse]);

    useEffect(() => {
        const getUserCourseRequests = async () => {
            const dataUser = await ApiService.me(auth.access);
            const res = await ApiService.getUserCourseRequests(null, null, {
                no_page: 1,
                user: dataUser.data.id,
            });
            setCourses(res.data.map((ur) => ur.course));
        };

        getUserCourseRequests();
    }, []);

    return (
        <>
            <TextField
                fullWidth
                id="subject"
                select
                label="Curso"
                value={selectedCourse?.id}
                onChange={(evt) => {
                    setSelectedCourse(evt.target.value);
                    const course = courses.find((course) => course.id === evt.target.value.id);
                    if (course) {
                        setSubject(course.subject?.name || "");
                    }
                    setAssignCourseFormData({
                        ...assignCourseFormData,
                        course: course.id,
                    });
                }}
                margin="normal"
            >
                {courses.map((course) => (
                    <MenuItem key={course.id} value={course}>
                        {course.name}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                fullWidth
                id="subject"
                label="Materia"
                margin="normal"
                value={subject}
                disabled
            />
            <TextField
                fullWidth
                id="activityFor"
                select
                label="Crear actividad para"
                value={selectedActivityFor}
                onChange={(evt) => {
                    let students = [];
                    setSelectedActivityFor(evt.target.value);
                    if (evt.target.value === 1) {
                        students = users.map((u) => u.id);
                    }
                    setAssignCourseFormData({
                        ...assignCourseFormData,
                        students,
                    });
                }}
                margin="normal"
            >
                <MenuItem value={1}>Todos los estudiantes</MenuItem>
                <MenuItem value={2}>Grupo reducido</MenuItem>
            </TextField>
            {selectedActivityFor === 2 && (
                <Autocomplete
                    sx={{ marginTop: "16px" }}
                    id="activityFor"
                    // freeSolo
                    multiple
                    options={users}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {`${option.firstName} ${option.lastName}`}
                            </li>
                        );
                    }}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    renderInput={(params) => <TextField {...params} label="Elegir estudiantes" />}
                    value={selectedEstudents}
                    onChange={(evt, newValue) => {
                        if (newValue !== null) {
                            setSelectedStudents(newValue);
                        }
                        setAssignCourseFormData({
                            ...assignCourseFormData,
                            students: newValue.map((u) => u.id) || [],
                        });
                    }}
                />
            )}
        </>
    );
};

export default DialogContentAssign;
