export const datasetSubPages = [
    { name: "Datasets públicos", url: "/datasets/public" },
    { name: "Datasets protegidos", url: "/datasets/protected" },
];

export const grafosSocialesSubPages = [
    { name: "Reddit (ocurrencias)", url: "/graphs/reddit" },
];

export const superAdminSettings = [
    { name: "Perfil", url: "/profile" },
    { name: "Super Administrador", url: "/superadmin", badge: true },
    { name: "Cerrar sesión", url: "/logout" },
];

export const adminSettings = [
    { name: "Perfil", url: "/profile" },
    { name: "Administrador", url: "/admin", badge: true },
    { name: "Cerrar sesión", url: "/logout" },
];

export const teacherSettings = [
    { name: "Perfil", url: "/profile" },
    { name: "Profesor", url: "/teacher", badge: true },
    { name: "Cerrar sesión", url: "/logout" },
];

export const userSettings = [
    { name: "Perfil", url: "/profile" },
    { name: "Cerrar sesión", url: "/logout" },
];

export const leftSideMenu = [
    {
        title: "Datasets",
        menuItems: datasetSubPages,
        name: "datasets",
    },
    {
        title: "Grafos sociales",
        menuItems: grafosSocialesSubPages,
        name: "grafos",
    },
];

export const invisibleDotNotificationPath = [
    "/admin",
    "/superadmin",
    "teacher",
];
