export const NETWORKGRAPH = "networkgraph";
export const MOREGRAPH = "more";

export const VISIBILITY = {
    public: "PUB",
    private: "PRIV",
    community: "COMM",
};

export const QUALIFICATION = {
    disapproved: "DISA",
    approved: "APPR",
    reDelivery: "REDE",
};

export const MAP_COLUMNS = {
    value: "Categorizar por",
    group_by: "Agrupar por",
    datasetColumns: "Columnas Dataset",
};

export const MAP_GRAPHS = {
    dataTable: "Tabla de datos",
    column: "Columnas",
    line: "Lineas",
    pie: "Torta",
    bubble: "Burbujas",
    dispersion: "Dispersión",
    map: "Mapa",
    social_user_comments: "Grafo social (Interaccion Usuarios)",
    social_word_frequency: "Grafo social (Ocurrecias)",
    static_network: "Grafo",
    word_cloud: "Nube De Palabras",
};

export const ROLE_MAP = {
    admin: "Administrador",
    teacher: "Profesor",
    student: "Estudiante",
    superadmin: "Superadmin",
};