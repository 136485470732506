import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Badge,
    Box,
    Button,
    Grid,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import useStyles, { stylesSx } from "common/components/NavBar/styles.js";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import DropDownMenu from "./DropDownMenu";

import {
    leftSideMenu,
    adminSettings,
    teacherSettings,
    superAdminSettings,
    userSettings,
    invisibleDotNotificationPath,
} from "../utils/constants";
import { useAuth } from "context/AuthContext";
import { ROLE_MAP } from "utils/constants";

const LoggedUserMenu = ({
    handleOpenMenu,
    handleCloseMenu,
    anchorElements,
    setOpen,
    userName,
    settings,
    setSettings,
    checkPendingNotification,
    pendingNotificationIntervalId,
    setPendingNotificationIntervalId,
    pendingNotification,
}) => {
    const { auth, setAuth } = useAuth();
    // console.log(auth);
    const [anchorElUser, setAnchorElUser] = useState();
    const [menuEstablishmentsTitle, setMenuEstablishmentsTitle] =
        useState("Establecimiento");
    const [menuEstablishments, setMenuEstablishments] = useState([]);

    const [menuRolesTitle, setMenuRolesTitle] = useState("Rol");
    const [menuRoles, setMenuRoles] = useState([]);

    const allowSuperAdmin = auth?.rolesEstablishment?.some((re) =>
        re.roles.includes("superadmin")
    );
    const selectedRole = JSON.parse(localStorage.getItem("selectedRole"));
    const enable =
        selectedRole !== null ? selectedRole.name === "superadmin" : false;

    const [enableSuperAdmin, setEnableSuperAdmin] = useState(enable);

    useEffect(() => {
        let rolesEstablishment = localStorage.getItem("rolesEstablishment");
        rolesEstablishment = JSON.parse(rolesEstablishment);
        const menuData = rolesEstablishment.map((item) => {
            return {
                id: item.establishment.id,
                name: item.establishment.name,
                roles: item.roles.map((role) => {
                    return { name: role };
                }),
            };
        });
        setMenuEstablishments(menuData);
    }, []);

    useEffect(() => {
        if (localStorage.getItem("selectedEstablishment") !== null) {
            const menuEstablishmentsData = JSON.parse(
                localStorage.getItem("selectedEstablishment")
            );
            if (menuEstablishmentsData.name.length > 10) {
                setMenuEstablishmentsTitle(
                    `${menuEstablishmentsData.name.slice(0, 10)}...`
                );
            } else {
                setMenuEstablishmentsTitle(menuEstablishmentsData.name);
            }
            const rolesWithoutSuperAdmin = menuEstablishmentsData.roles.filter(
                (r) => r.name !== "superadmin"
            );

            setMenuRoles(rolesWithoutSuperAdmin);
        }
        // console.log(localStorage.getItem("selectedRole"));
        if (JSON.parse(localStorage.getItem("selectedRole")) !== null) {
            const menuRolesData = JSON.parse(
                localStorage.getItem("selectedRole")
            );
            if (menuRolesData.name !== "superadmin")
                setMenuRolesTitle(ROLE_MAP[menuRolesData.name]);
        }
        if (auth.selectedRole) {
            if (auth.selectedRole.name === "admin") {
                setSettings(adminSettings);
            } else if (auth.selectedRole.name === "teacher") {
                setSettings(teacherSettings);
            } else if (auth.selectedRole.name === "student") {
                setSettings(userSettings);
            }
        }
    }, [auth]);

    const { boxDisplayStyle, boxFlexStyle, menuButtons, menuText } = stylesSx;
    const navigate = useNavigate();

    const classes = useStyles();

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClickSettings = (url) => {
        if (url === "/logout") {
            setOpen(true);
        } else {
            navigate(url);
        }
        handleCloseUserMenu();
    };

    const handleClickLeftSubMenuItem = (evt, menuName) => {
        navigate(evt.url);
        handleCloseMenu(menuName.name);
    };

    const handleClickRightSubMenuEstablishmentItem = (evt) => {
        // ACA HABRIA QUE HACER ALGO DE LOGICA PARA GUARDAR EL VALOR DEL ESTABLISHMENT ELEGIDO
        // console.log(evt);
        localStorage.setItem("selectedEstablishment", JSON.stringify(evt));
        localStorage.setItem("selectedRole", null);
        setAuth({ ...auth, selectedEstablishment: evt, selectedRole: null });
        setMenuEstablishmentsTitle(evt.name);
        setMenuRolesTitle("Rol");
        setMenuRoles(evt.roles);
        handleCloseMenu("establishments");
        clearInterval(pendingNotificationIntervalId);
    };

    const handleClickRightSubMenuRoleItem = (evt) => {
        // ACA HABRIA QUE HACER ALGO DE LOGICA PARA GUARDAR EL VALOR DEL ROL ELEGIDO
        const selectedRoleName = evt.name;
        const dataUpdateAuth = { ...auth, selectedRole: evt };
        setAuth(dataUpdateAuth);
        localStorage.setItem("selectedRole", JSON.stringify(evt));

        clearInterval(pendingNotificationIntervalId);
        checkPendingNotification(selectedRoleName, dataUpdateAuth);
        const pendingNotificationInterval = setInterval(() => {
            checkPendingNotification(selectedRoleName, dataUpdateAuth);
        }, 60000); // 1 minute
        setPendingNotificationIntervalId(pendingNotificationInterval);

        handleCloseMenu("roles");
        if (selectedRoleName === "admin") {
            setSettings(adminSettings);
            navigate("/admin");
        } else if (selectedRoleName === "teacher") {
            setSettings(teacherSettings);
            navigate("/teacher");
        } else if (selectedRoleName === "superadmin") {
            setMenuRolesTitle("Rol");
            setSettings(superAdminSettings);
            navigate("/superadmin");
            return;
        }
        setMenuRolesTitle(ROLE_MAP[selectedRoleName]);
    };

    const getAnchorElementValue = (menuName) => {
        const element = anchorElements.find(
            (element) => element.name === menuName
        );

        if (element) {
            return element.value;
        }
    };

    useEffect(() => {
        if (enableSuperAdmin) {
            const superAdmin = {
                name: "superadmin",
            };
            handleClickRightSubMenuRoleItem(superAdmin);
        }
    }, [enableSuperAdmin]);

    const handleClickSuperAdminButton = () => {
        if (enableSuperAdmin) {
            localStorage.setItem("selectedRole", null);
            setAuth({ ...auth, selectedRole: null });
            handleCloseMenu("roles");
            setMenuRolesTitle("Rol");
            setSettings(userSettings);
            clearInterval(pendingNotificationIntervalId);
        }
        setEnableSuperAdmin(!enableSuperAdmin);
    };

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    marginLeft: "2%",
                }}
            >
                <Typography
                    variant="p"
                    noWrap
                    component="div"
                    sx={{
                        mr: 2,
                        display: { xs: "flex", md: "none" },
                        flexGrow: 1,
                        fontFamily: "monospace",
                        fontWeight: 700,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none",
                    }}
                >
                    <Link to="/" className={classes.linkNavBar}>
                        <img
                            src="/img/logoAd.png"
                            alt="logo"
                            className={classes.logo}
                        />
                    </Link>
                </Typography>
                {/* BOTONES DE GRAFOS SOCIALES Y DATSETS */}
                {/* {leftSideMenu.map((element) => (
                    <DropDownMenu
                        key={element.name}
                        buttonTitle={element.title}
                        menuItems={element.menuItems}
                        handleOpenMenu={(evt) => handleOpenMenu(evt, element.name)}
                        handleCloseMenu={() => handleCloseMenu(element.name)}
                        handleClickItem={(evt) => handleClickLeftSubMenuItem(evt, element.name)}
                        anchorElement={getAnchorElementValue(element.name)}
                    ></DropDownMenu>
                ))} */}
                {auth.selectedRole?.name === "student" && (
                    <Button
                        onClick={() => navigate("/courses")}
                        sx={menuButtons}
                    >
                        Cursos
                    </Button>
                )}
            </Box>
            <Box sx={boxFlexStyle} />
            <Box sx={boxDisplayStyle}>
                <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
                    {allowSuperAdmin && (
                        <Button
                            onClick={() => {
                                handleClickSuperAdminButton();
                            }}
                            sx={{
                                ...menuButtons,
                                color: !enableSuperAdmin
                                    ? "grey"
                                    : menuButtons.color,
                            }}
                        >
                            SUPER ADMIN
                        </Button>
                    )}
                    <Tooltip
                        title={
                            auth.selectedEstablishment &&
                            auth.selectedEstablishment?.name
                        }
                    >
                        <div>
                            <DropDownMenu
                                buttonTitle={menuEstablishmentsTitle}
                                disabled={enableSuperAdmin}
                                menuItems={menuEstablishments}
                                handleOpenMenu={(evt) =>
                                    handleOpenMenu(evt, "establishments")
                                }
                                handleCloseMenu={() =>
                                    handleCloseMenu("establishments")
                                }
                                handleClickItem={
                                    (evt) =>
                                        handleClickRightSubMenuEstablishmentItem(
                                            evt
                                        )
                                    // console.log(evt)
                                }
                                anchorElement={getAnchorElementValue(
                                    "establishments"
                                )}
                            />
                        </div>
                    </Tooltip>
                    <DropDownMenu
                        buttonTitle={menuRolesTitle}
                        isRole
                        rolesTranscription={ROLE_MAP}
                        menuItems={menuRoles}
                        disabled={enableSuperAdmin}
                        handleOpenMenu={(evt) => handleOpenMenu(evt, "roles")}
                        handleCloseMenu={() => handleCloseMenu("roles")}
                        handleClickItem={(evt) =>
                            handleClickRightSubMenuRoleItem(evt)
                        }
                        anchorElement={getAnchorElementValue("roles")}
                    />
                </Grid>
                <Typography variant="p" sx={menuText}>
                    {/* {userName} */}
                </Typography>
                <Box
                    sx={{
                        display: { xs: "flex", md: "none" },
                        justifyContent: "center",
                    }}
                >
                    {allowSuperAdmin && (
                        <Button
                            onClick={() => {
                                handleClickSuperAdminButton();
                            }}
                            sx={{
                                ...menuButtons,
                                color: !enableSuperAdmin
                                    ? "grey"
                                    : menuButtons.color,
                            }}
                        >
                            SUPER ADMIN
                        </Button>
                    )}
                    <IconButton
                        size="large"
                        aria-label="User auth"
                        color="inherit"
                        onClick={handleOpenUserMenu}
                    >
                        <Badge
                            variant="dot"
                            color="secondary"
                            invisible={
                                selectedRole === null ||
                                (selectedRole &&
                                    !pendingNotification[selectedRole.name]) ||
                                (selectedRole &&
                                    pendingNotification[selectedRole.name] &&
                                    invisibleDotNotificationPath.includes(
                                        window.location.pathname
                                    ))
                            }
                        >
                            <PersonIcon />
                        </Badge>
                    </IconButton>
                </Box>
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                    <IconButton
                        size="large"
                        aria-label="User auth"
                        color="inherit"
                        onClick={handleOpenUserMenu}
                    >
                        <Badge
                            variant="dot"
                            color="secondary"
                            invisible={
                                selectedRole === null ||
                                (selectedRole &&
                                    !pendingNotification[selectedRole.name]) ||
                                (selectedRole &&
                                    pendingNotification[selectedRole.name] &&
                                    invisibleDotNotificationPath.includes(
                                        window.location.pathname
                                    ))
                            }
                        >
                            <PersonIcon />
                        </Badge>
                    </IconButton>
                </Box>
                <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {settings.map((setting) => (
                        <MenuItem
                            key={setting.name}
                            onClick={() => {
                                handleClickSettings(setting.url);
                            }}
                        >
                            {setting.badge ? (
                                <Badge
                                    variant="dot"
                                    color="secondary"
                                    invisible={
                                        selectedRole === null ||
                                        (selectedRole &&
                                            !pendingNotification[
                                                selectedRole.name
                                            ]) ||
                                        (selectedRole &&
                                            pendingNotification[
                                                selectedRole.name
                                            ] &&
                                            invisibleDotNotificationPath.includes(
                                                window.location.pathname
                                            ))
                                    }
                                >
                                    <Typography textAlign="center">
                                        {setting.name}
                                    </Typography>
                                </Badge>
                            ) : (
                                <Typography textAlign="center">
                                    {setting.name}
                                </Typography>
                            )}
                        </MenuItem>
                    ))}
                    <Box sx={{ display: { xs: "block", md: "none" } }}>
                        <MenuItem
                            className="extraItems"
                            sx={{ height: "36px" }}
                        >
                            <DropDownMenu
                                disabled={enableSuperAdmin}
                                isMobile={true}
                                buttonTitle={menuEstablishmentsTitle}
                                menuItems={menuEstablishments}
                                handleOpenMenu={(evt) =>
                                    handleOpenMenu(evt, "establishments")
                                }
                                handleCloseMenu={() =>
                                    handleCloseMenu("establishments")
                                }
                                handleClickItem={(evt) =>
                                    handleClickRightSubMenuEstablishmentItem(
                                        evt
                                    )
                                }
                                anchorElement={getAnchorElementValue(
                                    "establishments"
                                )}
                            ></DropDownMenu>
                        </MenuItem>
                        <MenuItem
                            className="extraItems"
                            sx={{ height: "36px" }}
                        >
                            <DropDownMenu
                                disabled={enableSuperAdmin}
                                isMobile={true}
                                isRole={true}
                                rolesTranscription={ROLE_MAP}
                                buttonTitle={menuRolesTitle}
                                menuItems={menuRoles}
                                handleOpenMenu={(evt) =>
                                    handleOpenMenu(evt, "roles")
                                }
                                handleCloseMenu={() => handleCloseMenu("roles")}
                                handleClickItem={(evt) =>
                                    handleClickRightSubMenuRoleItem(evt)
                                }
                                anchorElement={getAnchorElementValue("roles")}
                            ></DropDownMenu>
                        </MenuItem>
                    </Box>
                </Menu>
            </Box>
        </>
    );
};

LoggedUserMenu.propTypes = {
    // anchorElDatasets: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    // anchorElGrafos: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    // handleCloseDatasets: PropTypes.func.isRequired,
    // handleCloseGrafos: PropTypes.func.isRequired,
    // handleOpenDatasetsMenu: PropTypes.func.isRequired,
    // handleOpenGrafosMenu: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    // settings: PropTypes.object.isRequired,
};

export default LoggedUserMenu;
