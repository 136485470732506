import {
    Autocomplete,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useAuth } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import { ROLE_MAP } from "utils/constants";

const EstablishmentSelectionModal = ({
    setOpen,
    checkPendingNotification,
    pendingNotificationIntervalId,
    setPendingNotificationIntervalId,
}) => {
    const { auth, setAuth } = useAuth();

    const [establishmentSelected, setEstablishmentSelected] = useState(null);
    const [roleSelected, setRoleSelected] = useState(null);
    const [establishments, setEstablishments] = useState([]);
    const [roles, setRoles] = useState([]);
    const rolesEstablishment = JSON.parse(
        localStorage.getItem("rolesEstablishment")
    );

    useEffect(() => {
        if (rolesEstablishment) {
            setEstablishments(
                rolesEstablishment.map((item) => {
                    return {
                        id: item.establishment.id,
                        name: item.establishment.name,
                        roles: item.roles.map((role) => {
                            return { name: role };
                        }),
                    };
                })
            );
        }
    }, [rolesEstablishment]);

    useEffect(() => {
        establishmentSelected && setRoles(establishmentSelected.roles);
    }, [establishmentSelected]);

    const onClickRole = (selectedRole) => {
        const dataUpdateAuth = { ...auth, selectedRole: selectedRole };
        clearInterval(pendingNotificationIntervalId);
        checkPendingNotification(selectedRole.name, dataUpdateAuth);
        const pendingNotificationInterval = setInterval(() => {
            checkPendingNotification(selectedRole.name, dataUpdateAuth);
        }, 60000); // 1 minute
        setPendingNotificationIntervalId(pendingNotificationInterval);
    };

    return (
        <>
            <DialogTitle sx={{ width: { md: "500px" } }}>
                Seleccione Establecimiento y Rol
            </DialogTitle>
            <DialogContent>
                <Autocomplete
                    sx={{ marginTop: "16px" }}
                    id="autocomplete"
                    freeSolo
                    // options={products.map((option) => option.name)}
                    options={establishments}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    getOptionLabel={(option) => {
                        return option.name;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Seleccione una institución"
                        />
                    )}
                    // value={selectedResponsible}
                    onChange={(evt, newValue) => {
                        if (newValue !== null) {
                            setEstablishmentSelected(newValue);
                            localStorage.setItem(
                                "selectedEstablishment",
                                JSON.stringify(newValue)
                            );
                            localStorage.setItem("selectedRole", null);
                            setAuth({
                                ...auth,
                                selectedEstablishment: newValue,
                                selectedRole: null,
                            });
                            // handleSetResponsibleToEstablishment(newValue);
                        }
                    }}
                />

                <Autocomplete
                    sx={{ marginTop: "16px" }}
                    id="autocomplete"
                    freeSolo
                    // options={products.map((option) => option.name)}
                    options={roles}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.name}>
                                {/* {option.name} */}
                                {ROLE_MAP[option.name]}
                            </li>
                        );
                    }}
                    getOptionLabel={(option) => {
                        return option.name;
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Seleccione un rol" />
                    )}
                    // value={selectedResponsible}
                    onChange={(evt, newValue) => {
                        if (newValue !== null) {
                            localStorage.setItem(
                                "selectedRole",
                                JSON.stringify(newValue)
                            );
                            setRoleSelected(newValue);
                            setAuth({ ...auth, selectedRole: newValue });
                            onClickRole(newValue);
                            // handleSetResponsibleToEstablishment(newValue);
                        }
                    }}
                />
                <Box mt={1}>
                    <small
                        style={{
                            display: "block",
                            fontSize: "12px",
                            textAlign: "center",
                        }}
                    >
                        <i>
                            Si aún no tienes un rol y una institución deberás
                            esperar que un administrador acepte tu solicitud!
                        </i>
                    </small>
                </Box>
            </DialogContent>
            <DialogActions>
                {/* <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ backgroundColor: "primary.light" }}
                    >
                        Cancelar
                    </Button> */}
                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Continuar
                </Button>
            </DialogActions>
        </>
    );
};

export default EstablishmentSelectionModal;
