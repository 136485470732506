import { Box, Typography } from "@mui/material";
import React from "react";

const TabPanelComponent = ({ children, value, index }) => {
    return (
        <div>
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default TabPanelComponent