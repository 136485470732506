const stylesSx = {
    baseStyle: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
        fontWeight: "bold",
    },
    focusedStyle: {
        borderColor: "#2196f3",
    },
    acceptStyle: {
        borderColor: "#00e676",
    },
    rejectStyle: {
        borderColor: "#ff1744",
    },
    backDrop: {
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
    },
};

export default stylesSx;
