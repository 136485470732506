import jwtDecode from "jwt-decode";
export function verifyToken() {
    if (!localStorage.getItem("access")) {
        return false;
    } else {
        const accessToken = localStorage.getItem("access");
        if (accessToken) {
            try {
                const decodedToken = jwtDecode(accessToken, {
                    complete: true,
                });
                const expirationTime = decodedToken.exp * 1000;
                // console.log("exp time ", expirationTime);
                // console.log("date now", Date.now());

                if (Date.now() >= expirationTime) {
                    return false;
                }
            } catch (error) {
                return false;
            }
        }

        return true;
    }
}
