import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./style.scss";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function GenericDialog({
    open,
    setOpen,
    title = "",
    handleSave,
    children,
    saveButton = true,
    cancelButton = true,
    saveButtonText = "Guardar",
    cancelButtonText = "Cancelar",
    onClose = () => {},
    showCloseButton = true,
    dialogWidth = false,
}) {
    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const paperProp =
        dialogWidth === true
            ? {
                  style: {
                      width: "1000px",
                      maxWidth: "3000px",
                  },
              }
            : {};

    return (
        <div>
            <Dialog open={open} onClose={handleClose} PaperProps={paperProp}>
                {showCloseButton && (
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                <DialogTitle>{title}</DialogTitle>
                {children}
                {(cancelButton || saveButton) && (
                    <DialogActions>
                        {cancelButton && (
                            <Button onClick={() => handleClose()}>{cancelButtonText}</Button>
                        )}
                        {saveButton && (
                            <Button onClick={() => handleSave()}>{saveButtonText}</Button>
                        )}
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
}
