import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import useStyles from "./styles";

const InputsFromFile = (props) => {
    const classes = useStyles();
    const { file, setInputsFromUser, inputsFromUser, hasHeaders } = props;

    useEffect(() => {
        let dataAux = {}
        file.forEach((input, i) => {
            dataAux = {
                ...dataAux,
                [i + 1]: hasHeaders ? input : `Columna${i + 1}`
            }
        })
        setInputsFromUser(dataAux)
    }, [hasHeaders])

    return (
        <div>
            <form
                className={classes.form}
                onSubmit={(e) => {
                    e.preventDefault();
                    this.signUp();
                }}
            >
                {file.map((input, i) => (
                    <div key={i} className={classes.containerTextField}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="outlined-basic"
                            label={`Columna ${i + 1}`}
                            onChange={(e) => {
                                setInputsFromUser({
                                    ...inputsFromUser,
                                    [i + 1]: e.target.value
                                })
                            }}
                            value={inputsFromUser[i + 1] || `Columna ${i + 1}`}
                        />
                    </div>
                ))}
            </form>
        </div>
    );
};

export default InputsFromFile;
