import Chart from "common/components/Chart/Chart";
import { useColumnsChart } from "hooks/chart/UseColumnsChart";
import React, { useEffect } from "react";
import { MOREGRAPH } from "utils/constants";

const ColumnsChart = ({ data, xAxis, yAxis }) => {
    const { options: optionsColumnsChart, setOptions: setOptionsColumnsChart } = useColumnsChart();

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const dataFiltered = [];

            data.value_categories.forEach((categorie) => {
                dataFiltered.push({
                    name: categorie,
                    data: data.data
                        .filter((obj) => obj[`group_by_${xAxis}_${yAxis}`][xAxis] === categorie)
                        .map((obj) => obj.count),
                });
            });

            setOptionsColumnsChart({
                ...optionsColumnsChart,
                xAxis: {
                    ...optionsColumnsChart.xAxis,
                    categories: data.group_by_categories,
                },
                series: dataFiltered,
            });
        }
    }, [data]);

    return <Chart options={optionsColumnsChart} type={MOREGRAPH} />;
};

export default ColumnsChart;
