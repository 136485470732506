import React from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { stylesSx } from "common/components/NavBar/styles.js";

const DropDownMenu = ({
    disabled,
    buttonTitle,
    menuItems,
    handleOpenMenu,
    handleCloseMenu,
    handleClickItem,
    anchorElement,
    isMobile = false,
    isRole = false,
    rolesTranscription = [],
}) => {
    const { menuButtons, menuButtonsMobile } = stylesSx;

    return (
        <>
            <Button
                disabled={disabled}
                onClick={handleOpenMenu}
                sx={!isMobile ? menuButtons : menuButtonsMobile}
            >
                {buttonTitle} <KeyboardArrowDownIcon />
            </Button>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElement)}
                onClose={handleCloseMenu}
            >
                {menuItems.length > 0 ? (
                    menuItems.map((item) => (
                        <MenuItem
                            key={item.name}
                            onClick={() => {
                                handleClickItem(item);
                            }}
                        >
                            <Typography textAlign="center">
                                {!isRole
                                    ? item.name
                                    : rolesTranscription[item.name]}
                            </Typography>
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>
                        <Typography textAlign="center">
                            No hay opciones
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default DropDownMenu;
