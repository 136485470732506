import { createTheme } from "@mui/material";
import palette from "./palette";

const theme = createTheme({
    palette,
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    "& .MuiDataGrid-cell": {
                        "&:focus-within": {
                            outline: "unset",
                        },
                    },
                    "& .MuiDataGrid-columnHeader": {
                        "&:focus": {
                            outline: "unset",
                        },
                    },
                },
            },
        },
    },
});

export default theme;
