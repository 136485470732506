import {
    Alert,
    Autocomplete,
    Button,
    DialogContent,
    DialogContentText,
    TextField,
} from "@mui/material";
import GenericTable from "common/components/GenericTable/GenericTable";
import { ApiService } from "common/services/ApiService";
import React, { useEffect, useState } from "react";
import { ROLE_MAP } from "utils/constants";

const DialogUserRoleContent = ({ selectedUser, handleChangeExternalData }) => {
    const [userRoleEstablishmentList, setUserRoleEstablishmentList] = useState(
        []
    );
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");

    const getUserRoleEstablishments = async () => {
        const result = await ApiService.getUserRoleEstablishments({
            user: selectedUser.id,
            pending: 1,
        });
        setUserRoleEstablishmentList(
            result.data.map((ure) => ({
                id: ure.id,
                roleRequested: ure.role,
                establishment: ure.establishment,
            }))
        );
    };

    useEffect(() => {
        getUserRoleEstablishments();
    }, []);

    const acceptOrReject = async (ureId, data) => {
        try {
            const res = await ApiService.acceptOrRejectRole(ureId, data);
            getUserRoleEstablishments();
            if (res.status === 200) {
                if (data.accepted) {
                    setAlertText("El rol se aceptó con éxito!");
                } else {
                    setAlertText("El rol se rechazó con éxito!");
                }
                setAlertSeverity("success");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertText("");
                }, 2500);
            } else {
                setAlertText("Ocurrió un error, intente nuevamente!");
                setAlertSeverity("error");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertText("");
                }, 2500);
            }
        } catch (error) {
            console.log(error);
            setAlertText("Ocurrió un error, intente nuevamente!");
            setAlertSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setAlertText("");
            }, 2500);
        }
    };

    const columns = [
        {
            field: "roleRequested",
            headerName: "Rol Solicitado",
            flex: 0.7,
            valueGetter: (row) => `${ROLE_MAP[row.row.roleRequested]}`,
        },
        { field: "establishment", headerName: "Establecimiento", flex: 1 },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                acceptOrReject(params.row.id, {
                                    accepted: true,
                                });
                            }}
                        >
                            Aceptar
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ marginLeft: "10px" }}
                            onClick={() => {
                                acceptOrReject(params.row.id, {
                                    accepted: false,
                                });
                            }}
                        >
                            Rechazar
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    <strong>Nombre:</strong> {selectedUser.first_name}{" "}
                    {selectedUser.last_name}
                </DialogContentText>
                <DialogContentText>
                    <strong>Email:</strong> {selectedUser.email}
                </DialogContentText>
                <DialogContentText>
                    <strong>DNI:</strong> {selectedUser.dni}
                </DialogContentText>
                <GenericTable
                    rows={userRoleEstablishmentList}
                    columns={columns}
                    disableSelectionOnClick
                />
            </DialogContent>
            {showAlert && (
                <Alert
                    sx={{
                        zIndex: 99999,
                        position: "fixed",
                        top: "50%",
                        left: "40%",
                        color: "white",
                        fontWeight: "bold",
                    }}
                    variant="filled"
                    severity={alertSeverity}
                >
                    {alertText}
                </Alert>
            )}
        </>
    );
};

export default DialogUserRoleContent;
