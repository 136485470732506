import {
    Backdrop,
    Button,
    CircularProgress,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Tooltip,
} from "@mui/material";
import DndComponent from "common/components/DndComponent/DndComponent";
import GenericAlert from "common/components/GenericAlert/GenericAlert";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import TabPanelComponent from "common/components/TabPanel/TabPanelComponent";
import { ApiService } from "common/services/ApiService";
import { toSnakeCase } from "helpers/TextHelpers";
import BubbleChart from "modules/activity/pages/Activities/components/BubbleChart";
import ColumnsChart from "modules/activity/pages/Activities/components/ColumnsChart";
import FilterDataset from "modules/activity/pages/Activities/components/FiltersDataset";
import LineChart from "modules/activity/pages/Activities/components/LineChart";
import MapChartGrapher from "modules/activity/pages/Activities/components/MapChartGrapher";
import PieChart from "modules/activity/pages/Activities/components/PieChart";
import QuestionAnswerForum from "modules/activity/pages/Activities/components/QuestionAnswerForum";
import StaticNetworkBetweennessChart from "modules/activity/pages/Activities/components/StaticNetworkBetweennessChart";
import StaticNetworkChart from "modules/activity/pages/Activities/components/StaticNetworkChart";
import useStyles from "modules/activity/pages/Activities/components/styles";
import WordCloudChart from "modules/activity/pages/Activities/components/WordCloudChart";
import SocialGraphs from "modules/activity/pages/SocialGraphs/SocialGraphs";
import React, { useState } from "react";
import { exportGraphToImg, exportMultipleChartsToPdf } from "utils/exportPdf";
import HelpIcon from "@mui/icons-material/Help";

const TabNewResolution = ({
    datasetColumns,
    graphSelectedMap,
    setGraphSelectedMap,
    includeChipList,
    excludeChipList,
    setIncludeChipList,
    setExcludeChipList,
    studentActivityId,
    graphListAvailable,
    graphs,
    actualGraph,
    setActualGraph,
    handleChangeTabDataSource,
    studentActivity,
    getStudentActivity,
    callbackHandleChangeTabPanel,
    handleOpenTutorial,
}) => {
    const [openDialogFilter, setOpenDialogFilter] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [valueTab, setValueTab] = useState(0);
    const [valueGraphTab, setValueGraphTab] = useState(0);

    const [openLoader, setOpenLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [alertText, setAlertText] = useState("");

    const criterionMapList = {
        include: [includeChipList, setIncludeChipList],
        exclude: [excludeChipList, setExcludeChipList],
    };
    const dataSourceMap = {
        0: "static",
        1: "dynamic",
    };

    const classes = useStyles();

    const API_GRAPHER = {
        dataTable: ApiService.getDataGraphBasic,
        column: ApiService.getDataGraphColumn,
        line: ApiService.getDataGraphColumn,
        pie: ApiService.getDataGraphBasic,
        bubble: ApiService.getDataGraphColumn,
        dispersion: ApiService.getDataGraphColumn,
        map: ApiService.getDataGraphMap,
        social_word_frequency: ApiService.getRedditGraph,
        social_user_comments: ApiService.getRedditGraphUserComments,
        static_network: ApiService.getDataGraph,
        word_cloud: ApiService.getDataWordCloud,
    };

    const [dataGraph, setDataGraph] = useState({
        dataTable: {},
        column: {},
        line: {},
        pie: {},
        bubble: {},
        dispersion: {},
        map: {},
        social: {},
        social_word_frequency: {},
        social_user_comments: {},
        static_network: {},
        word_cloud: {},
    });

    const mapValueGraphTab = {
        0: "normal",
        1: "betweenness",
    };

    const handleChangeGraph = (event) => {
        setActualGraph(event.target.value);
    };

    const checkSelectedColumns = () => {
        const columns = graphSelectedMap[actualGraph].columns;
        return Object.entries(columns)
            .filter(([k, v]) => k !== "datasetColumns")
            .every(([k, v]) => v.length > 0);
    };

    const saveFilter = () => {
        setGraphSelectedMap({
            ...graphSelectedMap,
            [actualGraph]: {
                ...graphSelectedMap[actualGraph],
                filterInclude: includeChipList,
                filterExclude: excludeChipList,
            },
        });
        setOpenDialogFilter(!openDialogFilter);
    };

    const addFilterCallback = (newCriterion, criterionType) => {
        const [criterionList, setCriterionList] =
            criterionMapList[criterionType];
        setCriterionList([...criterionList, newCriterion]);
    };

    const onDeleteFilterCallback = (obj, criterion) => {
        const [criterionList, setCriterionList] = criterionMapList[criterion];
        const criterionListFiltered = criterionList.filter(
            (crit) => crit.valueChip !== obj.valueChip
        );
        setCriterionList(criterionListFiltered);
    };

    const updateColumns = (dataUpdated) => {
        const dataUpdatedAux = { ...dataUpdated };

        setGraphSelectedMap({
            ...graphSelectedMap,
            [actualGraph]: {
                ...graphSelectedMap[actualGraph],
                columns: dataUpdatedAux,
            },
        });
    };

    const handleClickGraphicWithoutDnd = async () => {
        let api = API_GRAPHER[actualGraph];

        const result = await api({}, studentActivityId);

        setDataGraph({
            ...dataGraph,
            [actualGraph]: result.data,
        });
    };

    const handleClickGraphic = async () => {
        const projectHeadersMap = {};
        if (graphSelectedMap[actualGraph].columns.value.length > 0) {
            graphSelectedMap[actualGraph].columns.value.forEach((h) => {
                projectHeadersMap[h] = 1;
            });
        }

        const includeHeadersMap = {};
        const excludeHeadersMap = {};

        graphSelectedMap[actualGraph].filterInclude.forEach((obj) => {
            if (!(obj.column in includeHeadersMap)) {
                includeHeadersMap[obj.column] = [];
            }
            includeHeadersMap[obj.column].push(obj.valueFilter);
        });
        graphSelectedMap[actualGraph].filterExclude.forEach((obj) => {
            if (!(obj.column in excludeHeadersMap)) {
                excludeHeadersMap[obj.column] = [];
            }
            excludeHeadersMap[obj.column].push(obj.valueFilter);
        });

        let api = API_GRAPHER[actualGraph];

        if (actualGraph === "static_network" && valueGraphTab === 1) {
            api = ApiService.getDataGraphBetweenness;
        }

        const result = await api(
            {
                graph: actualGraph,
                project_headers_map: projectHeadersMap,
                columns: graphSelectedMap[actualGraph].columns,
                include_headers_map: includeHeadersMap,
                exclude_headers_map: excludeHeadersMap,
            },
            studentActivityId
        );

        setDataGraph({
            ...dataGraph,
            [actualGraph]: result.data,
        });
    };

    const sendResolution = async () => {
        setOpenLoader(true);
        setOpenConfirmDialog(false);
        try {
            const partialDeliveryJson = {};
            const graphs = [];

            Object.entries(graphSelectedMap).forEach(([k, el]) => {
                const keySnaked = toSnakeCase(k);
                if (el.selected) {
                    graphs.push(keySnaked);
                    partialDeliveryJson[keySnaked] = el;
                }
            });

            const data = {
                partial_delivery_json: partialDeliveryJson,
                student_activity: studentActivityId,
                graphs,
            };

            const res = await ApiService.createStudentActivityResolution(data);
            setOpenLoader(false);
            if (res.status === 200) {
                setAlertText("La actividad se entregó con éxito!");
                setSeverity("success");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertText("");
                    setSeverity("");
                    callbackHandleChangeTabPanel(null, 0);
                    //aca habria q cambiar de TAB
                }, 1500);
            } else {
                setAlertText("Ocurrió un error, intente nuevamente!");
                setSeverity("error");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertText("");
                    setSeverity("");
                }, 1500);
            }
        } catch (error) {
            setAlertText("Ocurrió un error, intente nuevamente!");
            setSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setAlertText("");
                setSeverity("");
            }, 1500);
        }
    };

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
        handleChangeTabDataSource(dataSourceMap[newValue]);
    };

    const handleChangeGraphTab = (event, newValue) => {
        setGraphSelectedMap({
            ...graphSelectedMap,
            [actualGraph]: {
                ...graphSelectedMap[actualGraph],
                metric: mapValueGraphTab[valueGraphTab],
            },
        });
        setDataGraph({
            ...dataGraph,
            [actualGraph]: {},
        });
        setValueGraphTab(newValue);
        // handleChangeTabDataSource(dataSourceMap[newValue]);
    };

    console.log(valueTab);
    return (
        <div>
            <Grid container pb={5} gap={1}>
                <Grid item>
                    <Tabs
                        value={valueTab}
                        onChange={handleChangeTab}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Fuentes Estáticas" />
                        <Tab label="Fuentes Dinámicas" />
                        <Tooltip title="Cómo graficar">
                            <IconButton
                                onClick={() =>
                                    handleOpenTutorial(valueTab === 0 ? "static" : "dynamic")
                                }
                            >
                                <HelpIcon></HelpIcon>
                            </IconButton>
                        </Tooltip>
                    </Tabs>
                </Grid>
            </Grid>
            <TabPanelComponent value={valueTab} index={0}>
                {actualGraph === "word_cloud" && (
                    <div>
                        <Grid container px={10} justifyContent={"center"} flexDirection={"column"}>
                            <div>
                                <h3>Seleccionar tipo de gráficos</h3>
                            </div>
                            <div>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={actualGraph}
                                        onChange={handleChangeGraph}
                                    >
                                        {graphListAvailable.length > 0 &&
                                            graphs
                                                .filter(
                                                    (graph) =>
                                                        graph.dataSource === "static" &&
                                                        graphListAvailable.includes(graph.value)
                                                )
                                                .map((graph) => (
                                                    <FormControlLabel
                                                        key={graph.value}
                                                        value={graph.value}
                                                        control={<Radio />}
                                                        label={graph.name}
                                                    />
                                                ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid container px={10} gap={1} justifyContent={"space-around"}>
                            <WordCloudChart data={dataGraph[actualGraph]} />
                            <Grid container px={10} py={4} gap={1} justifyContent={"space-between"}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleClickGraphicWithoutDnd()}
                                >
                                    Graficar
                                </Button>
                                <Button
                                    disabled={Object.keys(dataGraph[actualGraph]).length === 0}
                                    variant="contained"
                                    onClick={async () => {
                                        const exportData = {};
                                        if (!graphSelectedMap[actualGraph].selected) {
                                            const el = document.getElementById("chart-component");
                                            exportData.offsetHeight = el.offsetHeight;
                                            exportData.offsetWidth = el.offsetWidth;
                                            exportData.image = await exportGraphToImg(el);
                                        }
                                        setGraphSelectedMap({
                                            ...graphSelectedMap,
                                            [actualGraph]: {
                                                ...graphSelectedMap[actualGraph],
                                                selected: !graphSelectedMap[actualGraph].selected,
                                                export: exportData,
                                            },
                                        });
                                    }}
                                    sx={{
                                        backgroundColor: graphSelectedMap[actualGraph].selected
                                            ? "#db0909"
                                            : "#12416F",
                                        "&:hover": {
                                            backgroundColor: graphSelectedMap[actualGraph].selected
                                                ? "#db0909"
                                                : "#12416F",
                                        },
                                    }}
                                >
                                    {graphSelectedMap[actualGraph].selected
                                        ? "Remover de entrega"
                                        : "Agregar a entrega"}
                                </Button>
                                <Button
                                    disabled={Object.keys(dataGraph[actualGraph]).length === 0}
                                    variant="contained"
                                    onClick={() => {
                                        exportMultipleChartsToPdf(
                                            Object.entries(graphSelectedMap)
                                                .filter(([key, value]) => value.selected)
                                                .map(([key, value]) => ({
                                                    ...value.export,
                                                    columns: [],
                                                    filterExclude: [],
                                                    filterInclude: [],
                                                    graph: key,
                                                })),
                                            [],
                                            studentActivity
                                        );
                                    }}
                                >
                                    Previsualizar
                                </Button>
                                <Button
                                    disabled={Object.keys(dataGraph[actualGraph]).length === 0}
                                    variant="contained"
                                    onClick={() => setOpenConfirmDialog(true)}
                                >
                                    Realizar Entrega
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {actualGraph !== "word_cloud" && (
                    <Grid container gap={1} justifyContent={"space-around"}>
                        <Grid item xs={12} lg={5}>
                            {datasetColumns?.length > 0 && (
                                <div className={classes.dndContainer}>
                                    <div style={{ display: "flex" }}>
                                        <DndComponent
                                            callback={updateColumns}
                                            data={{
                                                items: {
                                                    datasetColumns,
                                                    ...graphSelectedMap[actualGraph].columns,
                                                },
                                                limitDrag: graphSelectedMap[actualGraph].limitDrag,
                                            }}
                                            refresh={actualGraph}
                                        />
                                    </div>
                                </div>
                            )}
                            <Grid container pt={5} px={10} justifyContent={"space-between"}>
                                <Grid item lg={6}>
                                    <Button
                                        disabled={!checkSelectedColumns()}
                                        variant="contained"
                                        onClick={() => setOpenDialogFilter(!openDialogFilter)}
                                    >
                                        Fltros
                                    </Button>
                                </Grid>
                                <Grid item lg={6}>
                                    <Button
                                        disabled={!checkSelectedColumns()}
                                        variant="contained"
                                        onClick={() => handleClickGraphic()}
                                    >
                                        Graficar
                                    </Button>
                                </Grid>
                                <GenericDialog
                                    title="Filtros"
                                    open={openDialogFilter}
                                    setOpen={setOpenDialogFilter}
                                    handleSave={() => saveFilter()}
                                >
                                    <DialogContent>
                                        <FilterDataset
                                            columns={datasetColumns}
                                            studentActivity={studentActivityId}
                                            onDeleteFilterCallback={(obj, criterion) => {
                                                onDeleteFilterCallback(obj, criterion);
                                            }}
                                            addFilterCallback={(newCriterion, criterionType) => {
                                                addFilterCallback(newCriterion, criterionType);
                                            }}
                                            includeChipLoadedList={
                                                graphSelectedMap[actualGraph].filterInclude.length >
                                                0
                                                    ? graphSelectedMap[actualGraph].filterInclude
                                                    : null
                                            }
                                            excludeChipLoadedList={
                                                graphSelectedMap[actualGraph].filterExclude.length >
                                                0
                                                    ? graphSelectedMap[actualGraph].filterExclude
                                                    : null
                                            }
                                        />
                                    </DialogContent>
                                </GenericDialog>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <h3>Seleccionar tipo de gráficos</h3>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={actualGraph}
                                    onChange={handleChangeGraph}
                                >
                                    {graphListAvailable.length > 0 &&
                                        graphs
                                            .filter(
                                                (graph) =>
                                                    graph.dataSource === "static" &&
                                                    graphListAvailable.includes(graph.value)
                                            )
                                            .map((graph) => (
                                                <FormControlLabel
                                                    key={graph.value}
                                                    value={graph.value}
                                                    control={<Radio />}
                                                    label={graph.name}
                                                />
                                            ))}
                                </RadioGroup>
                            </FormControl>
                            {actualGraph === "pie" && (
                                <PieChart
                                    data={dataGraph[actualGraph]}
                                    xAxis={graphSelectedMap[actualGraph].columns.value[0]}
                                />
                            )}

                            {actualGraph === "column" && (
                                <ColumnsChart
                                    data={dataGraph[actualGraph]}
                                    xAxis={graphSelectedMap[actualGraph].columns.value[0]}
                                    yAxis={graphSelectedMap[actualGraph].columns.group_by[0]}
                                />
                            )}

                            {actualGraph === "line" && (
                                <LineChart
                                    data={dataGraph[actualGraph]}
                                    xAxis={graphSelectedMap[actualGraph].columns.value[0]}
                                    yAxis={graphSelectedMap[actualGraph].columns.group_by[0]}
                                />
                            )}

                            {actualGraph === "bubble" && (
                                <BubbleChart
                                    data={dataGraph[actualGraph]}
                                    xAxis={graphSelectedMap[actualGraph].columns.value[0]}
                                    yAxis={graphSelectedMap[actualGraph].columns.group_by[0]}
                                />
                            )}

                            {actualGraph === "map" && (
                                <MapChartGrapher data={dataGraph[actualGraph]} />
                            )}

                            {actualGraph === "static_network" && (
                                <>
                                    <Tabs
                                        sx={{ py: "10px" }}
                                        value={valueGraphTab}
                                        onChange={handleChangeGraphTab}
                                        aria-label="Tabs for different social network metrics"
                                    >
                                        <Tab label="Grafo normal" />
                                        <Tab label="Betweenness" />
                                    </Tabs>
                                    {valueGraphTab === 0 && (
                                        <StaticNetworkChart data={dataGraph[actualGraph]} />
                                    )}
                                    {valueGraphTab === 1 && (
                                        <StaticNetworkBetweennessChart
                                            data={dataGraph[actualGraph]}
                                        />
                                    )}
                                </>
                            )}

                            <Grid container px={10} py={4} gap={1} justifyContent={"space-between"}>
                                <Button
                                    disabled={Object.keys(dataGraph[actualGraph]).length === 0}
                                    variant="contained"
                                    onClick={async () => {
                                        const exportData = {};
                                        if (!graphSelectedMap[actualGraph].selected) {
                                            const el = document.getElementById("chart-component");
                                            exportData.offsetHeight = el.offsetHeight;
                                            exportData.offsetWidth = el.offsetWidth;
                                            exportData.image = await exportGraphToImg(el);
                                        }
                                        setGraphSelectedMap({
                                            ...graphSelectedMap,
                                            [actualGraph]: {
                                                ...graphSelectedMap[actualGraph],
                                                selected: !graphSelectedMap[actualGraph].selected,
                                                export: exportData,
                                            },
                                        });
                                    }}
                                    sx={{
                                        backgroundColor: graphSelectedMap[actualGraph].selected
                                            ? "#db0909"
                                            : "#12416F",
                                        "&:hover": {
                                            backgroundColor: graphSelectedMap[actualGraph].selected
                                                ? "#db0909"
                                                : "#12416F",
                                        },
                                    }}
                                >
                                    {graphSelectedMap[actualGraph].selected
                                        ? "Remover de entrega"
                                        : "Agregar a entrega"}
                                </Button>
                                <Button
                                    disabled={
                                        Object.keys(dataGraph[actualGraph]).length === 0 ||
                                        (Object.keys(dataGraph[actualGraph]).length > 0 &&
                                            !graphSelectedMap[actualGraph].selected)
                                    }
                                    variant="contained"
                                    onClick={() => {
                                        exportMultipleChartsToPdf(
                                            Object.entries(graphSelectedMap)
                                                .filter(([key, value]) => value.selected)
                                                .map(([key, value]) => ({
                                                    ...value.export,
                                                    columns: value.columns,
                                                    filterExclude: value.filterExclude,
                                                    filterInclude: value.filterInclude,
                                                    graph: key,
                                                })),
                                            datasetColumns,
                                            studentActivity
                                        );
                                    }}
                                >
                                    Previsualizar
                                </Button>
                                <Button
                                    disabled={
                                        Object.keys(dataGraph[actualGraph]).length === 0 ||
                                        (Object.keys(dataGraph[actualGraph]).length > 0 &&
                                            !graphSelectedMap[actualGraph].selected)
                                    }
                                    variant="contained"
                                    onClick={() => setOpenConfirmDialog(true)}
                                >
                                    Realizar Entrega
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </TabPanelComponent>
            <TabPanelComponent value={valueTab} index={1}>
                <SocialGraphs
                    dataGraphCached={dataGraph}
                    setDataGraphCached={setDataGraph}
                    graphSelectedMap={graphSelectedMap}
                    setGraphSelectedMap={setGraphSelectedMap}
                    sendResolution={sendResolution}
                />
            </TabPanelComponent>
            <QuestionAnswerForum
                studentActivity={studentActivity}
                updateData={getStudentActivity}
            />
            <GenericDialog
                title="Está seguro que quiere realizar la entrega?"
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                handleSave={() => sendResolution()}
            >
                <DialogContent>
                    Una vez entregada no podrá realizar cambios en la misma
                </DialogContent>
            </GenericDialog>
            {!showAlert && (
                <Grid md={3} sx={{ position: "fixed", top: "40%", left: "45%" }}>
                    <GenericAlert severity={severity} text={alertText}></GenericAlert>
                </Grid>
            )}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 999999999,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default TabNewResolution;
