import { Autocomplete, CircularProgress, Popper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

function AsyncSelect({ options, onChangeSearch, onChangeSelect, disabled = false }) {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState(null);
    const loading = open && options?.length === 0;

    useEffect(() => {
        if (search !== null) {
            const timer = setTimeout(() => {
                onChangeSearch(search);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [search]);

    const customPopper = (props) => <Popper {...props} placement="bottom-end" />;

    return (
        <Autocomplete
            PopperComponent={customPopper}
            disabled={disabled}
            id="asynchronous-demo"
            open={open}
            onChange={(e, value) => onChangeSelect(value)}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={(e) => setSearch(e.target.value)}
                    label="Instituciones"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default AsyncSelect;
