import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs from "dayjs";

export default function DateFieldComponent({
    label,
    variant = "standard",
    handleChange,
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateField"]}>
                <DateField
                    label={label}
                    variant={variant}
                    fullWidth
                    format="DD/MM/YYYY"
                    onChange={(newValue) => handleChange(newValue)}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
