import React from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";

const GenericMenuItem = ({
    buttonTitle,
    subpages,
    handleClose,
    handleOpen,
    anchorEl,
}) => {
    const navigate = useNavigate();
    return (
        <MenuItem>
            <Button onClick={handleOpen}>
                {buttonTitle} <KeyboardArrowDownIcon />
            </Button>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {subpages.map((subpage) => (
                    <MenuItem
                        key={subpage.name}
                        onClick={() => {
                            handleClose();
                            navigate(subpage.url);
                        }}
                    >
                        <Typography textAlign="center">
                            {subpage.name}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </MenuItem>
    );
};

export default GenericMenuItem;
