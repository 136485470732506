import React, { useEffect, useState } from "react";

import {
    Alert,
    Autocomplete,
    Button,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { ApiService } from "common/services/ApiService";
import { getUsersRadioGroupItems } from "../../pages/SuperAdmin/utils/constants";
import FilterBar from "common/components/FilterBar/FilterBar";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import DialogUserRoleContent from "../../pages/SuperAdmin/components/DialogUserRoleContent";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";
import DialogUserEstablishmentContent from "../../pages/SuperAdmin/components/DialogUserEstablishmentContent";
import { useAuth } from "context/AuthContext";
import getUsersColumns from "./getUsersColumns";

const UsersList = ({
    isSuperAdmin,
    getUsersFunction,
    columnVisibilityModel,
    title = 'Usuarios del Sistema',
    showComponentInFilterBar = true,
    establishment
}) => {
    const { auth } = useAuth();
    const [establishmentsAdmin, setEstablishmentsAdmin] = useState([]);
    const [establishmentAdmin, setEstablishmentAdmin] = useState(
        establishment || auth.selectedEstablishment
    );
    const [users, setUsers] = useState([]);
    const [openLoader, setOpenLoader] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [extraFilter, setExtraFilter] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState("");

    const [usersColumns, setUsersColumns] = useState();

    const [showError, setShowError] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");

    const [extraFilterComponent, setExtraFilterComponent] = useState({
        establishment:
            showComponentInFilterBar && auth.selectedRole?.name === "superadmin"
                ? ""
                : establishment?.id || auth.selectedEstablishment?.id || "",
        role_name: getUsersRadioGroupItems(auth.selectedRole?.name)[0].value,
    });

    const [roleEstablishment, setRoleEstablishment] = useState({});

    const extraFilterList = [
        {
            type: "radio",
            key: "role_name",
            items: getUsersRadioGroupItems(auth.selectedRole?.name),
        },
    ];

    const openModal = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
    };

    const getUsers = async (filter = "", extraFilter = {}, page = 1, pageSize = 10) => {
        setOpenLoader(true);
        if (extraFilter.role_name === "pending") {
            setUsersColumns(
                getUsersColumns.getUsersColumnsWithPending(setDialogContent, openModal)
            );
        } else {
            setUsersColumns(
                getUsersColumns.getUsersColumnsWithoutPending(
                    setDialogContent,
                    openModal,
                    isSuperAdmin
                )
            );
        }

        const res = await getUsersFunction(page, pageSize, {
            ...extraFilter,
            search: filter,
            role_name: extraFilter.role_name !== "pending" ? extraFilter.role_name : "",
            roles_pending: extraFilter.role_name === "pending",
        });
        setRowCount(res.data.count);
        setPageSize(pageSize);
        setPage(page);
        setFilter(filter);
        setExtraFilter(extraFilter);
        setUsers(res.data.results);
        setOpenLoader(false);
    };

    const handleSave = async () => {
        try {
            setOpenConfirmationDialog(false);
            const res = await ApiService.updateUserRoles(
                selectedUser.id,
                roleEstablishment
            );
            if (res.status === 200) {
                setAlertText("Información actualizada con éxito!");
                setAlertSeverity("success");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertText("");
                    setOpenDialog(false);
                }, 2500);
            } else {
                setAlertText("Ocurrió un error, intente nuevamente!");
                setAlertSeverity("error");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertText("");
                }, 2500);
            }
        } catch (error) {
            setAlertText("Ocurrió un error, intente nuevamente!");
            setAlertSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setAlertText("");
            }, 2500);
        }
    };

    const handleOpenConfirmationModal = () => {
        console.log(roleEstablishment);
        if (
            selectedUser.id === null ||
            !roleEstablishment.establishment ||
            !roleEstablishment.roles.length > 0
        ) {
            setShowError(true);

            setTimeout(() => {
                setShowError(false);
            }, 2500);
        } else {
            setOpenConfirmationDialog(true);
        }
    };

    const componentFilterBarSuperAdmin = (
        <SelectProvinceAndLocation
            handleChangeProvince={() => {}}
            handleChangeLocation={() => {}}
            handleChangeEstablishment={(establishment) => {
                setExtraFilterComponent({
                    ...extraFilterComponent,
                    establishment: establishment?.id,
                });
            }}
            showEstablishments={true}
            xs={12}
        />
    );

    const componentFilterBarAdmin = (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Autocomplete
                    value={establishmentAdmin}
                    id="autocomplete"
                    freeSolo
                    // options={products.map((option) => option.name)}
                    options={establishmentsAdmin}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    getOptionLabel={(option) => {
                        return option.name;
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Seleccione una institución" />
                    )}
                    onChange={(e, v) => {
                        setEstablishmentAdmin(v);
                        setExtraFilterComponent({
                            ...extraFilterComponent,
                            establishment: v?.id,
                        });
                    }}
                />
            </Grid>
        </Grid>
    );

    useEffect(() => {
        setUsersColumns(
            getUsersColumns.getUsersColumnsWithoutPending(setDialogContent, openModal, isSuperAdmin)
        );
        setEstablishmentsAdmin(auth.rolesEstablishment.map((re) => re.establishment));
    }, []);

    return (
        <div>
            <h1> {title} </h1>
            {usersColumns && (
                <FilterBar
                    columnVisibilityModel={columnVisibilityModel}
                    onPageChange={getUsers}
                    openLoader={openLoader}
                    rowCount={rowCount}
                    columns={usersColumns}
                    rows={users}
                    pageSize={pageSize}
                    enableExtraFilter
                    extraFilterList={extraFilterList}
                    extraFilterComponent={extraFilterComponent}
                    component={
                        showComponentInFilterBar ? (
                            isSuperAdmin ? componentFilterBarSuperAdmin : componentFilterBarAdmin
                        ): undefined
                    }
                />
            )}

            {openDialog && (
                <GenericDialog
                    // handleSave={handleSave}
                    handleSave={handleOpenConfirmationModal}
                    open={openDialog}
                    setOpen={setOpenDialog}
                    cancelButton={dialogContent === "establishment"}
                    saveButton={dialogContent === "establishment"}
                    title={
                        dialogContent === "establishment"
                            ? "Asignación de establecimientos"
                            : "Asignación de roles"
                    }
                    onClose={() => {
                        getUsers(filter, extraFilter, page, pageSize);
                    }}
                    dialogWidth={dialogContent === "role"}
                >
                    {dialogContent === "role" && (
                        <DialogUserRoleContent
                            selectedUser={selectedUser}
                            handleChangeExternalData={(data) => {
                                setRoleEstablishment({
                                    ...roleEstablishment,
                                    ...data,
                                });
                            }}
                        ></DialogUserRoleContent>
                    )}
                    {dialogContent === "establishment" && (
                        <DialogUserEstablishmentContent
                            defaultEstablishment={establishmentAdmin}
                            isSuperAdmin={isSuperAdmin}
                            selectedUser={selectedUser}
                            handleChangeExternalData={(data) => {
                                setRoleEstablishment({
                                    ...roleEstablishment,
                                    ...data,
                                });
                            }}
                        ></DialogUserEstablishmentContent>
                    )}
                    {showError && (
                        <Typography variant="p" px={4} color={"red"}>
                            Debe seleccionar todos los campos
                        </Typography>
                    )}
                </GenericDialog>
            )}

            {openConfirmationDialog && (
                <GenericDialog
                    handleSave={handleSave}
                    open={openConfirmationDialog}
                    setOpen={setOpenConfirmationDialog}
                    // cancelButton={dialogContent === "establishment"}
                    // saveButton={dialogContent === "establishment"}
                    title={"Confirmación"}
                    onClose={() => {
                        getUsers(filter, extraFilter, page, pageSize);
                    }}
                >
                    <Typography variant="p" px={3}>
                        Está seguro que desea guardar esta información?
                    </Typography>
                </GenericDialog>
            )}
            {showAlert && (
                <Alert
                    sx={{
                        zIndex: 99999,
                        position: "fixed",
                        top: "50%",
                        left: "40%",
                        color: "white",
                        fontWeight: "bold",
                    }}
                    variant="filled"
                    severity={alertSeverity}
                >
                    {alertText}
                </Alert>
            )}
        </div>
    );
};

export default UsersList;
