import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { verifyToken } from "utils/verifyToken";

export const AuthContext = React.createContext();

const getLocalStorageObject = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const AuthProvider = (props) => {
    const [auth, setAuth] = useState(
        localStorage.getItem("access") !== null
            ? {
                  access: localStorage.getItem("access"),
                  rolesEstablishment: getLocalStorageObject("rolesEstablishment"),
                  userName: localStorage.getItem("userName"),
                  userId: localStorage.getItem("userId"),
                  selectedEstablishment: getLocalStorageObject("selectedEstablishment"),
                  selectedRole: getLocalStorageObject("selectedRole"),
                  pendingNotification: getLocalStorageObject("pendingNotification"),
              }
            : ""
    );

    useEffect(() => {
        function checkToken() {
            const isAvailable = verifyToken();
            if (!isAvailable) {
                setAuth("");
            }
        }
        checkToken();
    }, []);

    return (
        // <AuthContext.Provider value={{ auth, setAuth, userName, setUserName }}>
        <AuthContext.Provider value={{ auth, setAuth }}>{props.children}</AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
