import React, { useState } from "react";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { ApiService } from "common/services/ApiService";
import { Box, Button, Container, Tab, Tabs } from "@mui/material";
import CourseActivitiesList from "../CourseActivitiesList/CourseActivitiesList";
import Activities from "modules/activity/pages/Activities/Activities";
import TabPanelComponent from "common/components/TabPanel/TabPanelComponent";
import UserCourseRequestList from "modules/authentication/components/CourseDetail/UserCourseRequestList";
import BackButton from "common/components/BackButton/BackButton";

const CourseDetail = () => {
    const { auth } = useAuth();
    const { id } = useParams();

    const [course, setCourse] = useState(null);
    const [valueTab, setValueTab] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const res = await ApiService.getCourseFromId(id);
            setCourse(res.data);
        };
        fetchData();
    }, []);

    const renderCell = (params) => {
        return (
            <Button
                variant="contained"
                onClick={() => {
                    console.log(params.row);
                }}
            >
                Ver curso
            </Button>
        );
    };

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    return (
        <Container>
            <Box style={{ padding: "10px 0px 0px 0px" }}>
                <BackButton />
            </Box>
            {course !== null && (
                <>
                    <h1>
                        {course.establishment.name} - Curso: {course.name}
                    </h1>
                    <small>
                        <strong>CICLO LECTIVO: {course.cicle}</strong>
                    </small>
                    <br />
                    <small>
                        <strong>
                            Inicio:{" "}
                            {course.start_date.split("-").reverse().join("/")} -
                            Fin:{" "}
                            {course.end_date.split("-").reverse().join("/")}
                        </strong>
                    </small>
                    <Tabs
                        value={valueTab}
                        onChange={handleChangeTab}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Alumnos" />

                        <Tab label="Actividades" />
                    </Tabs>
                    <TabPanelComponent value={valueTab} index={0}>
                        <UserCourseRequestList
                            getUsersFunction={ApiService.getFilterUsers}
                            isSuperAdmin={
                                auth.selectedRole?.name === "superadmin"
                            }
                            title="Alumnos"
                            showComponentInFilterBar={false}
                            establishment={course.establishment}
                            courseId={course.id}
                        />
                    </TabPanelComponent>
                    <TabPanelComponent value={valueTab} index={1}>
                        {["teacher", "admin", "superadmin"].includes(
                            auth.selectedRole?.name
                        ) && <CourseActivitiesList courseId={course.id} />}
                        {auth.selectedRole?.name === "student" && (
                            <Activities />
                        )}
                    </TabPanelComponent>
                </>
            )}
        </Container>
    );
};

export default CourseDetail;
