import { useState } from "react";

export const useBubbleChart = (title) => {
    const [options, setOptions] = useState({
        chart: {
            type: "bubble",
            plotBorderWidth: 1,
            zoomType: "xy",
        },

        yAxis: {
            startOnTick: false,
            endOnTick: false,
        },

        series: [],
    });

    return {
        options,
        setOptions,
    };
};
