const { Button, Grid } = require("@mui/material");

const getPendingColumns = (navigate, role) => {
    return [
        { field: "title", headerName: "Título", width: 150 },
        { field: "userName", width: 300, headerName: "Usuario" },
        { field: "firstName", width: 150, headerName: "Nombre" },
        { field: "lastName", width: 150, headerName: "Apellido" },
        {
            field: "actions",
            width: 300,
            headerName: "Acciones",
            renderCell: (params) => {
                return (
                    <Grid container gap={1} flexDirection={"row"}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    navigate(`/grapher/${params.row.id}`)
                                }
                            >
                                {role !== "student" ? "Ver" : "Resolver"}
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];
};

const getDelayedColumns = () => {
    return [
        { field: "title", headerName: "Título", width: 230 },
        { field: "startDate", headerName: "Fecha de comienzo", width: 230 },
        { field: "endDate", headerName: "Fecha de cierre", width: 230 },
        { field: "course", headerName: "Curso", width: 230 },
        { field: "state", headerName: "Estado", width: 230 },
    ];
};
const getCorrectedColumns = (navigate, role) => {
    return [
        { field: "title", headerName: "Título", width: 150 },
        { field: "userName", width: 300, headerName: "Usuario" },
        { field: "firstName", width: 150, headerName: "Nombre" },
        { field: "lastName", width: 150, headerName: "Apellido" },
        { field: "qualification", headerName: "Calificación", width: 230 },
        {
            field: "actions",
            width: 300,
            headerName: "Acciones",
            renderCell: (params) => {
                return (
                    <Grid container gap={1} flexDirection={"row"}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    navigate(`/grapher/${params.row.id}`)
                                }
                            >
                                Ver
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
        // { field: "comment", headerName: "Comentarios", width: 230 },
    ];
};
const getDeliveredColumns = (navigate, role) => {
    return [
        { field: "title", headerName: "Título", width: 150 },
        { field: "userName", width: 300, headerName: "Usuario" },
        { field: "firstName", width: 150, headerName: "Nombre" },
        { field: "lastName", width: 150, headerName: "Apellido" },
        {
            field: "actions",
            width: 300,
            headerName: "Acciones",
            renderCell: (params) => {
                return (
                    <Grid container gap={1} flexDirection={"row"}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    navigate(`/grapher/${params.row.id}`)
                                }
                            >
                                Ver
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];
};
const getExpiredColumns = (navigate, role) => {
    return [
        { field: "title", headerName: "Título", width: 150 },
        { field: "userName", width: 300, headerName: "Usuario" },
        { field: "firstName", width: 150, headerName: "Nombre" },
        { field: "lastName", width: 150, headerName: "Apellido" },
        {
            field: "actions",
            width: 300,
            headerName: "Acciones",
            renderCell: (params) => {
                return (
                    <Grid container gap={1} flexDirection={"row"}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    navigate(`/grapher/${params.row.id}`)
                                }
                            >
                                Ver
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];
};

const getColumns = {
    getPendingColumns,
    getDelayedColumns,
    getCorrectedColumns,
    getDeliveredColumns,
    getExpiredColumns,
};

export default getColumns;
