import Chart from "common/components/Chart/Chart";
import { usePieChart } from "hooks/chart/UsePieChart";
import React, { useEffect } from "react";
import { MOREGRAPH } from "utils/constants";

const PieChart = ({ data, xAxis }) => {
    const { options: optionsPieChart, setOptions: setOptionsPieChart } = usePieChart();

    useEffect(() => {
        if ("data" in data && Object.keys(data).length > 0) {
            setOptionsPieChart({
                ...optionsPieChart,
                series: [
                    {
                        ...optionsPieChart.series[0],
                        data: data.data.map((obj) => ({
                            name: obj[`group_by_${xAxis}`][xAxis],
                            y: (obj.count / data.total) * 100,
                        })),
                    },
                ],
            });
        }
    }, [data]);

    return <Chart options={optionsPieChart} type={MOREGRAPH} />;
};

export default PieChart;
