import React from "react";
import PropTypes from "prop-types";
import { stylesSx } from "common/components/NavBar/styles.js";
import { Box, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const NotLoggedUserMenu = ({ setOpenLogInForm, setOpenSignInForm }) => {
    const { boxDisplayStyle, boxFlexStyle } = stylesSx;

    return (
        <>
            <Box sx={boxFlexStyle} />
            <Box sx={boxDisplayStyle}>
                <Button
                    variant="outlined"
                    sx={{ color: "white" }}
                    startIcon={<PersonIcon />}
                    onClick={() => {
                        setOpenLogInForm(true);
                        setOpenSignInForm(false);
                    }}
                >
                    Iniciar sesión
                </Button>
                <Button
                    variant="outlined"
                    sx={{ color: "white", marginLeft: "10px" }}
                    onClick={() => {
                        setOpenSignInForm(true);
                        setOpenLogInForm(false);
                    }}
                >
                    Registrarse
                </Button>
            </Box>
        </>
    );
};

NotLoggedUserMenu.propTypes = {
    children: PropTypes.node,
    // index: PropTypes.number.isRequired,
    // value: PropTypes.number.isRequired,
};

export default NotLoggedUserMenu;
