import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Slider,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExcludedWords from "./components/ExcludedWords";
import { ApiService } from "common/services/ApiService";
import { exportGraphToImg, exportMultipleChartsToPdf } from "utils/exportPdf";
import SocialNetworkChart from "modules/activity/pages/Activities/components/SocialNetworkChart";
import SocialNetworkCommentsChart from "modules/activity/pages/Activities/components/SocialNetworkCommentsChart";

const API_GRAPHER = {
    social_word_frequency: ApiService.getRedditGraph,
    social_user_comments: ApiService.getRedditGraphUserComments,
};

const SocialGraphs = ({
    dataGraphCached,
    setDataGraphCached,
    graphSelectedMap,
    setGraphSelectedMap,
    sendResolution,
}) => {
    const [openLoader, setOpenLoader] = useState(false);
    const [graph, setGraph] = useState("social_user_comments");
    const [textValue, setTextValue] = useState("");
    const [textValueError, setTextValueError] = useState(false);

    const [sliderValue, setSliderValue] = useState(5);
    const [itemsState, setItemsState] = useState([
        { name: "Preposiciones", checked: false, value: "prepositions" },
        { name: "Articulos", checked: false, value: "articles" },
        { name: "Pronombres", checked: false, value: "pronouns" },
        {
            name: "Conjunciones de ser, estar, haber y tener",
            checked: false,
            value: "to_be_and_more",
        },
        { name: "Adverbios", checked: false, value: "adverbs" },
        { name: "Conjugaciones", checked: false, value: "conjunctions" },
    ]);

    const handleChange = (event, selectedItem) => {
        const newState = itemsState.map((item) => {
            if (item.name === selectedItem.name) {
                return { ...item, checked: event.target.checked };
            }
            return item;
        });

        setItemsState(newState);
    };

    async function getData() {
        setOpenLoader(true);
        const params = {
            word: textValue,
            frequency: sliderValue,
            exclude_list: itemsState
                .filter((i) => i.checked)
                .map((i) => i.value)
                .join("|"),
        };
        const response = await API_GRAPHER[graph](params);

        // const response = await ApiService.getRedditGraph(params);
        if (response.data.links) {
            setDataGraphCached({
                ...dataGraphCached,
                [graph]: response.data,
            });
        }
        setOpenLoader(false);
    }

    const handleSearch = () => {
        if (!textValue.trim()) {
            setTextValueError(true);

            return;
        }

        getData();
    };

    return (
        <div>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <TextField
                        value={textValue}
                        error={textValueError}
                        label="Inserte una palabra o frase"
                        variant="outlined"
                        fullWidth
                        onChange={(evt) => {
                            setTextValue(evt.target.value);
                            setTextValueError(false);
                        }}
                    />
                    {textValueError === true ? (
                        <Typography
                            variant="p"
                            fontSize={13}
                            ml={0.2}
                            color={"red"}
                        >
                            El campo es obligatorio
                        </Typography>
                    ) : (
                        ""
                    )}

                    <FormControl>
                        <FormLabel id="visilibty">Visibilidad</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={graph}
                            onChange={(e) => setGraph(e.target.value)}
                        >
                            <FormControlLabel
                                value="social_user_comments"
                                control={<Radio />}
                                label="Grafo social (Interaccion Usuarios)"
                            />
                            <FormControlLabel
                                value="social_word_frequency"
                                control={<Radio />}
                                label="Grafo social (Ocurrecias)"
                            />
                        </RadioGroup>
                    </FormControl>

                    <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Grid item md={4}>
                            <h4>Cantidad de palabras a mostrar</h4>
                        </Grid>
                        <Grid item md={6}>
                            <Slider
                                aria-label="Cantidad de palabras"
                                defaultValue={5}
                                valueLabelDisplay="auto"
                                step={1}
                                marks
                                min={1}
                                max={10}
                                onChange={(evt) =>
                                    setSliderValue(evt.target.value)
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ExcludedWords
                            itemsState={itemsState}
                            handleChange={handleChange}
                        ></ExcludedWords>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button variant="contained" onClick={handleSearch}>
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {graph === "social_word_frequency" && (
                <Grid container display={"flex"} justifyContent={"center"}>
                    <Grid item xs={12} md={9}>
                        <SocialNetworkChart data={dataGraphCached[graph]} />
                    </Grid>
                </Grid>
            )}

            {graph === "social_user_comments" && (
                <Grid container display={"flex"} justifyContent={"center"}>
                    <Grid item xs={12} md={9}>
                        <SocialNetworkCommentsChart
                            data={dataGraphCached[graph]}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid
                container
                px={10}
                py={4}
                gap={1}
                justifyContent={"space-between"}
            >
                <Button
                    disabled={Object.keys(dataGraphCached[graph]).length === 0}
                    variant="contained"
                    onClick={async () => {
                        const exportData = {};
                        if (!graphSelectedMap[graph].selected) {
                            const el =
                                document.getElementById("chart-component");
                            exportData.offsetHeight = el.offsetHeight;
                            exportData.offsetWidth = el.offsetWidth;
                            exportData.image = await exportGraphToImg(el);
                        }
                        setGraphSelectedMap({
                            ...graphSelectedMap,
                            [graph]: {
                                ...graphSelectedMap[graph],
                                selected: !graphSelectedMap[graph].selected,
                                export: exportData,
                                frequency: sliderValue,
                                word: textValue,
                            },
                        });
                    }}
                    sx={{
                        backgroundColor: graphSelectedMap[graph].selected
                            ? "#db0909"
                            : "#12416F",
                        "&:hover": {
                            backgroundColor: graphSelectedMap[graph].selected
                                ? "#db0909"
                                : "#12416F",
                        },
                    }}
                >
                    {graphSelectedMap[graph].selected
                        ? "Remover de entrega"
                        : "Agregar a entrega"}
                </Button>
                <Button
                    disabled={
                        Object.keys(dataGraphCached[graph]).length === 0 ||
                        (Object.keys(dataGraphCached[graph]).length > 0 &&
                            !graphSelectedMap[graph].selected)
                    }
                    variant="contained"
                    onClick={() => {
                        exportMultipleChartsToPdf(
                            Object.values(graphSelectedMap)
                                .filter((el) => el.selected)
                                .map((v) => v.export)
                        );
                    }}
                >
                    Previsualizar
                </Button>
                <Button
                    disabled={
                        Object.keys(dataGraphCached[graph]).length === 0 ||
                        (Object.keys(dataGraphCached[graph]).length > 0 &&
                            !graphSelectedMap[graph].selected)
                    }
                    variant="contained"
                    onClick={() => sendResolution()}
                >
                    Realizar Entrega
                </Button>
            </Grid>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 3,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default SocialGraphs;
