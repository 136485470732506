import { Button, Chip, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { ApiService } from "common/services/ApiService";
import React, { useEffect, useState } from "react";

const FilterDataset = ({
    columns,
    studentActivity,
    addFilterCallback,
    onDeleteFilterCallback,
    includeChipLoadedList,
    excludeChipLoadedList
}) => {
    const [valueColumnRadioButton, setValueColumnRadioButton] = useState("")
    const [valueCriterionRadioButton, setValueCriterionRadioButton] = useState("")
    const [optionsValueByHeaderMap, setOptionsValueByHeaderMap] = useState({})
    const [optionValueByHeader, setOptionValueByHeader] = useState("")
    const [includeChipList, setIncludeChipList] = useState(includeChipLoadedList || [])
    const [excludeChipList, setExcludeChipList] = useState(excludeChipLoadedList || [])

    const criterionMapList = {
        'include': [includeChipList, setIncludeChipList],
        'exclude': [excludeChipList, setExcludeChipList]
    }

    const addFilter = () => {
        const criterion = {}
        criterion.label = `${valueColumnRadioButton}/${optionValueByHeader}`
        criterion.valueChip = `${valueColumnRadioButton}-${optionValueByHeader}`
        criterion.valueFilter = optionValueByHeader
        criterion.column = valueColumnRadioButton

        const [criterionList, setCriterionList] = criterionMapList[valueCriterionRadioButton]
        setCriterionList([
            ...criterionList,
            criterion
        ])
        setValueColumnRadioButton("")
        setValueCriterionRadioButton("")
        setOptionValueByHeader("")
        addFilterCallback(criterion, valueCriterionRadioButton)
    }

    const onDeleteChip = (obj, criterion = 'include') => {
        const [criterionList, setCriterionList] = criterionMapList[criterion]
        setCriterionList(criterionList.filter(crit => (
            crit.valueChip !== obj.valueChip
        )))
        onDeleteFilterCallback(obj, criterion)
    }

    useEffect(() => {
        if (valueColumnRadioButton) {
            const getValuesForHeader = async () => {
                const params = { header: valueColumnRadioButton }
                const result = await ApiService.getValuesForHeader(studentActivity, params)
                const [criterionList, setCriterionList] = criterionMapList[valueCriterionRadioButton]
                const options = {
                    ...optionsValueByHeaderMap,
                    [valueColumnRadioButton]: result.data.values.map(obj => ({
                        value: obj,
                        disabled: criterionList.some(crit => (
                            crit.column === valueColumnRadioButton && crit.valueFilter === obj)
                        ) || false
                    }))
                }
                setOptionsValueByHeaderMap(options)
                setOptionValueByHeader(options[valueColumnRadioButton].filter(obj => !obj.disabled)[0].value)
            }

            if (
                !optionsValueByHeaderMap[valueColumnRadioButton] ||
                optionsValueByHeaderMap[valueColumnRadioButton]?.length === 0
            ) {
                getValuesForHeader()
            } else {
                const [criterionList, setCriterionList] = criterionMapList[valueCriterionRadioButton]
                const options = {
                    ...optionsValueByHeaderMap,
                    [valueColumnRadioButton]: optionsValueByHeaderMap[valueColumnRadioButton].map(obj => ({
                        value: obj.value,
                        disabled: criterionList.some(crit => (
                            crit.column === valueColumnRadioButton && crit.valueFilter === obj.value
                        )) || false
                    }))
                }
                setOptionsValueByHeaderMap(options)
                setOptionValueByHeader(options[valueColumnRadioButton].filter(obj => !obj.disabled)[0].value)
            }
        }
    }, [valueColumnRadioButton])

    useEffect(() => {
        setValueColumnRadioButton("")
        setOptionValueByHeader("")
    }, [valueCriterionRadioButton])

    return (
        <div>
            <h3> Filtros Aplicados</h3>
            <div>
                <FormControl>
                    <FormLabel>Incluir:</FormLabel>
                    <div style={{ paddingTop: 10, paddingBottom: 10, display: 'flex', gap: 5 }}>
                        {criterionMapList['include'][0].map(obj => (
                            <Chip label={obj.label} onDelete={() => onDeleteChip(obj, 'include')} />
                        ))}
                    </div>
                </FormControl>
            </div>
            <div>
                <FormControl>
                    <FormLabel>Excluir:</FormLabel>
                    <div style={{ paddingTop: 10, paddingBottom: 10, display: 'flex', gap: 5 }}>
                        {criterionMapList['exclude'][0].map(obj => (
                            <Chip label={obj.label} onDelete={() => onDeleteChip(obj, 'exclude')} />
                        ))}
                    </div>
                </FormControl>
            </div>
            <br />
            <div>
                <FormControl>
                    <FormLabel>Criterio</FormLabel>
                    <RadioGroup
                        value={valueCriterionRadioButton}
                        onChange={(e) => setValueCriterionRadioButton(e.target.value)}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="include" control={<Radio />} label="Incluir" />
                        <FormControlLabel value="exclude" control={<Radio />} label="Excluir" />
                    </RadioGroup>
                </FormControl>
            </div>
            <br />
            <div>
                {columns.length > 0 && (
                    <FormControl disabled={valueCriterionRadioButton === ""}>
                        <FormLabel>Columnas</FormLabel>
                        <RadioGroup
                            value={valueColumnRadioButton}
                            onChange={(e) => setValueColumnRadioButton(e.target.value)}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            {columns.map((obj) => (
                                <FormControlLabel
                                    value={obj}
                                    control={<Radio />}
                                    label={obj}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                )}
            </div>
            <br />
            <div>
                <FormControl disabled={valueColumnRadioButton === ""}>
                    <FormLabel>Valor</FormLabel>

                    <Select
                        value={optionValueByHeader}
                        onChange={(e) => setOptionValueByHeader(e.target.value)}
                    >
                        {
                            Object.keys(optionsValueByHeaderMap).length > 0 &&
                            optionsValueByHeaderMap[valueColumnRadioButton] && (
                                optionsValueByHeaderMap[valueColumnRadioButton].map(obj => (
                                    <MenuItem disabled={obj.disabled} value={obj.value}> {obj.value} </MenuItem>
                                ))
                            )
                        }
                    </Select>

                </FormControl>
            </div>
            <br />
            <div>
                <Button
                    variant="contained"
                    onClick={() => addFilter()}
                >
                    Agregar Filtro
                </Button>
            </div>
        </div>
    )
}

export default FilterDataset;