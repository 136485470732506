import { useState } from "react";

export const useWordCloudChart = (title) => {
    const [options, setOptions] = useState({
        series: [{
            type: 'wordcloud',
            data: [],
            name: 'Occurrences'
        }],
        tooltip: {
            headerFormat: '<span style="font-size: 16px"><b>{point.key}</b>' +
                '</span><br>'
        }
    });

    return {
        options,
        setOptions,
    };
};
