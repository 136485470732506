import { React, useState } from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import "./styles.scss";

const RemoteTable = (props) => {
    const {
        rows,
        columns,
        height = 400,
        rowCount,
        onPageChange,
        openLoader,
        filter = "",
        extraFilter = "",
        columnVisibilityModel = {},
    } = props;

    const [pageState, setPageState] = useState({
        page: 1,
        pageSize: 10,
    });

    return (
        <div style={{ height, width: "100%" }}>
            <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel,
                    },
                }}
                autoHeight
                rows={rows || []}
                rowCount={rowCount}
                loading={openLoader}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pagination
                page={pageState.page - 1}
                pageSize={pageState.pageSize}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageState((old) => ({ ...old, page: newPage + 1 }));
                    onPageChange(filter, extraFilter, newPage + 1, pageState.pageSize);
                }}
                onPageSizeChange={(newPageSize) => {
                    setPageState((old) => ({ ...old, pageSize: newPageSize }));
                    onPageChange(filter, extraFilter, pageState.page, newPageSize);
                }}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                experimentalFeatures={{
                    lazyLoading: true,
                }}
                sx={{ overflowX: "scroll" }}
            />
        </div>
    );
};
export default RemoteTable;
