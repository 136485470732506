const { Button } = require("@mui/material");

const cols = [];

const getUsersColumnsBase = () => {
    return [
        { field: "first_name", headerName: "Nombre", width: 150 },
        { field: "email", headerName: "Email", width: 300 },
    ];
};

const getUsersColumnsWithoutPending = (setDialogContent, openModal, isSuperAdmin) => {
    return [
        ...getUsersColumnsBase(),
        {
            field: "actions",
            headerName: "Acciones",
            width: 430,
            hideable: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ marginLeft: "10px" }}
                            onClick={() => {
                                setDialogContent("establishment");
                                openModal(params.row);
                            }}
                        >
                            {isSuperAdmin ? "Asignar institución" : "Asignar Rol"}
                        </Button>
                    </div>
                );
            },
        },
    ];
};

const getUsersColumnsWithPending = (setDialogContent, openModal) => {
    return [
        ...getUsersColumnsBase(),
        {
            field: "actions",
            headerName: "Acciones",
            width: 430,
            hideable: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                setDialogContent("role");
                                openModal(params.row);
                            }}
                        >
                            Ver roles solicitados
                        </Button>
                    </div>
                );
            },
        },
    ];
};

const getUsersColumnsAcceptOrReject = (openModal, setAccept) => {
    return [
        ...getUsersColumnsBase(),
        {
            field: "actions",
            headerName: "Acciones",
            width: 430,
            hideable: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                openModal(params.row.id);
                                setAccept(true);
                            }}
                        >
                            Aceptar
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ marginLeft: "10px" }}
                            onClick={() => {
                                openModal(params.row.id);
                                setAccept(false);
                            }}
                        >
                            Rechazar
                        </Button>
                    </div>
                );
            },
        },
    ];
};

const getUsersColumns = {
    getUsersColumnsWithoutPending,
    getUsersColumnsWithPending,
    getUsersColumnsBase,
    getUsersColumnsAcceptOrReject,
};

export default getUsersColumns;
