import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Button, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import GenericMenuItem from "common/components/GenericMenuItem/GenericMenuItem";
import { useNavigate } from "react-router-dom";
import { datasetSubPages, grafosSocialesSubPages } from "../utils/constants";
import { useAuth } from "context/AuthContext";

const LoggedUserMenuMobile = ({
    anchorElNav,
    handleCloseNavMenu,
    handleOpenNavMenu,
    handleOpenMenu,
    handleCloseMenu,
    anchorElements,
    // handleOpenDatasetsMenu,
    // handleOpenGrafosMenu,
    // handleCloseDatasets,
    // handleCloseGrafos,
    // anchorElDatasets,
    // anchorElGrafos,
}) => {
    const navigate = useNavigate();
    const { auth } = useAuth();

    const getAnchorElementValue = (menuName) => {
        const element = anchorElements.find(
            (element) => element.name === menuName
        );

        if (element) {
            return element.value;
        }
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
            }}
        >
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: "block", md: "none" },
                }}
            >
                <GenericMenuItem
                    buttonTitle={"Datasets"}
                    handleOpen={(evt) => handleOpenMenu(evt, "datasets")}
                    handleClose={() => {
                        handleCloseMenu("datasets");
                        // handleCloseDatasets();
                        handleCloseNavMenu();
                    }}
                    subpages={datasetSubPages}
                    anchorEl={getAnchorElementValue("datasets")}
                ></GenericMenuItem>
                <GenericMenuItem
                    buttonTitle={"Grafos sociales"}
                    handleOpen={(evt) => handleOpenMenu(evt, "grafos")}
                    handleClose={() => {
                        handleCloseMenu("grafos");
                        // handleCloseGrafos();
                        handleCloseNavMenu();
                    }}
                    subpages={grafosSocialesSubPages}
                    anchorEl={getAnchorElementValue("grafos")}
                ></GenericMenuItem>
                {auth.selectedRole?.name === "student" && (
                    <MenuItem onClick={handleCloseNavMenu}>
                        <Button onClick={() => navigate("/courses")}>
                            Cursos
                        </Button>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

LoggedUserMenuMobile.propTypes = {
    anchorElNav: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    handleCloseNavMenu: PropTypes.func.isRequired,
    handleOpenNavMenu: PropTypes.func.isRequired,
    // handleOpenDatasetsMenu: PropTypes.func.isRequired,
    // handleOpenGrafosMenu: PropTypes.func.isRequired,
    // handleCloseDatasets: PropTypes.func.isRequired,
    // handleCloseGrafos: PropTypes.func.isRequired,
    // anchorElDatasets: PropTypes.oneOfType([
    //     PropTypes.func,
    //     PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    // ]),
    // anchorElGrafos: PropTypes.oneOfType([
    //     PropTypes.func,
    //     PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    // ]),
};

export default LoggedUserMenuMobile;
