import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };
    return <Button onClick={handleGoBack}>Volver</Button>;
};

export default BackButton;
