import { React, useEffect, useState } from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import "./styles.scss";

const GenericTable = (props) => {
    const {
        rows,
        columns,
        height = 400,
        columnVisibilityModel = {},
        disableSelectionOnClick,
    } = props;
    const [pageSize, setPageSize] = useState(10);

    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (rows.length > 0 && !disableSelectionOnClick) {
            setSelectionModel([rows[0].id]);
        }
    }, [rows, disableSelectionOnClick]);

    return (
        <div style={{ height, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                autoHeight
                initialState={{
                    columns: {
                        columnVisibilityModel,
                    },
                }}
                disableSelectionOnClick={disableSelectionOnClick}
                // checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelection) => {
                    setSelectionModel(newSelection);
                }}
                sx={{ overflowX: "scroll" }}
            />
        </div>
    );
};
export default GenericTable;
