import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    containerDropzone: {
        width: "50%",
        margin: "auto",
        marginTop: "2%",
    },
}));

export const stylesSx = {
    main: {
        // minHeight: "90vh",
        marginBottom: "35px",
    },
    titleStyle: {
        fontWeight: "bold",
        fontSize: "2.5rem",
        color: "primary.dark",
    },
    centeredColumnDiv: {
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    howItWorks: {
        backgroundColor: "grey.light",
        display: "flex",
        justifyContent: "center",
        minHeight: "350px",
        marginTop: "20px",
    },
    howItWorksImages: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        "& img": {
            width: "25%",
            maxHeight: "50%",
            objectFit: "contain",
        },
        "& span": {
            width: "75%",
            textAlign: "center",
            fontWeight: "bold",
            color: "primary.dark",
        },
    },
    footer: {
        backgroundColor: "primary.dark",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "250px",
        marginTop: "20px",
        "& div": {
            height: "100px",
            color: "secondary.white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            textAlign: "justify",
            padding: "0px 15px",
            "& h5": {
                fontSize: "18px",
                fontWeight: "bolder",
                textAlign: "justify",
            },
            "& span": {
                fontSize: "14px",
            },
            "& ul": {
                listStyle: "none",
                textAlign: "left",
                padding: "0",
                "& li": {
                    margin: "0 0 10px 0",
                    fontSize: "14px",
                },
            },
        },
    },
    followUsIcons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center !important",
    },
};

export default useStyles;
