import Chart from "common/components/Chart/Chart";
import { useNetworkGraphChart } from "hooks/chart/UseNetworkGraphChart";
import React, { useEffect } from "react";
import { NETWORKGRAPH } from "utils/constants";

const SocialNetworkCommentsChart = ({ data }) => {
    const {
        options: optionsNetworkGraphCommentsChart,
        setOptions: setOptionsNetworkGraphCommentsChart,
    } = useNetworkGraphChart("Interacción Usuarios", {
        enabled: true,
        useHTML: true,
        formatter: function () {
            return `
                <div style="min-width: 400px; white-space: normal;">
                    ${this.point.comment}
                </div>
            `
        },
    });

    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            setOptionsNetworkGraphCommentsChart({
                ...optionsNetworkGraphCommentsChart,
                series: [
                    {
                        ...optionsNetworkGraphCommentsChart.series[0],
                        data: data.links,
                        nodes: data.nodes.map(([user, comment]) => ({
                            id: user,
                            comment: comment
                        })),
                    },
                ],
            });
        }
    }, [data]);

    useEffect(() => {
        console.log(optionsNetworkGraphCommentsChart)
    }, [optionsNetworkGraphCommentsChart])

    return <Chart options={optionsNetworkGraphCommentsChart} type={NETWORKGRAPH} />;
};

export default SocialNetworkCommentsChart;
