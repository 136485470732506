import { Grid, Typography } from "@mui/material";
import React from "react";
import PieChart from "../Activities/components/PieChart";
import BubbleChart from "../Activities/components/BubbleChart";
import ColumnsChart from "../Activities/components/ColumnsChart";
import AreaPercentageChart from "../Activities/components/AreaPercentageChart";

const dataPieChartMock = [
    {
        name: "Chrome",
        y: 70.67,
        sliced: true,
        selected: true,
    },
    {
        name: "Edge",
        y: 14.77,
    },
    {
        name: "Firefox",
        y: 4.86,
    },
    {
        name: "Safari",
        y: 2.63,
    },
    {
        name: "Internet Explorer",
        y: 1.53,
    },
    {
        name: "Opera",
        y: 1.4,
    },
    {
        name: "Sogou Explorer",
        y: 0.84,
    },
    {
        name: "QQ",
        y: 0.51,
    },
    {
        name: "Other",
        y: 2.6,
    },
];

const Graphs = () => {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0px",
            }}
        >
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    color="initial"
                    sx={{ textAlign: "center", padding: "0px 0px 25px 0px" }}
                >
                    Entrega de actividad
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <PieChart data={dataPieChartMock}></PieChart>
            </Grid>
            {/* <Grid item xs={12} md={6}>
                <ColumnsChart></ColumnsChart>
            </Grid>
            <Grid item xs={12} md={6}>
                <BubbleChart></BubbleChart>
            </Grid>
            <Grid item xs={12} md={6}>
                <AreaPercentageChart></AreaPercentageChart>
            </Grid> */}
        </Grid>
    );
};

export default Graphs;
