import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RemoteTable from "common/components/RemoteTable/RemoteTable";

const FilterBar = ({
    enableSearch = true,
    labelSearch = "Buscar",
    placeholder = "Buscar",
    onPageChange,
    openLoader = false,
    rowCount = 0,
    columns,
    rows,
    pageSize,
    enableExtraFilter = false,
    extraFilterComponent,
    component,
    extraFilterList = [],
    columnVisibilityModel,
    extraFilterCallback = () => {},
    newEntryConfigButton = {},
}) => {
    const [extraFilter, setExtraFilter] = useState({});
    const [filter, setFilter] = useState("");

    const handleChangeExtraFilter = (evt, key) => {
        const filters = { ...extraFilter, [key]: evt.target.value };
        setExtraFilter(filters);
        if (extraFilterCallback) {
            extraFilterCallback(filters);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            onPageChange(filter, { ...extraFilterComponent, ...extraFilter }, 1, pageSize);
        }, 500);

        return () => clearTimeout(timer);
    }, [filter]);

    useEffect(() => {
        onPageChange(filter, { ...extraFilterComponent, ...extraFilter }, 1, pageSize);
    }, [extraFilter]);

    useEffect(() => {
        setExtraFilter({ ...extraFilter, ...extraFilterComponent });
    }, [extraFilterComponent]);

    return (
        <div>
            {enableSearch && (
                <Grid container spacing={2}>
                    {component && (
                        <Grid item xs={12} lg={8}>
                            {component}
                        </Grid>
                    )}
                    <Grid item xs={12} lg={4}>
                        <TextField
                            placeholder={placeholder}
                            id="outlined-basic"
                            value={filter}
                            label={labelSearch}
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            onChange={(evt) => setFilter(evt.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    {Object.keys(newEntryConfigButton).length > 0 && (
                        <Grid item xs={12} pt={2}>
                            <Button
                                disabled={newEntryConfigButton?.disabled || false}
                                variant="contained"
                                mb={3}
                                onClick={newEntryConfigButton?.onClick || (() => {})}
                            >
                                {newEntryConfigButton?.label || ""}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
            {enableExtraFilter &&
                extraFilterList.map(
                    (ef) =>
                        ef.type === "radio" && (
                            <FormControl key={ef.key}>
                                <FormLabel>Mostrar:</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={extraFilter[ef.key] || ""}
                                    onChange={(e) => handleChangeExtraFilter(e, ef.key)}
                                >
                                    {ef.items.map(
                                        (item, idx) =>
                                            item.show && (
                                                <FormControlLabel
                                                    key={idx}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            )
                                    )}
                                </RadioGroup>
                            </FormControl>
                        )
                )}
            <RemoteTable
                columnVisibilityModel={columnVisibilityModel}
                filter={filter}
                extraFilter={extraFilter}
                rows={rows}
                columns={columns}
                height={500}
                onPageChange={onPageChange}
                openLoader={openLoader}
                rowCount={rowCount}
            />
        </div>
    );
};

FilterBar.propTypes = {
    enableSearch: PropTypes.bool,
    labelSearch: PropTypes.string,
    placeholder: PropTypes.string,
    onPageChange: PropTypes.func,
    openLoader: PropTypes.bool,
    rowCount: PropTypes.number,
    columns: PropTypes.array,
    rows: PropTypes.array,
    pageSize: PropTypes.number,
    enableExtraFilter: PropTypes.bool,
    extraFilterList: PropTypes.array,
    columnVisibilityModel: PropTypes.object,
    extraFilterCallback: PropTypes.func,
    newEntryConfigButton: PropTypes.object,
};

export default FilterBar;
