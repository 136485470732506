import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MAP_COLUMNS } from "utils/constants";

const menu = {
    width: "35px",
    height: "5px",
    backgroundColor: "black",
    margin: "6px 0",
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    padding: grid,
    width: 250,
    border: "1px solid #12416F",
});

const DndComponent = ({ data, callback, refresh }) => {
    const [items, setItems] = useState(data.items);

    const getList = (id) => items[id];

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const itemsReorder = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );
            const itemsUpdated = {
                ...items,
                [source.droppableId]: itemsReorder,
            };
            setItems(itemsUpdated);
            callback(itemsUpdated);
        } else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            const itemsUpdated = {
                ...items,
                ...result,
            };
            setItems(itemsUpdated);
            callback(itemsUpdated);
        }
    };

    useEffect(() => {
        setItems(data.items);
    }, [refresh]);

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {Object.keys(items).map((k) => (
                <Droppable
                    isDropDisabled={k !== "datasetColumns" && items[k].length === data.limitDrag}
                    droppableId={k}
                    key={k}
                >
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                            <Typography variant="p"> {MAP_COLUMNS[k]} </Typography>
                            {items[k].length > 0 &&
                                items[k].map((item, index) => (
                                    <Draggable key={item} draggableId={item} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div
                                                    style={{
                                                        float: "right",
                                                        marginTop: "-9px",
                                                    }}
                                                >
                                                    <div style={menu} />
                                                    <div style={menu} />
                                                    <div style={menu} />
                                                </div>
                                                {item}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            ))}
        </DragDropContext>
    );
};

export default DndComponent;
