import React, { useState } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
} from "@mui/material";
import GenericTable from "common/components/GenericTable/GenericTable";
import BubbleChart from "modules/activity/pages/Activities/components/BubbleChart";
import ColumnsChart from "modules/activity/pages/Activities/components/ColumnsChart";
import LineChart from "modules/activity/pages/Activities/components/LineChart";
import MapChartGrapher from "modules/activity/pages/Activities/components/MapChartGrapher";
import PieChart from "modules/activity/pages/Activities/components/PieChart";
import { exportMultipleChartsToPdf } from "utils/exportPdf";
import SocialNetworkChart from "modules/activity/pages/Activities/components/SocialNetworkChart";
import SocialNetworkCommentsChart from "modules/activity/pages/Activities/components/SocialNetworkCommentsChart";
import StaticNetworkChart from "modules/activity/pages/Activities/components/StaticNetworkChart";
import StaticNetworkBetweennessChart from "modules/activity/pages/Activities/components/StaticNetworkBetweennessChart";
import WordCloudChart from "modules/activity/pages/Activities/components/WordCloudChart";
import QuestionAnswerForum from "modules/activity/pages/Activities/components/QuestionAnswerForum";

const TabResolution = ({
    studentActivityResolutions,
    deliveriesColumns,
    actualGraphResolution,
    handleChangeGraphResolution,
    graphListAvailableResolution,
    graphs,
    dataGraphResolution,
    graphSelectedMapResolution,
    actualResolution,
    getStudentResolutions,
    datasetColumns,
    studentActivity,
    setGraphSelectedMapResolution,
    setDataGraphResolution,
    getStudentActivity,
}) => {
    const [valueGraphTab, setValueGraphTab] = useState(0);

    const mapValueGraphTab = {
        normal: 0,
        betweenness: 1,
    };

    const handleChangeGraphTab = (event, newValue) => {
        setValueGraphTab(newValue);
        // handleChangeTabDataSource(dataSourceMap[newValue]);
    };

    return (
        <div>
            <Grid container justifyContent={"space-around"}>
                <Grid item xs={12} lg={5}>
                    {/* FALTA TRAER LAS ENTREGAS Y SETEAR EL ROWS */}
                    <GenericTable rows={studentActivityResolutions} columns={deliveriesColumns} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <h3>Seleccionar tipo de gráfico</h3>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={actualGraphResolution}
                            onChange={handleChangeGraphResolution}
                        >
                            {graphListAvailableResolution.length > 0 &&
                                graphs
                                    .filter((graph) =>
                                        graphListAvailableResolution.includes(graph.value)
                                    )
                                    .map((graph) => (
                                        <FormControlLabel
                                            key={graph.value}
                                            value={graph.value}
                                            control={<Radio />}
                                            label={graph.name}
                                        />
                                    ))}
                        </RadioGroup>
                    </FormControl>
                    {actualGraphResolution === "pie" && (
                        <PieChart
                            data={dataGraphResolution[actualGraphResolution]}
                            xAxis={
                                graphSelectedMapResolution[actualGraphResolution].columns.value[0]
                            }
                        />
                    )}

                    {actualGraphResolution === "column" && (
                        <ColumnsChart
                            data={dataGraphResolution[actualGraphResolution]}
                            xAxis={
                                graphSelectedMapResolution[actualGraphResolution].columns.value[0]
                            }
                            yAxis={
                                graphSelectedMapResolution[actualGraphResolution].columns
                                    .group_by[0]
                            }
                        />
                    )}

                    {actualGraphResolution === "line" && (
                        <LineChart
                            data={dataGraphResolution[actualGraphResolution]}
                            xAxis={
                                graphSelectedMapResolution[actualGraphResolution].columns.value[0]
                            }
                            yAxis={
                                graphSelectedMapResolution[actualGraphResolution].columns
                                    .group_by[0]
                            }
                        />
                    )}

                    {actualGraphResolution === "social_word_frequency" && (
                        <SocialNetworkChart data={dataGraphResolution[actualGraphResolution]} />
                    )}

                    {actualGraphResolution === "social_user_comments" && (
                        <SocialNetworkCommentsChart
                            data={dataGraphResolution[actualGraphResolution]}
                        />
                    )}

                    {actualGraphResolution === "bubble" && (
                        <BubbleChart
                            data={dataGraphResolution[actualGraphResolution]}
                            xAxis={
                                graphSelectedMapResolution[actualGraphResolution].columns.value[0]
                            }
                            yAxis={
                                graphSelectedMapResolution[actualGraphResolution].columns
                                    .group_by[0]
                            }
                        />
                    )}

                    {actualGraphResolution === "map" && (
                        <MapChartGrapher data={dataGraphResolution[actualGraphResolution]} />
                    )}

                    {actualGraphResolution === "word_cloud" && (
                        <WordCloudChart data={dataGraphResolution[actualGraphResolution]} />
                    )}

                    {actualGraphResolution === "static_network" && (
                        <>
                            <Tabs
                                sx={{ py: "10px" }}
                                value={
                                    mapValueGraphTab[
                                        graphSelectedMapResolution[actualGraphResolution].metric
                                    ]
                                }
                                onChange={handleChangeGraphTab}
                                aria-label="Tabs for different social network metrics"
                            >
                                <Tab
                                    disabled={
                                        mapValueGraphTab[
                                            graphSelectedMapResolution[actualGraphResolution].metric
                                        ] === 1
                                    }
                                    label="Grafo normal"
                                />
                                <Tab
                                    disabled={
                                        mapValueGraphTab[
                                            graphSelectedMapResolution[actualGraphResolution].metric
                                        ] === 0
                                    }
                                    label="Betweenness"
                                />
                            </Tabs>
                            {mapValueGraphTab[
                                graphSelectedMapResolution[actualGraphResolution].metric
                            ] === 0 && (
                                <StaticNetworkChart
                                    data={dataGraphResolution[actualGraphResolution]}
                                />
                            )}
                            {mapValueGraphTab[
                                graphSelectedMapResolution[actualGraphResolution].metric
                            ] === 1 && (
                                <StaticNetworkBetweennessChart
                                    data={dataGraphResolution[actualGraphResolution]}
                                />
                            )}
                        </>
                    )}

                    <Grid container px={10} py={4} gap={1} justifyContent={"center"}>
                        <Button
                            disabled={
                                Object.keys(dataGraphResolution[actualGraphResolution]).length === 0
                            }
                            variant="contained"
                            onClick={() => {
                                exportMultipleChartsToPdf(
                                    Object.entries(graphSelectedMapResolution)
                                        .filter(([key, value]) => value.selected)
                                        .map(([key, value]) => ({
                                            ...value.export,
                                            filterExclude: value.filterExclude,
                                            filterInclude: value.filterInclude,
                                            columns: value.columns,
                                            graph: key,
                                        })),
                                    datasetColumns || [],
                                    studentActivity
                                );
                            }}
                        >
                            Previsualizar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <QuestionAnswerForum
                studentActivity={studentActivity}
                updateData={getStudentActivity}
                editableForum={false}
            />
        </div>
    );
};

export default TabResolution;
