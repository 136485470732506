import React, { useState, useEffect } from "react";
import {
    FormControlLabel,
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Checkbox,
    FormGroup,
    MenuItem,
} from "@mui/material";
import { VISIBILITY } from "utils/constants";
import { ApiService } from "common/services/ApiService";
import { useAuth } from "context/AuthContext";

const DialogContentClone = ({
    activityCloneFormData,
    setActivityCloneFormData,
    selectedActivity,
}) => {
    const { auth } = useAuth()
    const [visibility, setVisibility] = useState(selectedActivity.visibility);
    const [datasets, setDatasets] = useState([])
    const [graphAvailableMap, setGraphAvailableMap] = useState({
        dataTable: false,
        column: false,
        line: false,
        pie: false,
        bubble: false,
        dispersion: false,
        map: false,
        static_network: false,
        word_cloud: false,
    });
    const [actualDataset, setActualDataset] = useState({});

    const { bubble, column, line, pie, map, static_network, word_cloud } = graphAvailableMap;

    const handleChangeVisibility = (event) => {
        setVisibility(event.target.value);
        setActivityCloneFormData({
            ...activityCloneFormData,
            visibility: event.target.value,
        });
    };

    const handleChange = (event) => {
        const newGraphs = {
            ...graphAvailableMap,
            [event.target.name]: event.target.checked,
        };
        setGraphAvailableMap(newGraphs);
        setActivityCloneFormData({
            ...activityCloneFormData,
            graphsData: newGraphs,
        });
    };

    useEffect(() => {
        const graphs = {};
        setActivityCloneFormData({
            ...activityCloneFormData,
            title: selectedActivity.title,
            visibility: selectedActivity.visibility,
            description: selectedActivity.description,
        });
        if (selectedActivity.graphs.length > 0) {
            selectedActivity.graphs.forEach((g) => (graphs[g] = true));
            setGraphAvailableMap(graphs);
        }

        const getDatasetsCloneCreate = async () => {
            const res = await ApiService.getDatasetsCloneCreate({
                establishmet: auth?.selectedEstablishment.id,
                activity: 1,
                clone: true,
                no_page: 1,
            });
            setDatasets(res.data);
        };

        getDatasetsCloneCreate();
    }, []);

    return (
        <>
            <TextField
                autoFocus
                margin="normal"
                id="title"
                fullWidth
                label="Título de la actividad"
                type="text"
                value={selectedActivity.title}
                disabled
            />
            <h5>Enunciado (Esta sección será lo que el estudiante verá en su actividad)</h5>
            <Grid
                container
                sx={{
                    display: "flex",
                    justifyContent: "right",
                }}
            >
                <Grid item xs={11}>
                    <TextField
                        margin="normal"
                        id="statementTitle"
                        fullWidth
                        label="Título del enunciado"
                        type="text"
                        onChange={(evt) => {
                            setActivityCloneFormData({
                                ...activityCloneFormData,
                                statementTitle: evt.target.value,
                            });
                        }}
                    />
                    <TextField
                        margin="normal"
                        id="statement"
                        fullWidth
                        label="Enunciado"
                        type="text"
                        multiline
                        rows={4}
                        maxRows={6}
                        onChange={(evt) => {
                            setActivityCloneFormData({
                                ...activityCloneFormData,
                                statement: evt.target.value,
                            });
                        }}
                    />
                </Grid>
            </Grid>
            <TextField
                margin="normal"
                id="objective"
                fullWidth
                label="Objetivo de la actividad"
                type="text"
                disabled
                value={selectedActivity.objective}
            />
            <TextField
                margin="normal"
                id="description"
                fullWidth
                label="Descripción de la actividad"
                type="text"
                disabled
                value={selectedActivity.description}
            />
            <FormControl>
                <FormLabel id="visilibty">Visibilidad</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={visibility}
                    onChange={handleChangeVisibility}
                >
                    <FormControlLabel
                        value={VISIBILITY.public}
                        control={<Radio />}
                        label="Establecimiento"
                    />
                    <FormControlLabel
                        value={VISIBILITY.private}
                        control={<Radio />}
                        label="Privado"
                    />
                    <FormControlLabel
                        value={VISIBILITY.community}
                        control={<Radio />}
                        label="Comunidad"
                    />
                </RadioGroup>
            </FormControl>
            <TextField
                fullWidth
                id="subject"
                select
                label="Dataset"
                // value={activityFormData.course}
                onChange={(evt) => {
                    setActualDataset(evt.target.value);
                    setActivityCloneFormData({
                        ...activityCloneFormData,
                        static_data_source: evt.target.value.id,
                    });
                }}
                margin="normal"
            >
                {datasets.map((dataset) => (
                    <MenuItem key={dataset.id} value={dataset}>
                        {dataset.name}
                    </MenuItem>
                ))}
            </TextField>
            <FormControl>
                <FormLabel component="legend">Gráficos permitidos</FormLabel>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={
                            <Checkbox checked={column} onChange={handleChange} name="column" />
                        }
                        label="Columnas"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={<Checkbox checked={line} onChange={handleChange} name="line" />}
                        label="Línea"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={<Checkbox checked={pie} onChange={handleChange} name="pie" />}
                        label="Torta"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={
                            <Checkbox checked={bubble} onChange={handleChange} name="bubble" />
                        }
                        label="Burbujas"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={<Checkbox checked={map} onChange={handleChange} name="map" />}
                        label="Mapa"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={
                            <Checkbox
                                checked={static_network}
                                onChange={handleChange}
                                name="static_network"
                            />
                        }
                        label="Grafo"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={!(actualDataset?.name || "").includes("pdf")}
                                checked={word_cloud}
                                onChange={handleChange}
                                name="word_cloud"
                            />
                        }
                        label="Nube de palabras"
                    />
                </FormGroup>
            </FormControl>
        </>
    );
};

export default DialogContentClone;
