import Chart from "common/components/Chart/Chart";
import { useLineChart } from "hooks/chart/UseLineChart";
import React, { useEffect } from "react";
import { MOREGRAPH } from "utils/constants";

const LineChart = ({ data, xAxis, yAxis }) => {
    const { options: optionsLineChart, setOptions: setOptionsLineChart } = useLineChart();

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const dataFiltered = [];

            data.value_categories.forEach((categorie) => {
                dataFiltered.push({
                    name: categorie,
                    data: data.data
                        .filter((obj) => obj[`group_by_${xAxis}_${yAxis}`][xAxis] === categorie)
                        .map((obj) => obj.count),
                });
            });

            setOptionsLineChart({
                ...optionsLineChart,
                xAxis: {
                    ...optionsLineChart.xAxis,
                    categories: data.group_by_categories,
                },
                series: dataFiltered,
            });
        }
    }, [data]);

    return <Chart options={optionsLineChart} type={MOREGRAPH} />;
};

export default LineChart;
