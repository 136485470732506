import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    TextField,
    TextareaAutosize,
    Tooltip,
    Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "common/services/ApiService";
import { useAuth } from "context/AuthContext";
import moment from "moment";
import { camelizeObject, toCamelCase } from "helpers/TextHelpers";
import { QUALIFICATION } from "utils/constants";
import TabResolution from "modules/activity/pages/Activities/components/TabResolution";
import TabNewResolution from "modules/activity/pages/Activities/components/TabNewResolution";
import TabPanelComponent from "common/components/TabPanel/TabPanelComponent";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import ImageSlider from "common/components/ImageSlider/ImageSlider";
import BackButton from "common/components/BackButton/BackButton";

const API_GRAPHER = {
    dataTable: ApiService.getDataGraphBasic,
    column: ApiService.getDataGraphColumn,
    line: ApiService.getDataGraphColumn,
    pie: ApiService.getDataGraphBasic,
    bubble: ApiService.getDataGraphColumn,
    dispersion: ApiService.getDataGraphColumn,
    map: ApiService.getDataGraphMap,
    social_word_frequency: ApiService.getRedditGraph,
    social_user_comments: ApiService.getRedditGraphUserComments,
    static_network: ApiService.getDataGraph,
    word_cloud: ApiService.getDataWordCloud,
};

const Grapher = () => {
    const { id } = useParams();
    const { auth } = useAuth();
    const [datasetColumns, setDatasetColumns] = useState([]);
    const [dataSource, setDataSource] = useState("static");
    const [graphListAvailable, setGraphListAvailable] = useState([]);
    const [graphListAvailableResolution, setGraphListAvailableResolution] =
        useState([]);
    const [studentActivity, setStudentActivity] = useState();
    const [valueTab, setValueTab] = useState(0);

    const [openLoader, setOpenLoader] = useState(false);
    const [openCorrectionModal, setOpenCorrectionModal] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [acutalFeedbackOpened, setActualFeedbackOpened] = useState();
    const [correctionStatus, setCorrectionStatus] = useState({
        status: null,
        observation: "",
    });

    const [openTutorialModal, setOpenTutorialModal] = useState(false);

    const [dataGraphResolution, setDataGraphResolution] = useState({
        dataTable: {},
        column: {},
        line: {},
        pie: {},
        bubble: {},
        dispersion: {},
        map: {},
        social_user_comments: {},
        social_word_frequency: {},
        static_network: {},
    });

    const [includeChipList, setIncludeChipList] = useState([]);
    const [excludeChipList, setExcludeChipList] = useState([]);

    const criterionMapList = {
        include: [includeChipList, setIncludeChipList],
        exclude: [excludeChipList, setExcludeChipList],
    };

    const defaultData = {
        dataTable: {
            selected: false,
            columns: { value: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 1,
            dataSource: "static",
        },
        column: {
            selected: false,
            columns: { value: [], group_by: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 1,
            dataSource: "static",
        },
        line: {
            selected: false,
            columns: { value: [], group_by: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 1,
            dataSource: "static",
        },
        pie: {
            selected: false,
            columns: { value: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 1,
            dataSource: "static",
        },
        bubble: {
            selected: false,
            columns: { value: [], group_by: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 1,
            dataSource: "static",
        },
        dispersion: {
            selected: false,
            columns: { value: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 1,
            dataSource: "static",
        },
        social_word_frequency: {
            selected: false,
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            dataSource: "dynamic",
        },
        social_user_comments: {
            selected: false,
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            dataSource: "dynamic",
        },
        map: {
            selected: false,
            columns: { value: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 2,
            dataSource: "static",
        },
        static_network: {
            selected: false,
            columns: { value: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 2,
            dataSource: "static",
            metric: "normal",
        },
        word_cloud: {
            selected: false,
            columns: { value: [] },
            export: { image: null, offsetHeight: null, offsetWidth: null },
            filterInclude: [],
            filterExclude: [],
            limitDrag: 2,
            dataSource: "static",
        },
    };

    const mapValueGraphTab = {
        normal: 0,
        betweenness: 1,
    };

    const [graphSelectedMap, setGraphSelectedMap] = useState(defaultData);

    const [graphSelectedMapResolution, setGraphSelectedMapResolution] =
        useState(defaultData);

    const [studentActivityResolutions, setStudentActivityResolutions] =
        useState([]);
    const [actualResolution, setActualResolution] = useState({});

    const mapGraphSelectedMapTab = {
        0: graphSelectedMapResolution,
        1: graphSelectedMap,
    };

    const setConfigurationResolutions = async (dataGraph) => {
        let dataGraphResolutionAux = { ...dataGraphResolution };
        const dataGraphCamelized = camelizeObject(dataGraph);

        setActualResolution(dataGraphCamelized);
        setActualFeedbackOpened(dataGraphCamelized);

        if (dataGraphCamelized.partialDeliveries.length > 0) {
            const graphs = [];
            const storedData = dataGraphCamelized.partialDeliveries[0].data;

            await Promise.all(
                dataGraphCamelized.partialDeliveries[0].graphs.map(
                    async (graph) => {
                        const projectHeadersMap = {};

                        if (
                            graph.name !== "word_cloud" &&
                            storedData[graph.name]?.columns?.value?.length > 0
                        ) {
                            storedData[graph.name].columns.value.forEach(
                                (h) => {
                                    projectHeadersMap[h] = 1;
                                }
                            );
                        }

                        const includeHeadersMap = {};
                        const excludeHeadersMap = {};

                        let params = {};

                        storedData[graph.name].filterInclude.forEach((obj) => {
                            if (!(obj.column in includeHeadersMap)) {
                                includeHeadersMap[obj.column] = [];
                            }
                            includeHeadersMap[obj.column].push(obj.valueFilter);
                        });
                        storedData[graph.name].filterExclude.forEach((obj) => {
                            if (!(obj.column in excludeHeadersMap)) {
                                excludeHeadersMap[obj.column] = [];
                            }
                            excludeHeadersMap[obj.column].push(obj.valueFilter);
                        });

                        if (!graph.name.includes("social")) {
                            params = {
                                graph: graph.name,
                                project_headers_map: projectHeadersMap,
                                columns: storedData[graph.name].columns,
                                include_headers_map: includeHeadersMap,
                                exclude_headers_map: excludeHeadersMap,
                            };
                        } else {
                            params = {
                                frequency: storedData[graph.name].frequency,
                                word: storedData[graph.name].word,
                            };
                        }

                        let api = API_GRAPHER[graph.name];

                        if (
                            graph.name === "static_network" &&
                            mapValueGraphTab[storedData[graph.name].metric] ===
                                1
                        ) {
                            api = ApiService.getDataGraphBetweenness;
                        }

                        const resultDataGraph = await api(params, id);

                        dataGraphResolutionAux = {
                            ...dataGraphResolutionAux,
                            [graph.name]: resultDataGraph.data,
                        };
                        graphs.push(graph.name);
                    }
                )
            );
            setActualGraphResolution(graphs[0]);
            setGraphListAvailableResolution(graphs);
            setGraphSelectedMapResolution(storedData);
            setDataGraphResolution(dataGraphResolutionAux);
        }
    };

    const getStudentResolutions = async () => {
        const result = await ApiService.getStudentActivityResolutions({
            student_activity: id,
        });

        if (result.data.length > 0) {
            const sarSortedList = result.data
                .map((d) => ({
                    deliveryDate: moment(d.delivery_date).format("DD/MM/YYYY"),
                    partialDeliveries: d.partial_deliveries,
                    id: d.id,
                    feedback: d.feedback,
                }))
                .sort(
                    (a, b) =>
                        moment(b.deliveryDate, "DD/MM/YYYY") -
                        moment(a.deliveryDate, "DD/MM/YYYY")
                );
            const existCorrection = [
                QUALIFICATION.approved,
                QUALIFICATION.disapproved,
                QUALIFICATION.reDelivery,
            ].includes(sarSortedList[0]?.feedback?.qualification);

            setConfigurationResolutions(sarSortedList[0]);
            setStudentActivityResolutions(sarSortedList);
            setCorrectionStatus({
                status: existCorrection ? sarSortedList[0]?.feedback?.qualification : null,
                observation: existCorrection ? sarSortedList[0]?.feedback?.observation : "",
            });
            return;
        }
        if (auth?.selectedRole?.name === "student") setValueTab(1);
    };

    const graphs = [
        { name: "Tabla de datos", value: "dataTable", dataSource: "static" },
        { name: "Columnas", value: "column", dataSource: "static" },
        { name: "Lineas", value: "line", dataSource: "static" },
        { name: "Torta", value: "pie", dataSource: "static" },
        { name: "Burbujas", value: "bubble", dataSource: "static" },
        { name: "Dispersión", value: "dispersion", dataSource: "static" },
        { name: "Mapa", value: "map", dataSource: "static" },
        {
            name: "Grafo social (Interaccion Usuarios)",
            value: "social_user_comments",
            dataSource: "dynamic",
        },
        {
            name: "Grafo social (Ocurrecias)",
            value: "social_word_frequency",
            dataSource: "dynamic",
        },
        { name: "Grafo", value: "static_network", dataSource: "static" },
        { name: "Nube de palabras", value: "word_cloud", dataSource: "static" },
    ];
    const [actualGraph, setActualGraph] = useState("pie");
    const [actualGraphResolution, setActualGraphResolution] = useState("pie");

    const handleChangeGraphResolution = (event) => {
        setActualGraphResolution(event.target.value);
    };

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
        setDataSource("static");
    };

    const qualify = async (qualification) => {
        const data = {
            qualification: qualification?.status,
            observation: qualification?.observation,
        };
        return await ApiService.qualify(data, id);
    };

    const onDeleteFilterApplied = (obj, criterion) => {
        const criterionMapFilter = {
            include: "filterInclude",
            exclude: "filterExclude",
        };

        const criterionFilter = criterionMapFilter[criterion];
        const [criterionList, setCriterionList] = criterionMapList[criterion];
        const criterionListFiltered = criterionList.filter(
            (crit) => crit.valueChip !== obj.valueChip
        );
        setCriterionList(criterionListFiltered);
        setGraphSelectedMap({
            ...graphSelectedMap,
            [actualGraph]: {
                ...graphSelectedMap[actualGraph],
                [criterionFilter]: criterionListFiltered,
            },
        });
    };

    const getStudentActivity = async () => {
        const result = await ApiService.getStudentActivity(id);
        const activity = result.data.course_activity.activity;
        const headers = activity.static_data_source.headers?.split("|");
        setGraphListAvailable(activity.graphs);
        setStudentActivity(result.data);
        setDatasetColumns(headers);
        setActualGraph(activity.graphs[0]);
    };

    useEffect(() => {
        if (id) {
            getStudentActivity();
        }
    }, []);

    useEffect(() => {
        if (valueTab === 0) {
            setOpenLoader(true);
            getStudentResolutions();
            setOpenLoader(false);
        }
    }, [valueTab]);

    const handleClickShowDeliveryButton = async (row) => {
        setOpenLoader(true);
        setDataGraphResolution({
            dataTable: {},
            column: {},
            line: {},
            pie: {},
            bubble: {},
            dispersion: {},
            map: {},
            social_user_comments: {},
            social_word_frequency: {},
            static_network: {},
        });
        setActualResolution(row);
        await setConfigurationResolutions(row);
        setOpenLoader(false);
    };

    const deliveriesColumns = [
        { field: "deliveryDate", headerName: "Fecha", flex: 0.7 },
        {
            field: "feedback",
            headerName: "Nota",
            flex: 1,
            hideable: true,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        disabled={!params.row.feedback}
                        onClick={() => {
                            setActualFeedbackOpened(params.row);
                            setOpenFeedbackModal(true);
                        }}
                    >
                        Ver Nota
                    </Button>
                );
            },
        },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            hideable: true,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        disabled={params.row.id === actualResolution.id}
                        onClick={() => {
                            handleClickShowDeliveryButton(params.row);
                        }}
                    >
                        Ver entrega
                    </Button>
                );
            },
        },
    ];

    const saveCorrection = () => {
        setOpenConfirmationDialog(true);
    };

    const handleSaveCorrection = async () => {
        setOpenConfirmationDialog(false);
        setOpenLoader(true);
        const res = await qualify(correctionStatus);
        if (res.status === 200) {
            setOpenCorrectionModal(false);
            setOpenLoader(true);
            getStudentResolutions();
            setOpenLoader(false);
        }
        setOpenLoader(false);
    };
    const handleOpenTutorial = (type) => {
        if (type === "static") {
            console.log("Tutorial estatico");
        } else if (type === "dynamic") {
            console.log("Tutorial estatico");
        }
        setOpenTutorialModal(true);
    };

    return (
        <Box sx={{ width: "100%", paddingLeft: 5, paddingRight: 10 }}>
            <Box style={{ padding: "10px 0px 0px 0px" }}>
                <BackButton />
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab
                        disabled={studentActivityResolutions.length === 0}
                        label="Entregas realizadas"
                    />
                    {auth.selectedRole?.name === "student" && <Tab label="Nueva entrega" />}
                </Tabs>
            </Box>

            {Object.keys(graphSelectedMap).length > 0 && (
                <div
                    style={{
                        float: "right",
                        width: "200px",
                        height: "200px",
                        marginLeft: "10px",
                        right: 0,
                        top: "20%",
                        position: "fixed",
                    }}
                >
                    <div>
                        <h4> Gráficos Agregados</h4>
                        <List>
                            {Object.entries(graphSelectedMap).map(
                                ([key, el]) =>
                                    el.selected && (
                                        <ListItem key={key} disablePadding>
                                            -{" "}
                                            <ListItemText
                                                primary={graphs.find((g) => g.value === key).name}
                                            />
                                        </ListItem>
                                    )
                            )}
                        </List>
                        {dataSource === "static" && (
                            <div>
                                <h4>
                                    {`Filtros Aplicados-${
                                        graphs.find(
                                            (g) =>
                                                g.value ===
                                                (valueTab === 1
                                                    ? actualGraph
                                                    : actualGraphResolution)
                                        ).name
                                    }`}
                                </h4>
                                <List>
                                    {mapGraphSelectedMapTab[valueTab][
                                        valueTab === 1 ? actualGraph : actualGraphResolution
                                    ]?.filterInclude.map((obj, idx) => (
                                        <ListItem
                                            secondaryAction={
                                                valueTab === 1 ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            onDeleteFilterApplied(obj, "include");
                                                        }}
                                                        edge="start"
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            key={idx}
                                            disablePadding
                                        >
                                            <ListItemText primary={`- ${obj.label} (Incluir)`} />
                                        </ListItem>
                                    ))}
                                    {mapGraphSelectedMapTab[valueTab][
                                        valueTab === 1 ? actualGraph : actualGraphResolution
                                    ]?.filterExclude.map((obj, idx) => (
                                        <ListItem
                                            secondaryAction={
                                                valueTab === 1 ? (
                                                    <IconButton
                                                        onClick={() =>
                                                            onDeleteFilterApplied(obj, "exclude")
                                                        }
                                                        edge="start"
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            key={idx}
                                            disablePadding
                                        >
                                            <ListItemText primary={`- ${obj.label} (Excluir)`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <Grid container>
                <Grid item xs={12} md={4}>
                    <u>
                        <h2>Nombre Dataset:</h2>
                    </u>
                    <h4>{studentActivity?.course_activity?.activity?.static_data_source?.name}</h4>
                </Grid>
                <Grid item xs={12} md={4}>
                    <u>
                        <h2>Nombre Actividad:</h2>
                    </u>
                    <h4>{studentActivity?.course_activity?.activity?.title}</h4>
                </Grid>

                <Grid item xs={12} md={4}>
                    {auth?.selectedRole.name === "teacher" &&
                        studentActivityResolutions.length > 0 && (
                            <div>
                                <u>
                                    <h2>Acciones:</h2>
                                </u>
                                {/* <Button
                                    disabled={studentActivity?.disable_dalivery}
                                    variant="contained"
                                    onClick={() =>
                                        qualify(QUALIFICATION.approved)
                                    }
                                >
                                    Aprobar
                                </Button>
                                <Button
                                    disabled={studentActivity?.disable_dalivery}
                                    variant="contained"
                                    onClick={() =>
                                        qualify(QUALIFICATION.disapproved)
                                    }
                                >
                                    Desaprobar
                                </Button>
                                <Button
                                    disabled={studentActivity?.disable_dalivery}
                                    variant="contained"
                                    onClick={() =>
                                        qualify(QUALIFICATION.reDelivery)
                                    }
                                >
                                    Reentregar
                                </Button> */}

                                <Button
                                    variant="contained"
                                    onClick={() => setOpenCorrectionModal(true)}
                                >
                                    {[
                                        QUALIFICATION.approved,
                                        QUALIFICATION.disapproved,
                                        QUALIFICATION.reDelivery,
                                    ].includes(
                                        studentActivityResolutions[0]?.feedback?.qualification
                                    )
                                        ? "Editar Correccion"
                                        : "Corregir"}
                                </Button>
                            </div>
                        )}
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={12}>
                    <u>
                        <h2>Enunciado:</h2>
                    </u>
                    <h4>{studentActivity?.course_activity?.activity?.statement_title}</h4>
                </Grid>
            </Grid>

            <TabPanelComponent value={valueTab} index={0}>
                <TabResolution
                    studentActivityResolutions={studentActivityResolutions}
                    deliveriesColumns={deliveriesColumns}
                    actualGraphResolution={actualGraphResolution}
                    handleChangeGraphResolution={handleChangeGraphResolution}
                    graphListAvailableResolution={graphListAvailableResolution}
                    graphs={graphs}
                    dataGraphResolution={dataGraphResolution}
                    graphSelectedMapResolution={graphSelectedMapResolution}
                    setGraphSelectedMapResolution={setGraphSelectedMapResolution}
                    setDataGraphResolution={setDataGraphResolution}
                    actualResolution={actualResolution}
                    getStudentResolutions={getStudentResolutions}
                    datasetColumns={datasetColumns}
                    studentActivity={studentActivity}
                    getStudentActivity={getStudentActivity}
                />
            </TabPanelComponent>
            <TabPanelComponent value={valueTab} index={1}>
                <TabNewResolution
                    datasetColumns={datasetColumns}
                    graphSelectedMap={graphSelectedMap}
                    setGraphSelectedMap={setGraphSelectedMap}
                    includeChipList={includeChipList}
                    excludeChipList={excludeChipList}
                    setIncludeChipList={setIncludeChipList}
                    setExcludeChipList={setExcludeChipList}
                    studentActivityId={id}
                    graphListAvailable={graphListAvailable}
                    graphs={graphs}
                    studentActivity={studentActivity}
                    actualGraph={actualGraph}
                    getStudentActivity={getStudentActivity}
                    setActualGraph={setActualGraph}
                    handleChangeTabDataSource={(dataSourceType) => {
                        setDataSource(dataSourceType);
                    }}
                    callbackHandleChangeTabPanel={handleChangeTab}
                    handleOpenTutorial={handleOpenTutorial}
                />
            </TabPanelComponent>
            {openCorrectionModal && (
                <GenericDialog
                    title="Corrección de entrega"
                    open={openCorrectionModal}
                    setOpen={setOpenCorrectionModal}
                    handleSave={() => saveCorrection()}
                    onClose={() => {
                        setOpenCorrectionModal(false);
                        setOpenLoader(true);
                        getStudentResolutions();
                        setOpenLoader(false);
                    }}
                >
                    <DialogContent>
                        <Grid container gap={3}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                        Calificación
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={correctionStatus.status}
                                        onChange={(evt) =>
                                            setCorrectionStatus({
                                                ...correctionStatus,
                                                status: evt.target.value,
                                            })
                                        }
                                    >
                                        <FormControlLabel
                                            value={QUALIFICATION.approved}
                                            control={<Radio />}
                                            label="Aprobado"
                                        />
                                        <FormControlLabel
                                            value={QUALIFICATION.disapproved}
                                            control={<Radio />}
                                            label="Desaprobado"
                                        />
                                        <FormControlLabel
                                            value={QUALIFICATION.reDelivery}
                                            control={<Radio />}
                                            label="Reentrega"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Comentarios de entrega"
                                    multiline
                                    minRows={7}
                                    maxRows={10}
                                    placeholder="Escriba un comentario.."
                                    value={correctionStatus.observation}
                                    onChange={(evt) => {
                                        setCorrectionStatus({
                                            ...correctionStatus,
                                            observation: evt.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </GenericDialog>
            )}

            {openFeedbackModal && (
                <GenericDialog
                    title="Corrección de entrega"
                    open={openFeedbackModal}
                    setOpen={setOpenFeedbackModal}
                    cancelButton={null}
                    saveButton={null}
                >
                    <DialogContent>
                        <Grid container gap={3}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                        Calificación
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={acutalFeedbackOpened?.feedback?.qualification}
                                    >
                                        <FormControlLabel
                                            value={QUALIFICATION.approved}
                                            control={<Radio />}
                                            label="Aprobado"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value={QUALIFICATION.disapproved}
                                            control={<Radio />}
                                            label="Desaprobado"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value={QUALIFICATION.reDelivery}
                                            control={<Radio />}
                                            label="Reentrega"
                                            disabled
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Comentarios de entrega"
                                    multiline
                                    minRows={7}
                                    maxRows={10}
                                    placeholder="Escriba un comentario.."
                                    disabled
                                    value={acutalFeedbackOpened?.feedback?.observation}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </GenericDialog>
            )}

            {openTutorialModal && (
                <GenericDialog
                    title="Tutorial de graficador"
                    open={openTutorialModal}
                    setOpen={setOpenTutorialModal}
                    saveButton={false}
                    cancelButton={false}
                >
                    <ImageSlider
                        images={[
                            "learn-more1.png",
                            "learn-more2.png",
                            "learn-more3.png",
                            "learn-more4.png",
                        ]}
                    ></ImageSlider>
                </GenericDialog>
            )}
            {openConfirmationDialog && (
                <GenericDialog
                    handleSave={() => handleSaveCorrection()}
                    open={openConfirmationDialog}
                    setOpen={setOpenConfirmationDialog}
                    title={"Confirmación"}
                >
                    <Typography variant="p" px={3}>
                        Está seguro que desea guardar esta información?
                    </Typography>
                </GenericDialog>
            )}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 999999999,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default Grapher;
