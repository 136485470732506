import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    containerTextField: {
        marginTop: "10px",
        width: "50%",
    },
}));

export default useStyles;
