import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import useStyles, { stylesSx } from "./styles";
import { useAuth } from "context/AuthContext";
import AuthFormDialog from "../AuthFormDialog/AuthFormDialog";
import NotLoggedUserMenu from "./components/NotLoggedUserMenu";
import {
    superAdminSettings,
    userSettings,
    adminSettings,
    teacherSettings,
} from "./utils/constants";
import LoggedUserMenu from "./components/LoggedUserMenu";
import LoggedUserMenuMobile from "./components/LoggedUserMenuMobile";
import LogoutConfirmationDialog from "./components/LogoutConfirmationDialog";
import { ApiService } from "common/services/ApiService";
import queryString from "query-string";
import { Dialog } from "@mui/material";
import EstablishmentSelectionModal from "common/components/AuthFormDialog/components/EstablishmentSelectionModal";

export default function NavBar() {
    const { auth, setAuth } = useAuth();
    const [settings, setSettings] = useState([]);
    const [open, setOpen] = useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [openLogInForm, setOpenLogInForm] = useState(false);
    const [openSignInForm, setOpenSignInForm] = useState(false);
    const [pendingNotificationIntervalId, setPendingNotificationIntervalId] =
        useState();
    const [pendingNotification, setPendingNotification] = useState({
        superadmin: false,
        admin: false,
    });
    const [openEstablishmentModal, setOpenEstablishmentModal] = useState(false);

    const [loggedByGoogle, setLoggedByGoogle] = useState(false);

    const [anchorElements, setAnchorElements] = useState([
        { name: "datasets", value: null },
        { name: "grafos", value: null },
        { name: "establishments", value: null },
        { name: "roles", value: null },
    ]);

    const { boxFlexStyle, appBarStyle } = stylesSx;

    const location = useLocation();

    const classes = useStyles();

    const handleOpenNavMenu = (event) => {
        if (anchorElNav === null) {
            setAnchorElNav(event.currentTarget);
        } else {
            setAnchorElNav(null);
        }
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenMenu = (event, menuName) => {
        const element = anchorElements.find(
            (objeto) => objeto.name === menuName
        );

        if (element) {
            element.value = event.currentTarget;
            setAnchorElements([...anchorElements]);
        }
    };
    const handleCloseMenu = (menuName) => {
        const element = anchorElements.find(
            (objeto) => objeto.name === menuName
        );

        if (element) {
            element.value = null;
            setAnchorElements([...anchorElements]);
        }
    };

    const getConfigurationAuthMe = async (access) => {
        localStorage.setItem("access", access);
        const dataUser = await ApiService.me(access);
        const pendingNotification = {
            superadmin: {
                user: false,
            },
            admin: {
                user: false,
                course: false,
            },
        };

        if (dataUser.status === 200) {
            localStorage.setItem(
                "rolesEstablishment",
                JSON.stringify(dataUser.data.role_establishment_list)
            );
            localStorage.setItem("userName", dataUser.data.firstName);
            localStorage.setItem("userId", dataUser.data.id);

            let selectedRole = null;
            if (dataUser.data.roles.includes(4)) {
                selectedRole = { name: "superadmin" };
            }

            localStorage.setItem("selectedRole", JSON.stringify(selectedRole));

            localStorage.setItem(
                "pendingNotification",
                JSON.stringify(pendingNotification)
            );

            setAuth({
                access,
                rolesEstablishment: dataUser.data.role_establishment_list,
                userName: dataUser.data.firstName,
                selectedRole: selectedRole,
                pendingNotification,
                userId: dataUser.data.id,
            });
            return dataUser;
        }
        return;
    };

    const cleanUrl = () => {
        const currentUrl = new URL(
            window.location.origin + location.pathname + location.search
        );

        const allowedParams = ["access_token", "refresh_token"];
        for (let param of Array.from(currentUrl.searchParams.keys())) {
            if (!allowedParams.includes(param)) {
                currentUrl.searchParams.delete(param);
            }
        }

        window.history.replaceState(null, "", currentUrl.toString());
    };

    // useEffect google --- seguir ---
    useEffect(() => {
        const values = queryString.parse(location.search);
        const { access_token, new_user, roles_pending, complete_data } = values;

        if (access_token) {
            setLoggedByGoogle(true);

            if (new_user === "True") {
                localStorage.setItem("access", access_token);
                localStorage.setItem("rolesEstablishment", JSON.stringify([]));
                setAuth({
                    ...auth,
                    access: access_token,
                    rolesEstablishment: [],
                });
                setOpenSignInForm(true);
            } else {
                if (roles_pending === "False" && complete_data === "False") {
                    localStorage.setItem("access", access_token);
                    localStorage.setItem(
                        "rolesEstablishment",
                        JSON.stringify([])
                    );
                    setAuth({
                        ...auth,
                        access: access_token,
                        rolesEstablishment: [],
                    });
                    setOpenSignInForm(true);
                    return;
                }
                getConfigurationAuthMe(access_token);
                setOpenEstablishmentModal(true);
                cleanUrl();
            }
        }
    }, [location]);

    useEffect(() => {
        const setMenuSettings = () => {
            if (
                auth.rolesEstablishment !== undefined &&
                auth.rolesEstablishment.length > 0
            ) {
                let selectedRole = JSON.parse(
                    localStorage.getItem("selectedRole")
                );
                if (selectedRole) {
                    if (selectedRole.name === "admin") {
                        setSettings(adminSettings);
                    } else if (selectedRole.name === "teacher") {
                        setSettings(teacherSettings);
                    } else if (selectedRole.name === "superadmin") {
                        setSettings(superAdminSettings);
                    }
                    return;
                }
            }
            setSettings(userSettings);
        };
        setMenuSettings();
    }, [auth.rolesEstablishment, auth.selectedRole]);

    const checkPendingNotification = async (role, authUpdated = {}) => {
        const res = await ApiService.pendingNotification();
        const pendingNotificationMap = { ...auth.pendingNotification };

        let pendingNotificationAux = { ...pendingNotification };

        Object.entries(res.data.pending_notification_list).forEach(
            ([key, value]) => {
                if (pendingNotificationMap[role])
                    if (value && key in pendingNotificationMap[role]) {
                        pendingNotificationMap[role] = {
                            ...pendingNotificationMap[role],
                            [key]: value,
                        };
                        pendingNotificationAux = {
                            ...pendingNotificationAux,
                            [role]: value,
                        };
                    }
            }
        );

        setAuth({
            ...auth,
            ...authUpdated,
            pendingNotification: pendingNotificationMap,
        });

        setPendingNotification(pendingNotificationAux);
        localStorage.setItem(
            "pendingNotification",
            JSON.stringify(pendingNotificationMap)
        );
    };

    return (
        <Box sx={boxFlexStyle}>
            <AppBar position="fixed" sx={appBarStyle}>
                <Toolbar>
                    <Typography
                        variant="p"
                        noWrap
                        component="div"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        <Link to="/" className={classes.linkNavBar}>
                            <img
                                src="/img/logoAd.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </Link>
                    </Typography>
                    {auth !== "" ? (
                        <LoggedUserMenuMobile
                            anchorElNav={anchorElNav}
                            handleOpenNavMenu={handleOpenNavMenu}
                            handleCloseNavMenu={handleCloseNavMenu}
                            handleOpenMenu={handleOpenMenu}
                            handleCloseMenu={handleCloseMenu}
                            anchorElements={anchorElements}
                            setOpen={setOpen}
                            settings={settings}
                            setSettings={setSettings}
                        />
                    ) : (
                        ""
                    )}
                    <Typography
                        variant="p"
                        noWrap
                        component="div"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                            justifyContent: "center",
                        }}
                    >
                        <Link to="/" className={classes.linkNavBar}>
                            <img
                                src="/img/logoAd.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </Link>
                    </Typography>
                    {auth !== "" ? (
                        <LoggedUserMenu
                            anchorElements={anchorElements}
                            setAnchorElements={setAnchorElements}
                            handleCloseMenu={handleCloseMenu}
                            handleOpenMenu={handleOpenMenu}
                            setOpen={setOpen}
                            userName={auth.userName}
                            settings={settings}
                            setSettings={setSettings}
                            checkPendingNotification={checkPendingNotification}
                            pendingNotificationIntervalId={
                                pendingNotificationIntervalId
                            }
                            setPendingNotificationIntervalId={
                                setPendingNotificationIntervalId
                            }
                            pendingNotification={pendingNotification}
                        />
                    ) : (
                        <NotLoggedUserMenu
                            setOpenLogInForm={setOpenLogInForm}
                            setOpenSignInForm={setOpenSignInForm}
                        />
                    )}
                </Toolbar>
            </AppBar>

            <AuthFormDialog
                getConfigurationAuthMe={(access) =>
                    getConfigurationAuthMe(access)
                }
                type={"login"}
                open={openLogInForm}
                setOpen={setOpenLogInForm}
                handleChangeForms={() => {
                    setOpenLogInForm(false);
                    setOpenSignInForm(true);
                }}
                checkPendingNotification={checkPendingNotification}
                pendingNotificationIntervalId={pendingNotificationIntervalId}
                setPendingNotificationIntervalId={
                    setPendingNotificationIntervalId
                }
                loggedByGoogle={loggedByGoogle}
            />
            <AuthFormDialog
                getConfigurationAuthMe={(access) =>
                    getConfigurationAuthMe(access)
                }
                type={"signin"}
                open={openSignInForm}
                setOpen={setOpenSignInForm}
                handleChangeForms={() => {
                    setOpenSignInForm(false);
                    setOpenLogInForm(true);
                }}
                checkPendingNotification={checkPendingNotification}
                pendingNotificationIntervalId={pendingNotificationIntervalId}
                setPendingNotificationIntervalId={
                    setPendingNotificationIntervalId
                }
                loggedByGoogle={loggedByGoogle}
            />

            <LogoutConfirmationDialog
                setAuth={setAuth}
                setOpen={setOpen}
                open={open}
            />

            {openEstablishmentModal && (
                <Dialog
                    open={openEstablishmentModal}
                    // ESTE ONCLOSE NO VA
                    onClose={() => setOpenEstablishmentModal(false)}
                >
                    <EstablishmentSelectionModal
                        setOpen={setOpenEstablishmentModal}
                        checkPendingNotification={checkPendingNotification}
                        pendingNotificationIntervalId={
                            pendingNotificationIntervalId
                        }
                        setPendingNotificationIntervalId={
                            setPendingNotificationIntervalId
                        }
                    ></EstablishmentSelectionModal>
                </Dialog>
            )}
        </Box>
    );
}
