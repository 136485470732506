import { useState, React } from "react";

import { ApiService } from "common/services/ApiService";
import { useAuth } from "../../../context/AuthContext";

import { Alert, Backdrop, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import LogInForm from "./components/LogInForm";
import SignUpForm from "./components/SignUpForm";
import { snakeCaseObject } from "helpers/TextHelpers";
import EstablishmentSelectionModal from "./components/EstablishmentSelectionModal";
import GenericAlert from "../GenericAlert/GenericAlert";
import { zip } from "lodash";
import { useLocation } from "react-router-dom";

export default function AuthFormDialog({
    type,
    open,
    setOpen,
    handleChangeForms,
    checkPendingNotification,
    pendingNotificationIntervalId,
    setPendingNotificationIntervalId,
    loggedByGoogle = false,
    getConfigurationAuthMe,
}) {
    const { auth, setAuth } = useAuth();
    const location = useLocation();

    const [logInFormData, setLogInFormData] = useState({
        email: "",
        password: "",
    });

    const [openEstablishmentModal, setOpenEstablishmentModal] = useState(false);

    const [openLoader, setOpenLoader] = useState(false);
    const [showError, setShowError] = useState(false);

    const [alertSeverity, setAlertSeverity] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [signUpFormData, setSignUpFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repeatedPassword: "",
        birthday: "",
        province: "",
        city: "",
        establishments: [],
        isTeacher: false,
        isAdmin: false,
        rolesPending: [],
    });

    const handleClose = () => {
        setOpen(false);
    };

    const cleanUrl = () => {
        const currentUrl = new URL(
            window.location.origin + location.pathname + location.search
        );

        const allowedParams = ["access_token", "refresh_token"];
        for (let param of Array.from(currentUrl.searchParams.keys())) {
            if (!allowedParams.includes(param)) {
                currentUrl.searchParams.delete(param);
            }
        }

        window.history.replaceState(null, "", currentUrl.toString());
    };

    const handleLogIn = async (loginData, origin = "login") => {
        try {
            const data = loginData || logInFormData;
            const res = await ApiService.login(data);
            if (res.status === 200) {
                handleClose();
                localStorage.setItem("access", res.data.access);
                const authMeData = await getConfigurationAuthMe(
                    res.data.access
                );
                if (
                    origin === "login" &&
                    authMeData &&
                    !authMeData.data.roles.includes(4)
                ) {
                    setOpenEstablishmentModal(true);
                }
            } else {
                setShowError(true);
                setTimeout(() => {
                    setShowError(false);
                }, 3000);
            }
        } catch (error) {
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 3000);
        }

        setOpenLoader(false);
    };
    const handleShowAlert = (variant, msg) => {
        setAlertSeverity(variant);
        setAlertMsg(msg);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setAlertSeverity("");
            setAlertMsg("msg");
        }, 5000);
    };
    const handleSignUp = async (evt) => {
        evt.preventDefault();

        setOpenLoader(true);
        signUpFormData.rolesPending = [];
        signUpFormData.isTeacher && signUpFormData.rolesPending.push("teacher");
        signUpFormData.isAdmin && signUpFormData.rolesPending.push("admin");

        if (!signUpFormData.isTeacher && !signUpFormData.isAdmin) {
            signUpFormData.rolesPending.push("student");
        }

        const data = snakeCaseObject(signUpFormData);

        try {
            const res = !loggedByGoogle
                ? await ApiService.register(data)
                : await ApiService.completeRegisterGoogle(data);

            if (res.status === 200) {
                if (!loggedByGoogle) {
                    const loginData = {
                        email: signUpFormData.email,
                        password: signUpFormData.password,
                    };
                    await handleLogIn(loginData, "register");
                } else {
                    const authMeData = await getConfigurationAuthMe(
                        auth.access
                    );
                    if (authMeData && !authMeData.data.roles.includes(4)) {
                        setOpenEstablishmentModal(true);
                    }
                    cleanUrl();
                }
                handleClose();
                handleShowAlert(
                    "success",
                    "Se registró con éxito. Debe esperar a que un administrador procese su solicitud!"
                );
            } else {
                setShowError(true);
                setTimeout(() => {
                    setShowError(false);
                }, 3000);
            }
        } catch (error) {
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 3000);
        }
        setOpenLoader(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                {type === "login" ? (
                    <LogInForm
                        logInFormData={logInFormData}
                        setLogInFormData={setLogInFormData}
                        setOpenLoader={setOpenLoader}
                        handleLogIn={handleLogIn}
                        handleChangeForms={handleChangeForms}
                        handleClose={handleClose}
                        setShowError={setShowError}
                        showError={showError}
                    />
                ) : (
                    <SignUpForm
                        handleSignUp={handleSignUp}
                        signUpFormData={signUpFormData}
                        setSignUpFormData={setSignUpFormData}
                        handleChangeForms={handleChangeForms}
                        handleClose={handleClose}
                        setShowError={setShowError}
                        showError={showError}
                        loggedByGoogle={loggedByGoogle}
                    />
                )}
            </Dialog>
            {openEstablishmentModal && (
                <Dialog
                    open={openEstablishmentModal}
                    // ESTE ONCLOSE NO VA
                    onClose={() => setOpenEstablishmentModal(false)}
                >
                    <EstablishmentSelectionModal
                        setOpen={setOpenEstablishmentModal}
                        checkPendingNotification={checkPendingNotification}
                        pendingNotificationIntervalId={
                            pendingNotificationIntervalId
                        }
                        setPendingNotificationIntervalId={
                            setPendingNotificationIntervalId
                        }
                    ></EstablishmentSelectionModal>
                </Dialog>
            )}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 999999999,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {showAlert && (
                <GenericAlert
                    severity={alertSeverity}
                    text={alertMsg}
                    style={{
                        position: "fixed",
                        zIndex: "9999",
                        top: "50%",
                        left: "30%",
                    }}
                />
            )}
        </div>
    );
}
