import Chart from "common/components/Chart/Chart";
import { useBubbleChart } from "hooks/chart/UseBubbleChart";
import { head } from "lodash";
import React, { useEffect } from "react";
import { MOREGRAPH } from "utils/constants";

export const BubbleChart = ({ data, xAxis, yAxis }) => {
    const { options: optionsLineChart, setOptions: setOptionsLineChart } = useBubbleChart();

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            let headmapList = [];
            data.value_categories.forEach((vCategorie, vIndex) => {
                data.group_by_categories.forEach((gCategorie, xIndex) => {
                    const items = data.data
                        .filter(
                            (obj) =>
                                obj[`group_by_${xAxis}_${yAxis}`][xAxis] === vCategorie &&
                                obj[`group_by_${xAxis}_${yAxis}`][yAxis] === gCategorie
                        )
                        .map((obj) => [xIndex, vIndex, obj.count]);
                    headmapList = [...headmapList, ...items];
                });
            });

            setOptionsLineChart({
                ...optionsLineChart,
                xAxis: {
                    ...optionsLineChart.xAxis,
                    categories: data.group_by_categories,
                },
                yAxis: {
                    ...optionsLineChart.yAxis,
                    categories: data.value_categories,
                },
                series: [
                    {
                        data: headmapList,
                    },
                ],
            });
        }
    }, [data]);

    return <Chart options={optionsLineChart} type={MOREGRAPH} />;
};

export default BubbleChart;
