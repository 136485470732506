import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { stylesSx } from "./styles";

const HomeAd = () => {
    const { titleStyle, centeredColumnDiv, main } = stylesSx;

    return (
        <main>
            <section
                id="main"
                style={{ height: "90vh", backgroundColor: "#d9cdd2" }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            src="/img/home.jpeg"
                            alt=""
                            style={{
                                objectFit: "contain",
                                width: "80%",
                                maxWidth: "100%",
                                // padding: "20px",
                            }}
                        />
                    </Grid>
                </Grid>
            </section>
        </main>
    );
};

export default HomeAd;
