const _ = require("lodash");

export const toCamelCase = (text) => _.camelCase(text);

export const toSnakeCase = (text) => _.snakeCase(text);

/**
 * camelizeObject - Transforma todas las claves del objeto a camelCase.
 * Si el objeto tiene claves cuyo contenido es un objeto también va a convertir
 * sus claves a camelCase. Transforma objetos o instancias de una clase, ya que también
 * son objetos.
 * @function
 * @param  {object} object - Es el objeto a pasar a camelCase
 * @returns {object} - Una copia del objeto con todas sus claves en camelCase.
 * Si recibe una instancia de una clase devuelve un objeto
 */

export const camelizeObject = (object) => {
    return object
        ? Object.entries(object).reduce((carry, [key, value]) => {
              carry[toCamelCase(key)] =
                  value && typeof value === "object" && !Array.isArray(value)
                      ? camelizeObject(value)
                      : value;
              return carry;
          }, {})
        : {};
};

/**
 * snakeCaseObject - Transforma todas las claves del objeto a snakeCase.
 * Si el objeto tiene claves cuyo contenido es un objeto también va a convertir
 * sus claves a snakeCase. Transforma objetos o instancias de una clase, ya que también
 * son objetos.
 * @function
 * @param  {object} object - Es el objeto a pasar a camelCase
 * @returns {object} - Una copia del objeto con todas sus claves en camelCase.
 * Si recibe una instancia de una clase devuelve un objeto
 */

export const snakeCaseObject = (object) => {
    return object
        ? Object.entries(object).reduce((carry, [key, value]) => {
              carry[toSnakeCase(key)] =
                  value && !Array.isArray(value) && typeof value === "object"
                      ? snakeCaseObject(value)
                      : value;
              return carry;
          }, {})
        : {};
};
