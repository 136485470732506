import { useState } from "react";

export const useColumnsChart = (title, titleY) => {
    const [options, setOptions] = useState({
        chart: {
            type: "column",
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                viewDistance: 25,
                depth: 40,
            },
        },

        title: {
            text: title,
            align: "left",
        },

        xAxis: {
            categories: [],
            crosshair: true,
        },

        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                skew3d: true,
                style: {
                    fontSize: "16px",
                },
            },
        },

        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
            },
        },

        series: [],
    });

    return {
        options,
        setOptions,
    };
};
