/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
    Button,
    FormControlLabel,
    Checkbox,
    TextareaAutosize,
} from "@mui/material";

import InputsFromFile from "../InputsFromFile/InputsFromFile";
import stylesSx from "./styles";

const FileDropzone = ({
    file,
    inputsFromCSV = [],
    title = "Arrastrá o agregá tu archivo!",
    onDrop,
    open,
    setInputsFromUser,
    inputsFromUser,
    hasHeaderCallback,
}) => {
    const { baseStyle, focusedStyle, acceptStyle, rejectStyle, backDrop } =
        stylesSx;
    const [hasHeaders, setHasHeaders] = useState(false);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({ onDrop });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const handleCheckHasHeaders = (event) => {
        setHasHeaders(event.target.checked);
        hasHeaderCallback(event.target.checked);
    };

    return (
        <div>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p> {title} </p>
            </div>
            {file !== null ? (
                <div>
                    <ul>
                        <li>{file.name}</li>
                    </ul>

                    {file.name.includes("csv") &&
                        inputsFromCSV.length !== 0 && (
                            <div>
                                <FormControlLabel
                                    label="La primera fila contiene los titulos?"
                                    control={
                                        <Checkbox
                                            value=""
                                            checked={hasHeaders}
                                            onChange={handleCheckHasHeaders}
                                            color="primary"
                                        />
                                    }
                                />
                                <h4>Elija el nombre de los campos de su csv</h4>
                                <InputsFromFile
                                    hasHeaders={hasHeaders}
                                    file={inputsFromCSV}
                                    setInputsFromUser={setInputsFromUser}
                                    inputsFromUser={inputsFromUser}
                                />
                            </div>
                        )}
                    <h4>Agregar descripción al dataset</h4>
                    {/* <textarea
                        name=""
                        id=""
                        placeholder="Se recomienda agregar una descripcion"
                    ></textarea> */}
                    <TextareaAutosize
                        style={{ width: "100%" }}
                        maxRows={6}
                        minRows={4}
                        placeholder="Se recomienda agregar una descripción"
                    />
                </div>
            ) : (
                <></>
            )}
            <Backdrop sx={backDrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

FileDropzone.propTypes = {
    // file: PropTypes.array.isRequired,
    inputsFromCSV: PropTypes.array.isRequired,
    title: PropTypes.string,
    onDrop: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setInputsFromUser: PropTypes.func.isRequired,
    inputsFromUser: PropTypes.object.isRequired,
    // hasHeader: PropTypes.func.isRequired,
};

export default FileDropzone;
