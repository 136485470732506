import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";

const MapChart = ({ options }) => {
    highchartsMap(Highcharts);

    return (
        <div id="chart-component">
            <HighchartsReact
                constructorType={"mapChart"}
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
};

export default MapChart;
