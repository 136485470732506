import React, { useEffect, useState } from "react";

import { Alert, Typography } from "@mui/material";
import { ApiService } from "common/services/ApiService";
import { getUsersRadioGroupItems } from "../../pages/SuperAdmin/utils/constants";
import FilterBar from "common/components/FilterBar/FilterBar";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import { useAuth } from "context/AuthContext";
import getUsersColumns from "modules/authentication/components/UsersList/getUsersColumns";

const UserCourseRequestList = ({
    isSuperAdmin,
    getUsersFunction,
    columnVisibilityModel,
    title = "Usuarios del Sistema",
    showComponentInFilterBar = true,
    establishment,
    courseId,
}) => {
    const { auth } = useAuth();
    const [establishmentsAdmin, setEstablishmentsAdmin] = useState([]);
    const [users, setUsers] = useState([]);
    const [openLoader, setOpenLoader] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [extraFilter, setExtraFilter] = useState({});
    const [selectedUcr, setSelectedUcr] = useState(null);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [acceptCourseRequest, setAcceptCourseRequest] = useState(false);

    const [usersColumns, setUsersColumns] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const [extraFilterComponent, setExtraFilterComponent] = useState({
        role_name: getUsersRadioGroupItems(auth.selectedRole?.name, true).filter((r) => r.show)[0]
            .value,
    });

    const extraFilterList = [
        {
            type: "radio",
            key: "role_name",
            items: getUsersRadioGroupItems(auth.selectedRole?.name, true),
        },
    ];

    const handleOpenConfirmationModal = (ucr) => {
        setSelectedUcr(ucr);
        setOpenConfirmationDialog(true);
    };

    const getUserCourseRequests = async (
        filter = "",
        extraFilter = {},
        page = 1,
        pageSize = 10
    ) => {
        setOpenLoader(true);
        if (extraFilter.role_name === "pending") {
            setUsersColumns(
                getUsersColumns.getUsersColumnsAcceptOrReject(
                    handleOpenConfirmationModal,
                    setAcceptCourseRequest
                )
            );
        } else {
            setUsersColumns(getUsersColumns.getUsersColumnsBase());
        }

        const res = await ApiService.getUserCourseRequests(page, pageSize, {
            ...extraFilter,
            search: filter,
            role_name: extraFilter.role_name !== "pending" ? extraFilter.role_name : "",
            course: courseId,
            pending: extraFilter.role_name === "pending",
        });
        setRowCount(res.data.count);
        setPageSize(pageSize);
        setPage(page);
        setFilter(filter);
        setExtraFilter(extraFilter);
        setUsers(
            res.data.results.map((ucr) => ({
                id: ucr.id,
                first_name: ucr.user.first_name,
                email: ucr.user.email,
            }))
        );
        setOpenLoader(false);
    };

    useEffect(() => {
        setUsersColumns(getUsersColumns.getUsersColumnsBase());
        setEstablishmentsAdmin(auth.rolesEstablishment.map((re) => re.establishment));
    }, []);

    const handleSave = async () => {
        try {
            setOpenConfirmationDialog(false);
            const res = await ApiService.acceptOrRejectCourse(selectedUcr, {
                accepted: acceptCourseRequest,
            });
            if (res.status === 200) {
                setAlertText("Información actualizada con éxito!");
                setAlertSeverity("success");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertText("");
                    setOpenDialog(false);
                }, 2500);
                getUserCourseRequests(filter, extraFilter, page, pageSize);
            } else {
                setAlertText("Ocurrió un error, intente nuevamente!");
                setAlertSeverity("error");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertText("");
                }, 2500);
            }
        } catch (error) {
            setAlertText("Ocurrió un error, intente nuevamente!");
            setAlertSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setAlertText("");
            }, 2500);
        }
    };

    return (
        <div>
            <h1> {title} </h1>
            {usersColumns && (
                <FilterBar
                    columnVisibilityModel={columnVisibilityModel}
                    onPageChange={getUserCourseRequests}
                    openLoader={openLoader}
                    rowCount={rowCount}
                    columns={usersColumns}
                    rows={users}
                    pageSize={pageSize}
                    enableExtraFilter
                    extraFilterList={extraFilterList}
                    extraFilterComponent={extraFilterComponent}
                />
            )}

            {openConfirmationDialog && (
                <GenericDialog
                    handleSave={() => handleSave()}
                    open={openConfirmationDialog}
                    setOpen={setOpenConfirmationDialog}
                    title={"Confirmación"}
                    onClose={() => {
                        getUserCourseRequests(filter, extraFilter, page, pageSize);
                    }}
                >
                    <Typography variant="p" px={3}>
                        Está seguro que desea guardar esta información?
                    </Typography>
                </GenericDialog>
            )}

            {showAlert && (
                <Alert
                    sx={{
                        zIndex: 99999,
                        position: "fixed",
                        top: "50%",
                        left: "40%",
                        color: "white",
                        fontWeight: "bold",
                    }}
                    variant="filled"
                    severity={alertSeverity}
                >
                    {alertText}
                </Alert>
            )}
        </div>
    );
};

export default UserCourseRequestList;
