import { useState } from "react";

export const useLineChart = (title, titleY) => {
    const [options, setOptions] = useState({
        yAxis: {
            title: {
                text: titleY,
            },
        },

        xAxis: {
            accessibility: {
                rangeDescription: "Range: 2010 to 2020",
            },
        },

        legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
            },
        },

        series: [],

        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom",
                        },
                    },
                },
            ],
        },
    });

    return {
        options,
        setOptions,
    };
};
