import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    schoolForm: {
        width: "70%",
        margin: "auto",
    },
}));

export const stylesSx = {
    containerStyle: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "2%",
    },

    cardStyle: { width: 500, padding: "10px" },
};

export default useStyles;
