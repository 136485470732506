import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
import { MAP_COLUMNS, MAP_GRAPHS } from "utils/constants";

const checkIfOffsetWidth = (actualPadding, pageWidth, defaultPadding, actualTop, defaultTop) => {
    if (actualPadding + 200 > pageWidth) {
        return [actualTop + 20, defaultPadding];
    }
    return [actualTop, actualPadding];
};

function createPdf({ doc, elements }, datasetColumns, studentActivity) {
    let top = 20;
    const padding = 10;
    const nameDataset = studentActivity?.course_activity?.activity?.static_data_source?.name;
    const nameActivity = studentActivity?.course_activity?.activity?.title;
    const student = `${studentActivity?.student.first_name} ${studentActivity?.student.last_name}`;
    const pageWidth = doc.internal.pageSize.getWidth();

    let topInfo = top + 20;
    let paddingInfo = padding;

    const xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth("Entrega") * doc.internal.getFontSize()) / 2;
    doc.text("Entrega", xOffset, top);

    doc.setFont(undefined, "bold");
    doc.text("Nombre Dataset", paddingInfo, topInfo);
    doc.setFont(undefined, "normal");
    topInfo += 20;
    doc.text(nameDataset, paddingInfo + 15, topInfo);
    paddingInfo += doc.getTextWidth("Nombre Dataset") + doc.getTextWidth(nameDataset);
    topInfo -= 20;
    [topInfo, paddingInfo] = checkIfOffsetWidth(paddingInfo, pageWidth, padding, topInfo, top);

    doc.setFont(undefined, "bold");
    doc.text(MAP_COLUMNS.datasetColumns, paddingInfo, topInfo);
    doc.setFont(undefined, "normal");
    topInfo += 20;
    doc.text(datasetColumns.toString(), paddingInfo + 15, topInfo, { maxWidth: 200 });
    paddingInfo +=
        doc.getTextWidth(MAP_COLUMNS.datasetColumns) + doc.getTextWidth(datasetColumns.toString());
    topInfo += doc.getTextDimensions(datasetColumns.toString()).h;
    [topInfo, paddingInfo] = checkIfOffsetWidth(paddingInfo, pageWidth, padding, topInfo, top);

    doc.setFont(undefined, "bold");
    doc.text("Nombre Actividad", paddingInfo, topInfo);
    doc.setFont(undefined, "normal");
    topInfo += 20;
    doc.text(nameActivity, paddingInfo + 15, topInfo);
    paddingInfo += doc.getTextWidth("Nombre Dataset") + doc.getTextWidth(nameDataset);
    topInfo -= 20;
    [topInfo, paddingInfo] = checkIfOffsetWidth(paddingInfo, pageWidth, padding, topInfo, top);

    doc.setFont(undefined, "bold");
    doc.text("Alumno", paddingInfo, topInfo);
    doc.setFont(undefined, "normal");
    topInfo += 20;
    doc.text(student, paddingInfo + 15, topInfo);
    paddingInfo += doc.getTextWidth("Alumno") + doc.getTextWidth(student);
    [topInfo, paddingInfo] = checkIfOffsetWidth(paddingInfo, pageWidth, padding, topInfo, top);

    topInfo += 20;
    doc.setFont(undefined, "bold");
    doc.text("Enunciado", paddingInfo, topInfo);
    doc.setFont(undefined, "normal");
    topInfo += 20;
    doc.text(
        studentActivity?.course_activity?.activity?.statement_title,
        paddingInfo + 15,
        topInfo,
        { maxWidth: 400 }
    );
    topInfo += 40;

    elements.forEach((el) => {
        let elHeight = el.offsetHeight;
        let elWidth = el.offsetWidth;

        if (elWidth > pageWidth) {
            const ratio = pageWidth / elWidth;
            elHeight = elHeight * ratio - padding;
            elWidth = elWidth * ratio - padding;
        }

        doc.addPage();
        doc.text(MAP_GRAPHS[el.graph], padding, top);
        top += 20;
        doc.addImage(el.image, "PNG", padding, top, elWidth, elHeight);
        top += elHeight;

        if (el.columns) {
            let topTitle = top + 20;
            let paddingKeys = padding;

            Object.entries(el.columns).forEach(([k, v]) => {
                if (v && v.length > 0) {
                    if (k === "datasetColumns") return;

                    if (paddingKeys + 200 > pageWidth) {
                        paddingKeys = padding;
                        topTitle += 40;
                    }
                    doc.text(MAP_COLUMNS[k], paddingKeys, topTitle);

                    const headers = v.toString();
                    doc.text(headers, paddingKeys + 15, topTitle + 10);
                    paddingKeys += doc.getTextWidth(MAP_COLUMNS[k]) + doc.getTextWidth(headers);
                }
            });


            if (el.filterInclude.length > 0) {
                const filterInclude = el.filterInclude.map((fi) => fi.label);
                topTitle += 40;
                doc.text("Filtrar por", padding, topTitle);
                topTitle += 20;
                doc.text(filterInclude.toString(), padding + 15, topTitle);
            }

            if (el.filterExclude.length > 0) {
                const filterExclude = el.filterExclude.map((fi) => fi.label);
                topTitle += 40;
                doc.text("Filtrar por", padding, topTitle);
                topTitle += 20;
                doc.text(filterExclude.toString(), padding + 15, topTitle);
            }
        }

        top = 20;
    });
}

export function exportMultipleChartsToPdf(elements, datasetColumns, studentActivity) {
    const doc = new jsPDF("p", "px");
    createPdf({ doc, elements }, datasetColumns, studentActivity);
    window.open(doc.output("bloburl"));
}

export const exportGraphToImg = async (el) => {
    return await htmlToImage.toPng(el);
};
