import React, { useState, useEffect } from "react";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    TextField,
    FormControl,
    FormLabel,
    FormHelperText,
    RadioGroup,
    Radio,
} from "@mui/material";
import DateFieldComponent from "common/components/DateFieldComponent/DateFieldComponent";
import dayjs from "dayjs";
import { VISIBILITY } from "utils/constants";
import { ApiService } from "common/services/ApiService";
import AsyncSelect from "common/components/AsyncSelect/AsyncSelect";

const DialogContentCreate = ({ activityFormData, setActivityFormData }) => {
    const [datasets, setDatasets] = useState([]);
    const [actualDataset, setActualDataset] = useState({});

    const [visibility, setVisibility] = React.useState("all");

    const [graphs, setGraphs] = useState({
        dataTable: false,
        column: false,
        line: false,
        pie: false,
        bubble: false,
        dispersion: false,
        map: false,
        static_network: false,
        word_cloud: false,
    });

    const { bubble, column, line, pie, map, static_network, word_cloud } = graphs;

    const handleChange = (event) => {
        const newGraphs = {
            ...graphs,
            [event.target.name]: event.target.checked,
        };
        setGraphs(newGraphs);
        setActivityFormData({
            ...activityFormData,
            graphsData: newGraphs,
        });
    };

    const handleChangeVisibility = (event) => {
        setVisibility(event.target.value);
        setActivityFormData({
            ...activityFormData,
            visibility: event.target.value,
        });
    };

    const getStaticDataSource = async (search) => {
        const res = await ApiService.getStaticDataSources(null, null, {
            no_page: 1,
            // search,
        });
        setDatasets(res.data);
    };

    const searchAsyncSelect = (search) => {
        getStaticDataSource(search);
    };

    useEffect(() => {
        getStaticDataSource(null);
    }, []);

    return (
        <>
            {/* <TextField
                fullWidth
                id="subject"
                select
                label="Curso"
                // value={activityFormData.course}
                onChange={(evt) => {
                    setActivityFormData({
                        ...activityFormData,
                        course: [...activityFormData.course, evt.target.value],
                    });
                }}
                margin="normal"
            >
                {courses.map((course) => (
                    <MenuItem key={course.id} value={course.id}>
                        {course.name}
                    </MenuItem>
                ))}
            </TextField> */}
            <TextField
                required
                autoFocus
                margin="normal"
                id="title"
                fullWidth
                label="Título de la actividad"
                type="text"
                // variant="standard"
                onChange={(evt) => {
                    setActivityFormData({
                        ...activityFormData,
                        title: evt.target.value,
                    });
                    // setEmailError(false);
                }}
            />
            <h5>Enunciado (Esta sección será lo que el estudiante verá en su actividad)</h5>
            <Grid
                container
                sx={{
                    display: "flex",
                    justifyContent: "right",
                }}
            >
                <Grid item xs={11}>
                    <TextField
                        // error={emailError}
                        required
                        margin="normal"
                        id="statementTitle"
                        fullWidth
                        label="Título del enunciado"
                        type="text"
                        // variant="standard"
                        onChange={(evt) => {
                            setActivityFormData({
                                ...activityFormData,
                                statementTitle: evt.target.value,
                            });
                            // setEmailError(false);
                        }}
                    />
                    <TextField
                        // error={emailError}
                        required
                        margin="normal"
                        id="statement"
                        fullWidth
                        label="Enunciado"
                        type="text"
                        multiline
                        rows={4}
                        maxRows={6}
                        onChange={(evt) => {
                            setActivityFormData({
                                ...activityFormData,
                                statement: evt.target.value,
                            });
                            // setEmailError(false);
                        }}
                    />
                </Grid>
            </Grid>
            <TextField
                // error={emailError}
                required
                margin="normal"
                id="objective"
                fullWidth
                label="Objetivo de la actividad"
                type="text"
                // variant="standard"
                onChange={(evt) => {
                    setActivityFormData({
                        ...activityFormData,
                        objective: evt.target.value,
                    });
                    // setEmailError(false);
                }}
            />
            <TextField
                // error={emailError}
                required
                margin="normal"
                id="description"
                fullWidth
                label="Descripción de la actividad"
                type="text"
                // variant="standard"
                onChange={(evt) => {
                    setActivityFormData({
                        ...activityFormData,
                        description: evt.target.value,
                    });
                    // setEmailError(false);
                }}
            />
            <FormControl>
                <FormLabel id="visilibty">Visibilidad</FormLabel>
                <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={visibility}
                    onChange={handleChangeVisibility}
                >
                    <FormControlLabel
                        value={VISIBILITY.public}
                        control={<Radio />}
                        label="Establecimiento"
                    />
                    <FormControlLabel
                        value={VISIBILITY.private}
                        control={<Radio />}
                        label="Privado"
                    />
                    <FormControlLabel
                        value={VISIBILITY.community}
                        control={<Radio />}
                        label="Comunidad"
                    />
                </RadioGroup>
            </FormControl>
            <TextField
                required
                fullWidth
                id="subject"
                select
                label="Dataset"
                defaultValue={""}
                onChange={(evt) => {
                    setActualDataset(evt.target.value);
                    setActivityFormData({
                        ...activityFormData,
                        staticDataSource: evt.target.value.id,
                    });
                }}
                margin="normal"
            >
                {datasets.map((dataset) => (
                    <MenuItem key={dataset.id} value={dataset}>
                        {dataset.name}
                    </MenuItem>
                ))}
            </TextField>

            {/* <AsyncSelect
                options={datasets}
                onChangeSearch={(search) => searchAsyncSelect(search)}
                onChangeSelect={(value) => {
                    console.log(value);
                    setActualDataset(value);
                    setActivityFormData({
                        ...activityFormData,
                        staticDataSource: value.id,
                    });
                }}
            /> */}
            <FormControl>
                <FormLabel component="legend">Gráficos permitidos</FormLabel>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={
                            <Checkbox checked={column} onChange={handleChange} name="column" />
                        }
                        label="Columnas"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={<Checkbox checked={line} onChange={handleChange} name="line" />}
                        label="Línea"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={<Checkbox checked={pie} onChange={handleChange} name="pie" />}
                        label="Torta"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={
                            <Checkbox checked={bubble} onChange={handleChange} name="bubble" />
                        }
                        label="Burbujas"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={<Checkbox checked={map} onChange={handleChange} name="map" />}
                        label="Mapa"
                    />
                    <FormControlLabel
                        disabled={!(actualDataset?.name || "").includes("csv")}
                        control={
                            <Checkbox
                                checked={static_network}
                                onChange={handleChange}
                                name="static_network"
                            />
                        }
                        label="Grafo"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={!(actualDataset?.name || "").includes("pdf")}
                                checked={word_cloud}
                                onChange={handleChange}
                                name="word_cloud"
                            />
                        }
                        label="Nube de palabras"
                    />
                </FormGroup>
            </FormControl>
        </>
    );
};

export default DialogContentCreate;
