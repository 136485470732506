import React from "react";
import { PropTypes } from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const LogoutConfirmationDialog = ({ open, setAuth, setOpen }) => {
    const navigate = useNavigate();

    const handleCloseNo = () => {
        setOpen(false);
    };
    const handleCloseYes = () => {
        setOpen(false);
        setAuth("");
        localStorage.clear();
        navigate("/");
    };
    return (
        <Dialog
            // fullScreen={fullScreen}
            open={open}
            onClose={handleCloseNo}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"Cerrar Sesión"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Está seguro que quiere cerrar la sesión?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCloseNo}>
                    No
                </Button>
                <Button onClick={handleCloseYes} autoFocus>
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    );
};

LogoutConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setAuth: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default LogoutConfirmationDialog;
